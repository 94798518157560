import { Buffer } from 'buffer';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Navigate } from 'react-router-dom';
import { uniqueId } from 'lodash';
import axios from '../../util/axios';
import { ALL_DOCUMENTS } from '../../util/constants';
import { dispatchAndUpdateStore } from '../../util/dispatchAndUpdateStore';
import { StoreKey } from '../../types/storeNames';
import { ShareAcceptDestinations } from '../share/types';
import { onWebAppPoolingRequest } from '../../tracking/trackers';
import {
    IFilterState,
    ILoadReceiptResponse,
    IReceiptExportResponse,
    IReceiptUploadResponse,
    IUserTagResponse,
    ISearchResponse,
    ILineItem,
    IReceiptUpdateResponseV2,
    IEditReceiptData,
    IDownloadImagesResponse,
    IProcessingLineItem,
    ICreateLineItemResponse,
    IDeleteReceiptResponse,
    ReceiptPlatform,
    ILoadAllProcessingResponse,
    ILoadUncategorizedResponse,
    IShareTokenResponse,
    ILoadReceiptsResponse,
    ILoadImageIds,
    IReceiptBulkCommitResponse,
    IReceipt,
    IGetProcessingLineItemResponse,
    IProcessingCommitData,
    IPlaidCreateLinkTokenResponse,
    IPlaidWebhookResponse,
    IPlaidTransactionsResponse,
    IPlaidTransactionsExportResponse,
    IPlaidItemsResponse,
    IGetMatchingReceiptsResponse,
    ICategorySuggestionResponse,
    IFolderIconSuggestionResponse,
    ILoadInvalidationUpdatesResponse,
    ICreateEstimateResponse,
} from './types';
const DOCUMENT_STORES = [StoreKey.RECEIPTS, StoreKey.PROCESSING_RECEIPTS];

export default class ReceiptService {
    public static getReceiptById(receipt_id: string): Promise<IReceipt> {
        const url = `/receipts/get/${receipt_id}`;
        return axios.get(url).then((value) => value.data.data);
    }

    public static getImageDataV2(
        receipt_id: string | undefined,
        image_id: string
    ): Promise<string> {
        return axios
            .get(`/receipts/${receipt_id}/images/${image_id}`, {
                responseType: 'arraybuffer',
            })
            .then((value) => {
                return Buffer.from(value.data, 'binary').toString('base64');
            })
            .catch((error) => {
                if (error.response) {
                    console.log('ERROR: ', error.response.status);
                    if (error.response.status === 403) {
                        Navigate({ to: 'login' });
                    }
                }
                return '';
            });
    }

    public static loadReceipts(
        tagId: string,
        tagIds?: string[],
        page?: number,
        pageSize?: number
    ): Promise<ILoadReceiptsResponse> {
        onWebAppPoolingRequest({ url: 'loadReceipts' });
        return axios
            .post(
                '/receipts/load',
                {
                    key: '-date',
                    tag_id: tagId === ALL_DOCUMENTS ? null : tagId,
                    tag_ids: tagIds,
                    page,
                    page_size: pageSize,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<ILoadReceiptsResponse>) => value.data);
    }

    public static loadImageIds(): Promise<ILoadImageIds> {
        return axios
            .post(
                '/receipts/load-image-ids',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<ILoadImageIds>) => value.data);
    }

    public static loadUncategorizedReceipts(
        returnCountOnly?: boolean
    ): Promise<ILoadUncategorizedResponse> {
        onWebAppPoolingRequest({ url: 'loadUncategorizedReceipts' });
        return axios
            .post(
                '/receipts/load-uncategorized',
                { key: '-date', count: returnCountOnly || false },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(
                (value: AxiosResponse<ILoadUncategorizedResponse>) => value.data
            );
    }

    public static loadReceipt(
        receiptId: string
    ): Promise<ILoadReceiptResponse> {
        return axios
            .get(`receipts/get/${receiptId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((value) => value.data);
    }

    public static getShareToken(
        shareToken: string
    ): Promise<IShareTokenResponse> {
        return axios
            .post(
                '/receipts/share/get',
                { share_token: shareToken },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then((value) => value.data);
    }

    public static exportReceiptsV2(
        tagIds?: string[],
        filterState?: IFilterState
    ): Promise<IReceiptExportResponse> {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        return axios
            .post(
                '/receipts/export-csv',
                {
                    tag_ids: tagIds || [],
                    filters: filterState || {},
                    timeZone,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then((value: AxiosResponse<IReceiptExportResponse>) => value.data);
    }

    public static tagProcessingReceiptV2(
        receipt_id: string,
        tag_ids: string[]
    ): Promise<{}> {
        return axios
            .post(
                '/receipts/processing/tag',
                { receipt_id, tag_ids },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then((value: AxiosResponse) => {
                return value.data;
            });
    }

    public static commitReceiptV2(
        receipt_id: string,
        data: IProcessingCommitData
    ): Promise<IReceiptUploadResponse> {
        return dispatchAndUpdateStore(
            axios
                .post(
                    '/receipts/processing/save',
                    {
                        receipt_id,
                        data: {
                            ...data,
                            // note: this is critical to saving processing receipts since the spreadsheet only updates receipt.categories
                            tag_ids: data.categories?.map((c) => c.tag_id),
                        },
                    },
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((value: AxiosResponse) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static getReceiptTags(): Promise<IUserTagResponse> {
        onWebAppPoolingRequest({ url: 'getReceiptTags' });
        return axios.get('/receipts/tags/get', {}).then((value) => {
            return value.data;
        });
    }

    public static search(
        _key?: string,
        _search?: string,
        filterState?: IFilterState,
        folderId?: string,
        folderIds?: string[],
        page?: number,
        pageSize?: number,
        abortController?: CancelTokenSource
    ): Promise<ISearchResponse> {
        const key = _key ? _key : 'created_at';
        const search = _search ? _search : null;
        return axios
            .post(
                '/receipts/search',
                {
                    key,
                    search,
                    tag_id: folderId,
                    tag_ids: folderIds,
                    page,
                    page_size: pageSize,
                    ...filterState,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then((value: AxiosResponse<ISearchResponse>) => value.data);
    }

    public static editReceipt(
        receiptId: string,
        data: IEditReceiptData
    ): Promise<IReceiptUpdateResponseV2> {
        return dispatchAndUpdateStore(
            axios
                .post(
                    '/receipts/edit',
                    { receipt_id: receiptId, data },
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((value: AxiosResponse<IReceiptUpdateResponseV2>) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static getLineItems(receiptId: string): Promise<ILineItem[]> {
        const url = `/receipts/line-items/get/${receiptId}`;
        return axios.get(url).then((value) => value.data.data);
    }

    public static downloadImages(
        fileType: 'pdf' | 'zip',
        folderIds?: string[],
        removeLineItems?: boolean
    ): Promise<string> {
        return axios
            .post(`/receipts/download-images`, {
                file_type: fileType,
                tag_ids: folderIds,
                remove_line_items: removeLineItems,
            })
            .then((value: AxiosResponse<IDownloadImagesResponse>) => {
                return value.data.data.file_url_id;
            });
    }

    public static createLineItem(
        receiptId: string,
        data: IProcessingLineItem
    ): Promise<ICreateLineItemResponse> {
        return axios
            .post(`/receipts/line-items/create`, {
                receipt_id: receiptId,
                data,
            })
            .then(
                (value: AxiosResponse<ICreateLineItemResponse>) => value.data
            );
    }

    public static delete(receiptId: string): Promise<IDeleteReceiptResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/delete', { receipt_id: receiptId })
                .then((value: AxiosResponse<IDeleteReceiptResponse>) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static getProcessingReceipts(
        platforms?: ReceiptPlatform[],
        createdAt?: string | undefined
    ): Promise<ILoadAllProcessingResponse> {
        onWebAppPoolingRequest({ url: 'loadProcessingReceipts' });
        const created_at = createdAt || '1970-01-01 00:00:00';
        return axios
            .post('/receipts/processing/load-many', {
                created_at,
                platforms,
            })
            .then((value: AxiosResponse<ILoadAllProcessingResponse>) => {
                return value.data;
            });
    }

    public static acceptShareToken(
        linkToken: string,
        destinations?: ShareAcceptDestinations
    ): Promise<IShareTokenResponse> {
        return axios
            .post(
                '/receipts/share/accept',
                { share_token: linkToken, destinations },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value) => value.data);
    }

    public static getCategorySuggestion(
        merchant: string,
        receipt_id?: string
    ): Promise<ICategorySuggestionResponse> {
        return axios
            .post(
                '/receipts/get-category-suggestion',
                {
                    merchant,
                    receipt_id,
                },
                {}
            )
            .then((value) => value.data);
    }

    public static loadDataUpdates(
        token: string
    ): Promise<ILoadReceiptsResponse> {
        onWebAppPoolingRequest({ url: 'loadDataUpdates' });
        return axios
            .post(
                '/receipts/data-updates',
                {},
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static loadInvalidationUpdates(
        token: string
    ): Promise<ILoadInvalidationUpdatesResponse> {
        onWebAppPoolingRequest({ url: 'loadInvalidationUpdates' });
        return axios
            .post(
                '/receipts/invalidation-updates',
                {},
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static bulkCommitProcessingReceipts({
        receiptIds,
        tagIds,
        negativeTotal,
    }: {
        receiptIds: string[];
        tagIds: string[];
        negativeTotal?: boolean;
    }): Promise<IReceiptBulkCommitResponse> {
        // @ts-ignore
        const app_version = AppJSON.version[Platform.OS];
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/processing/bulk-save', {
                    receipt_ids: receiptIds,
                    tag_ids: tagIds,
                    app_version,
                    negative_total: negativeTotal,
                })
                .then((value: AxiosResponse) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static bulkEdit({
        receiptIds,
        data,
        negativeTotal,
    }: {
        receiptIds: string[];
        data: Partial<IReceipt> & { tag_ids?: string[] };
        negativeTotal?: boolean;
    }): Promise<IReceiptBulkCommitResponse> {
        // @ts-ignore
        const app_version = AppJSON.version[Platform.OS];
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/bulk-edit', {
                    receipt_ids: receiptIds,
                    data,
                    app_version,
                    negative_total: negativeTotal,
                })
                .then((value: AxiosResponse<IReceiptBulkCommitResponse>) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static bulkDeleteProcessingReceipts({
        receiptIds,
    }: {
        receiptIds: string[];
    }): Promise<IDeleteReceiptResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/processing/bulk-delete', {
                    receipt_ids: receiptIds,
                })
                .then(
                    (value: AxiosResponse<IDeleteReceiptResponse>) => value.data
                ),
            DOCUMENT_STORES
        );
    }

    public static bulkDelete({
        receiptIds,
    }: {
        receiptIds: string[];
    }): Promise<IDeleteReceiptResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/bulk-delete', { receipt_ids: receiptIds })
                .then((value: AxiosResponse<IDeleteReceiptResponse>) => {
                    return value.data;
                }),
            DOCUMENT_STORES
        );
    }

    public static getProcessingLineItems(
        receiptId: string
    ): Promise<IProcessingLineItem[]> {
        return axios
            .post('/receipts/processing/line-items/load', {
                receipt_id: receiptId,
            })
            .then((value: AxiosResponse<IGetProcessingLineItemResponse>) => {
                const itemsWithIds: IProcessingLineItem[] =
                    value.data.data?.map((lineItem) => {
                        return {
                            ...lineItem,
                            item_id: uniqueId(),
                            category_ids: lineItem.category_ids ?? [],
                        };
                    }) ?? [];
                return itemsWithIds;
            });
    }

    public static plaidCreateLinkToken(
        token: string
    ): Promise<IPlaidCreateLinkTokenResponse> {
        return axios
            .post(
                `/receipts/plaid/link-token/create`,
                {},
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static createPlaidItem(
        token: string,
        public_token?: string
    ): Promise<IPlaidWebhookResponse> {
        return axios
            .post(
                `/receipts/plaid/items/create`,
                { public_token },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static getPlaidTransactions(
        token: string,
        year?: string,
        item?: string
    ): Promise<IPlaidTransactionsResponse> {
        return axios
            .post(
                `/receipts/plaid/transactions/get`,
                { year, item },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static exportPlaidTransactions(
        token: string,
        start_date?: string,
        end_date?: string,
        account_id?: string
    ): Promise<IPlaidTransactionsExportResponse> {
        return axios
            .post(
                `/receipts/plaid/transactions/export`,
                { start_date, end_date, account_id },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static getPlaidItems(token: string): Promise<IPlaidItemsResponse> {
        return axios
            .post(
                `/receipts/plaid/items/get`,
                {},
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static removePlaidItem(
        token: string,
        item_id: string
    ): Promise<IPlaidItemsResponse> {
        return axios
            .post(
                `/receipts/plaid/items/remove`,
                { item_id },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static getMatchingReceipts(
        token: string,
        amount: number,
        date: string,
        merchant: string
    ): Promise<IGetMatchingReceiptsResponse> {
        return axios
            .post(
                `/receipts/get-matching-receipts`,
                { amount, date, merchant },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static getFolderIconSuggestion(
        token: string,
        folder_name: string,
        abortController?: CancelTokenSource
    ): Promise<IFolderIconSuggestionResponse> {
        return axios
            .post(
                `/receipts/get-category-icon-suggestion`,
                {
                    folder_name,
                },
                {
                    headers: { Authorization: `Token ${token}` },
                    cancelToken: abortController?.token,
                }
            )
            .then((value) => value.data);
    }

    public static requestPlaidAccess(
        token: string
    ): Promise<IPlaidWebhookResponse> {
        return axios
            .post(
                `/receipts/plaid/request-access`,
                {},
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value) => value.data);
    }

    public static createEstimate(
        token: string,
        merchant: string,
        line_items: { description: string; quantity?: number; total: number }[],
        description?: string,
        additional_fields?: any
    ): Promise<ICreateEstimateResponse> {
        return dispatchAndUpdateStore(
            axios
                .post(
                    `/receipts/create-estimate`,
                    { merchant, line_items, description, additional_fields },
                    {
                        headers: { Authorization: `Token ${token}` },
                    }
                )
                .then((value) => value.data),
            DOCUMENT_STORES
        );
    }

    public static async addImageStoredReceipt(
        token: string,
        receipt_id: string,
        blobUrl: string
    ): Promise<IReceiptUploadResponse> {
        // Convert blob URL to actual blob
        const response = await fetch(blobUrl);
        const blob = await response.blob();

        const data = new FormData();
        data.append('file', blob); // Append the actual blob object
        data.append('receipt_id', receipt_id);

        return axios
            .patch('/receipts/images/add', data, {
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((value: AxiosResponse<IReceiptUploadResponse>) => value.data);
    }
}
