import { styled } from '@mui/system';
import theme from '../../../../theme';

export const Container = styled('div')({
    padding: '0 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '37.5rem',
    margin: '0 auto',
    height: '100%',
});

export const QuestionContainer = styled('div')({
    fontSize: 22,
    marginTop: 30,
    fontWeight: '700',
    width: 300,
});

export const ProgressBarContainer = styled('div')({
    width: '100%',
    marginBottom: '1.5rem',
});

export const ProgressBar = styled('div')({
    width: '100%',
    height: '0.625rem',
    backgroundColor: '#E0E0E0',
    borderRadius: '0.3125rem',
});

export const Progress = styled('div')({
    height: '100%',
    backgroundColor: theme.colors.primary400,
    borderRadius: '0.3125rem',
    transition: 'width 0.3s ease',
});

export const QuestionText = styled('div')({
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    textAlign: 'left',
});

export const ChoicesContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});
