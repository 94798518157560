import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import {
    IGetCostEstimationResponse,
    IGetPhotoInformationResponse,
    IGetXlsxExportResponse,
    IRetrieveCostEstimationResponse,
    IUploadImageResponse,
    ISharedCostEstimationResponse,
    IGetImageDataResponse,
    ILogBidIqResponse,
    IGetBookingLinkResponse,
    IGetCostEstimatorQuestionaireFormIdResponse,
    ISubmitCostEstimatorQuestionaireFormResponse,
    IValidateSignKeyResponse,
    ISendSignedEstimationEmailResponse,
    ISendSharedCostEstimationEmailResponse,
    IPrintHtmlResponse,
} from './types';

export default class PublicService {
    public static uploadImage(
        image: Blob,
        prefix: string | null = null
    ): Promise<IUploadImageResponse> {
        const data = new FormData();
        data.append('file', image);
        data.append('prefix', prefix || '');
        return axios
            .post('/public/upload-image', data)
            .then((value: AxiosResponse<IUploadImageResponse>) => {
                return value.data;
            });
    }

    public static getCostEstimation(
        image_id: string | undefined,
        prompt: string,
        current_estimation?: string,
        previous_prompt?: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        if (image_id) data.append('image_id', image_id);
        data.append('prompt', prompt);
        data.append('format', 'csv');
        if (current_estimation)
            data.append('current_estimation', current_estimation);
        if (previous_prompt) data.append('previous_prompt', previous_prompt);
        data.append('visitor_id', localStorage.getItem('visitor_id') || '');
        return axios
            .post('/public/get-cost-estimation', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static logCostEstimationResult(
        token: string,
        image_id: string,
        prompt: string,
        line_items: any,
        receipt_id: string | undefined,
        saved: boolean
    ): Promise<IRetrieveCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        data.append('prompt', prompt);
        data.append('line_items', line_items);
        if (receipt_id) data.append('receipt_id', receipt_id);
        data.append('saved', saved.toString());
        return axios
            .post('/public/log-cost-estimation-result', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}`,
                },
            })
            .then((value: AxiosResponse<IRetrieveCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getPhotoInformation(
        image_ids: string | string[],
        date?: string
    ): Promise<IGetPhotoInformationResponse> {
        return axios
            .post('/public/get-photo-information', {
                image_ids: Array.isArray(image_ids) ? image_ids : [image_ids],
                date,
            })
            .then((value: AxiosResponse<IGetPhotoInformationResponse>) => {
                return value.data;
            });
    }

    public static getImageAnalyze(
        image_id: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        return axios
            .post('/public/analyze-image', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getXlsxExport(
        csv_data: string
    ): Promise<IGetXlsxExportResponse> {
        const data = new FormData();
        data.append('csv_data', csv_data);
        return axios
            .post('/public/get-xlsx-export', data)
            .then((value: AxiosResponse<IGetXlsxExportResponse>) => {
                return value.data;
            });
    }

    public static getEstimationData(
        url: string
    ): Promise<ISharedCostEstimationResponse> {
        const data = new FormData();
        data.append('url', url);
        return axios
            .post(`/public/shared-cost-estimation/get`, data)
            .then((value: AxiosResponse<ISharedCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getImageData(
        image_id: string
    ): Promise<IGetImageDataResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        return axios
            .post(`/public/get-image-data`, data)
            .then((value: AxiosResponse<IGetImageDataResponse>) => {
                return value.data;
            });
    }

    public static sharedEstimateNotify(
        url: string,
        notification: 'seen' | 'approved'
    ): Promise<IGetImageDataResponse> {
        const data = new FormData();
        data.append('url', url);
        data.append('notification', notification);
        return axios
            .post(`/public/shared-cost-estimation/notify`, data)
            .then((value: AxiosResponse<IGetImageDataResponse>) => {
                return value.data;
            });
    }

    public static bookingLinkNotify(
        url: string,
        notification: 'form_submitted',
        form_data: any
    ): Promise<IGetImageDataResponse> {
        return axios
            .post(`/public/booking-link/notify`, {
                url,
                notification,
                form_data,
            })
            .then((value: AxiosResponse<IGetImageDataResponse>) => {
                return value.data;
            });
    }

    public static logBidIqResult(
        imageId: string | null = null,
        postalCode: string | null = null
    ): Promise<ILogBidIqResponse> {
        const data = new FormData();
        data.append('image_id', imageId || '');
        data.append('metadata', JSON.stringify({ postalCode }));

        return axios
            .post('/public/log-bidiq-result', data)
            .then((value: AxiosResponse<ILogBidIqResponse>) => {
                return value.data;
            });
    }

    public static async getBookingLink(
        url: string
    ): Promise<IGetBookingLinkResponse> {
        const response = await axios.post(
            '/public/booking-link/get-by-url/',
            { url },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }

    public static async getCostEstimatorQuestionaireFormId(
        url: string
    ): Promise<IGetCostEstimatorQuestionaireFormIdResponse> {
        const response = await axios.post(
            '/public/booking-link/get-form',
            { url },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }

    public static async submitCostEstimatorQuestionaireForm(
        id: number,
        stepData: {
            image_ids?: string[];
            project_description?: string;
            questions_answers?: { question: string; answer: string }[];
            client_info?: {
                name: string;
                address: string;
                mobile: string;
                email: string;
            };
        }
    ): Promise<ISubmitCostEstimatorQuestionaireFormResponse> {
        const response = await axios.post(
            '/public/booking-link/submit-form-data',
            { id, form_data: stepData },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    }

    public static validateSignKey(
        url: string,
        sign_key: string
    ): Promise<IValidateSignKeyResponse> {
        return axios
            .post(
                '/public/shared-cost-estimation/validate-sign-key',
                {
                    url,
                    sign_key,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<IValidateSignKeyResponse>) => {
                return value.data;
            });
    }

    public static async updateSharedEstimation(
        url: string,
        sign_key: string,
        metadata: any
    ): Promise<ISharedCostEstimationResponse> {
        return axios
            .post(
                '/public/shared-cost-estimation/update',
                {
                    url,
                    sign_key,
                    metadata,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<any>) => {
                return value.data;
            });
    }

    public static async sendSharedCostEstimationEmail(
        url: string,
        email?: string,
        phone?: string
    ): Promise<ISendSharedCostEstimationEmailResponse> {
        return axios
            .post(
                '/public/shared-cost-estimation/send',
                {
                    url,
                    email,
                    phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<any>) => {
                return value.data;
            });
    }

    public static async sendSignedEstimationEmail(
        url: string
    ): Promise<ISendSignedEstimationEmailResponse> {
        return axios
            .post(
                '/public/shared-cost-estimation/send-signed-estimation-email',
                {
                    url,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<any>) => {
                return value.data;
            });
    }

    public static async printHtml(
        html: string,
        estimate_number: string
    ): Promise<IPrintHtmlResponse> {
        return axios
            .post('/public/print-html', {
                html,
                estimate_number,
            })
            .then((value: AxiosResponse<any>) => {
                return value.data;
            });
    }
}
