import { Button, Container, Input, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import theme from '../../theme';
import ProjectBreakdown from '../../assets/animations/svGator/MeasuringTape';

import TakePhoto from '../CostEstimator/components/TakePhoto';
import { callClaude } from '../CostEstimator/components/CostEstimatorProvider/service';
import { BIDIQ_PROMPT } from '../CostEstimator/components/CostEstimatorProvider/prompts';
import { Body, BodyBold } from '../../components/Text';
import {
    onWebAppBidIQClickContinue,
    onWebAppBidIQClickStartFreeTrial,
    onWebAppBidIQClickStartOver,
    onWebAppBidIQEnterPostcode,
    onWebAppBidIQLoadCohort,
    onWebAppBidIQLoadResult,
    onWebAppBidIQUploadImage,
} from '../../tracking/trackers';
import RefreshIcon from '../../assets/icons/refresh.svg';
import PublicService from '../../services/public/service';

const PriceRange = ({
    min,
    max,
    current,
}: {
    min: number;
    max: number;
    current: number;
}) => {
    const actualMin = Math.min(min, current);
    const actualCenter = Math.max(min, current);
    const actualMax = Math.max(max, current);
    const currentPlace =
        actualCenter === min ? 'min' : actualCenter === max ? 'max' : 'center';
    const getTextStyle = (place: string, _currentPlace: string) => {
        if (place === _currentPlace)
            return {
                color: current >= min && current <= max ? '#4CAF50' : '#FF0000',
                fontWeight: 'bold',
                position: 'absolute' as any,
                top: -30,
                width: 100,
                left: -50,
                textAlign: 'center' as any,
                transition: 'all 0.3s ease-in-out',
            };
        return {
            color: '#000',
            position: 'absolute' as any,
            fontWeight: 'normal',
            top: 30,
            width: 100,
            left: -50,
            textAlign: 'center' as any,
            transition: 'all 0.3s ease-in-out',
        };
    };
    return (
        <div
            style={{
                width: '100%',
                marginTop: 30,
                position: 'relative',
                height: 60,
                animation: 'fadeIn 0.5s ease-in-out',
            }}
        >
            {/* Horizontal line */}
            <div
                style={{
                    position: 'absolute',
                    top: 30,
                    left: 0,
                    right: 0,
                    height: 2,
                    backgroundColor: '#E0E0E0',
                }}
            />

            {/* Min marker */}
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    opacity: 0,
                    animation: 'slideInFromLeft 0.5s ease-in-out 0s forwards',
                }}
            >
                <div
                    style={{
                        width: 2,
                        height: 20,
                        backgroundColor: '#000',
                        left: 0,
                    }}
                />
                <Body
                    style={{
                        ...getTextStyle(currentPlace, 'min'),
                    }}
                >
                    ${actualMin}
                </Body>
            </div>

            {/* Max marker */}
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    opacity: 0,
                    animation: 'slideInFromTop 0.5s ease-in-out 1s forwards',
                }}
            >
                <div
                    style={{
                        width: 2,
                        height: 20,
                        backgroundColor: '#000',
                        right: 0,
                    }}
                />
                <Body
                    style={{
                        ...getTextStyle(currentPlace, 'max'),
                    }}
                >
                    ${actualMax}
                </Body>
            </div>

            {/* Current value marker */}
            <div
                style={{
                    left: `${
                        ((actualCenter - actualMin) / (actualMax - actualMin)) *
                        100
                    }%`,
                    position: 'absolute',
                    top: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    opacity: 0,
                    animation: 'slideInFromTop 0.5s ease-in-out 0.5s forwards',
                    transition: 'left 0.3s ease-in-out',
                }}
            >
                <div
                    style={{
                        width: 2,
                        height: 20,
                        backgroundColor: '#000',
                    }}
                />
                <Body
                    style={{
                        ...getTextStyle(currentPlace, 'center'),
                    }}
                >
                    ${actualCenter}
                </Body>
            </div>
            <div
                style={{
                    left: `${
                        ((min - actualMin) / (actualMax - actualMin)) * 100
                    }%`,
                    width: 0,
                    height: 5,
                    top: 30,
                    position: 'absolute',
                    backgroundColor: '#4CAF50',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    animation: 'growWidth 1s ease-in-out 1.2s forwards',
                    transition: 'left 0.3s ease-in-out, width 0.3s ease-in-out',
                }}
            ></div>

            <style>
                {`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                @keyframes growWidth {
                    from { width: 0; }
                    to { width: ${
                        ((max - min) / (actualMax - actualMin)) * 100
                    }% }
                }
                @keyframes growHeight {
                    from { height: 0; }
                    to { height: 20px; }
                }
                @keyframes slideInFromLeft {
                    from { transform: translateX(-20px); opacity: 0; }
                    to { transform: translateX(0); opacity: 1; }
                }
                @keyframes slideInFromRight {
                    from { transform: translateX(20px); opacity: 0; }
                    to { transform: translateX(0); opacity: 1; }
                }
                @keyframes slideInFromTop {
                    from { transform: translateY(-20px); opacity: 0; }
                    to { transform: translateY(0); opacity: 1; }
                }
                `}
            </style>
        </div>
    );
};

export const BidIQ = () => {
    const downloadBadges = '';
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [step, setStep] = useState(0);
    const [loadingText, setLoadingText] = useState(
        'Analyzing your estimate...'
    );
    const [imageUri, setImageUri] = useState<string | null>(null);
    const [imageId, setImageId] = useState<string | null>(null);
    const [postalCode, setPostalCode] = useState('');
    const [analyzeData, setAnalyzeData] = useState<any>(null);
    const [showPaywall, setShowPaywall] = useState(false);

    useEffect(() => {
        onWebAppBidIQLoadCohort({ cohort: 'baseline' });
        // setAnalyzeData(
        //     JSON.parse(
        //         `Let me analyze this painting project estimate for the Upper East Side area of Manhattan (10021):\n\n1. Project Type: Interior wall painting project including repairs\n2. Project Scale: Small to medium (based on labor hours and materials)\n3. Price Analysis for Upper East Side, Manhattan:\n\n<changes>\n{\n"changes": [\n{"type": "labor", "description": "Labor rate at $3/hour is extremely low for Manhattan. Standard rates in 10021 range from $65-85/hour for professional painters"},\n{"type": "material", "description": "Interior paint cost at $39.98 seems low for contractor-grade paint in Manhattan. Premium paint suitable for Upper East Side properties typically runs $55-75 per gallon"},\n{"type": "labor", "description": "Total labor hours (96) seem high for what appears to be a single wall project. Typical similar projects require 20-30 hours"},\n{"type": "overhead", "description": "No overhead or contractor markup included, which typically runs 20-30% in Manhattan"}\n],\n"area_name": "Upper East Side, Manhattan",\n"current_estimate": "$394.79",\n"minimum": "$850",\n"maximum": "$1,200"\n}\n</changes>\n\nThis estimate appears significantly underpriced for the Upper East Side area. Manhattan contractors typically charge premium rates due to higher operating costs, parking challenges, and building requirements. The labor rate particularly stands out as unrealistic for this zip code. Even small painting projects in this area typically start at $850 minimum, with higher-end services reaching $1,200 or more for premium finishes and materials expected in this neighborhood.`
        //             .split('<changes>')[1]
        //             .split('</changes>')[0]
        //     )
        // );
        // setStep(3);
    }, []);

    const groupedChanges =
        analyzeData?.changes?.reduce((acc: any, change: any) => {
            if (!acc[change.type]) {
                acc[change.type] = [];
            }
            acc[change.type].push(change);
            return acc;
        }, {}) || {};

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg' && _file.type !== 'image/png') return;

        resizeFile(_file)
            .then((resizedFile) => {
                // Create URL for both preview and Claude
                const imageUrl = URL.createObjectURL(resizedFile);
                setImageUri(imageUrl);
            })
            .catch((err) => {
                console.error('Resize error:', err);
            });

        PublicService.uploadImage(_file, 'bid_iq')
            .then((res) => {
                const imageId = res?.data?.image_ids?.[0];
                setImageId(imageId);
                onWebAppBidIQUploadImage({ imageId });
            })
            .catch(() => {
                onWebAppBidIQUploadImage({ imageId: null });
            });

        setStep(1);
        event.target.value = '';
    };

    const onSubmitPostalCode = () => {
        if (!postalCode) return;
        callClaude({
            instructions: BIDIQ_PROMPT,
            prompt: `my postal code: ${postalCode}`,
            imageUris: [imageUri || ''],
        }).then((res) => {
            setAnalyzeData(
                JSON.parse(
                    res.response
                        .split('<suggested_changes>')[1]
                        .split('</suggested_changes>')[0]
                )
            );
            onWebAppBidIQLoadResult();
            setStep(3);
        });

        PublicService.logBidIqResult(imageId, postalCode);
        setStep(2);
        onWebAppBidIQEnterPostcode({ postcode: postalCode });
        setLoadingText('Analyzing your estimate...');
        setTimeout(() => {
            setLoadingText('Fetching recent bids in your area...');
        }, 3000);
        setTimeout(() => {
            setLoadingText('Drafting the changes on your estimate...');
        }, 6000);
    };

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100vh',
                fontFamily: 'Inter, sans-serif',
                width: '100%',
            }}
        >
            {/* rendering it here to avoid lazy loading down the road */}
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            {/* Header Section */}
            <SectionStickyHeaderLogo
                expName={'cost-estimator-page'}
                pageId={'cost-estimator-page'}
                headerMode={undefined}
                onClick={() => {}}
            >
                {step === 3 ? (
                    <div>
                        <Button
                            style={{ fontSize: 12 }}
                            onClick={() => {
                                onWebAppBidIQClickStartOver();
                                setShowPaywall(false);
                                setImageUri(null);
                                setImageId(null);
                                setAnalyzeData(null);
                                setPostalCode('');
                                setStep(0);
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                Start Over
                                <img
                                    src={RefreshIcon}
                                    alt="refresh icon"
                                    style={{
                                        marginLeft: 8,
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                            </div>
                        </Button>
                    </div>
                ) : (
                    <div></div>
                )}
            </SectionStickyHeaderLogo>
            <input
                placeholder="Upload Image"
                type="file"
                accept="image/*"
                // capture="environment"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '40px 10px',
                    height: '100vh',
                }}
            >
                {step === 0 && (
                    <TakePhoto
                        imageInputRef={imageInputRef}
                        title="Bid IQ"
                        description="Upload one of your estimates to see how it compares to others"
                        buttonText="Upload Estimate"
                        disableSkip={true}
                    />
                )}
                {step === 1 ? (
                    <div
                        style={{
                            width: '80%',
                            maxWidth: 500,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Body
                            style={{
                                textAlign: 'center',
                                fontSize: 20,
                                fontWeight: 'bold',
                            }}
                        >
                            What is your postal code ?
                        </Body>
                        <Input
                            placeholder="Postal Code"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            style={{ width: '90%', marginTop: 20 }}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onSubmitPostalCode}
                            style={{
                                marginTop: 20,
                                width: '90%',
                                fontWeight: '600',
                                color: 'white',
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                ) : null}
                {step === 2 ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            width: '90%',
                            maxWidth: 500,
                        }}
                    >
                        <Body
                            style={{
                                fontSize: 22,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            {loadingText}
                        </Body>
                        <ProjectBreakdown />
                    </div>
                ) : null}
                {step === 3 ? (
                    <>
                        <Modal
                            open={showPaywall}
                            onClose={() => setShowPaywall(false)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    padding: 32,
                                    borderRadius: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '90%',
                                    maxWidth: 500,
                                }}
                            >
                                <Body
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        marginBottom: 32,
                                    }}
                                >
                                    Unlimited Access
                                </Body>
                                <Body
                                    style={{
                                        fontSize: 16,
                                        textAlign: 'center',
                                        marginBottom: 8,
                                    }}
                                >
                                    Get unlimited access to detailed market
                                    analysis, pricing recommendations, and more.
                                </Body>

                                <Body
                                    style={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        marginBottom: 32,
                                        color: '#666',
                                    }}
                                >
                                    Cancel anytime. No commitments.
                                </Body>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        onWebAppBidIQClickStartFreeTrial();
                                        setTimeout(() => {
                                            window.location.href =
                                                'https://swcostestimator.app.link/Syt64QUUoQb';
                                        }, 1000);
                                    }}
                                    style={{
                                        width: '100%',
                                        fontWeight: '600',
                                        color: 'white',
                                        marginBottom: 5,
                                    }}
                                >
                                    Start Free Trial
                                </Button>

                                <Body
                                    style={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: '#666',
                                    }}
                                >
                                    7 days free, then just $4.99/month
                                </Body>
                            </div>
                        </Modal>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                flex: 1,
                                width: '85%',
                                maxWidth: 600,
                            }}
                        >
                            <Body
                                style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Neighborhood:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    {analyzeData.area_name}
                                </span>
                            </Body>
                            <Body
                                style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Estimate:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    $
                                    {parseFloat(
                                        analyzeData.current_estimate
                                            .replace('$', '')
                                            .replace(',', '')
                                    ).toFixed(2)}
                                </span>
                            </Body>
                            <Body
                                style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Suggested range:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                    $
                                    {parseFloat(
                                        analyzeData.minimum
                                            .replace('$', '')
                                            .replace(',', '')
                                    ).toFixed(2)}{' '}
                                    - $
                                    {parseFloat(
                                        analyzeData.maximum
                                            .replace('$', '')
                                            .replace(',', '')
                                    ).toFixed(2)}
                                </span>
                            </Body>
                            <PriceRange
                                min={parseFloat(
                                    analyzeData.minimum
                                        .replace('$', '')
                                        .replace(',', '')
                                )}
                                max={parseFloat(
                                    analyzeData.maximum
                                        .replace('$', '')
                                        .replace(',', '')
                                )}
                                current={parseFloat(
                                    analyzeData.current_estimate
                                        .replace('$', '')
                                        .replace(',', '')
                                )}
                            />
                            {Object.keys(groupedChanges).map(
                                (key, groupIndex) => (
                                    <div
                                        key={key}
                                        style={{
                                            opacity: 0,
                                            animation: `fadeIn 0.6s ease-in forwards ${
                                                groupIndex * 0.4
                                            }s`,
                                        }}
                                    >
                                        <BodyBold style={{ marginTop: 20 }}>
                                            {key.charAt(0).toUpperCase() +
                                                key.slice(1)}
                                        </BodyBold>
                                        {groupedChanges[key].map(
                                            (
                                                change: any,
                                                itemIndex: number
                                            ) => (
                                                <Body
                                                    key={itemIndex}
                                                    style={{
                                                        paddingLeft: 10,
                                                        opacity: 0,
                                                        animation: `fadeIn 0.5s ease-in forwards ${
                                                            groupIndex * 0.4 +
                                                            (itemIndex + 1) *
                                                                0.15
                                                        }s`,
                                                    }}
                                                >
                                                    - {change.description}
                                                </Body>
                                            )
                                        )}
                                    </div>
                                )
                            )}
                            <div style={{ height: 100, width: '100%' }}></div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    onWebAppBidIQClickContinue();
                                    setShowPaywall(true);
                                }}
                                style={{
                                    marginTop: 40,
                                    width: '90%',
                                    fontWeight: '600',
                                    color: 'white',
                                    fontSize: 18,
                                    position: 'fixed',
                                    bottom: 35,
                                }}
                            >
                                Continue
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
        </Container>
    );
};
