import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Alert,
    Stack,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Dialog,
    List,
    ListItem,
    IconButton,
    Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Helmet } from 'react-helmet-async';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import SendIcon from '@mui/icons-material/Send';
import { getOS } from '../../util/isIOS';
import {
    ISharedCostEstimation,
    ISharedCostEstimationLineItem,
} from '../../services/public/types';
import pageTheme from '../../theme';
import PublicService from '../../services/public/service';
import { formatDateWithShortMonth } from '../../util/formatDateWithShortMonth';
import {
    onWebAppEstimationPreviewSend,
    onWebAppEstimationPreviewDownload,
    onWebAppEstimationPreviewApprove,
    onWebAppEstimationPreviewEdit,
    onWebAppEstimationPreviewOpen,
} from '../../tracking/trackers';
import { formatAmount } from '../../util/formatAmount';
import { capitalizeFirstLetter } from '../../util/formatname';
import { EMAIL } from '../../util/validators';
import { useBrowserDetails } from '../../services/browserDetails/context';
import { htmlToPDF } from '../../util/htmlToPDF';
import SignatureSection from './SignatureSection';
import { ISignatureData } from './types';
const EstimationPreview = ({
    isContractor = false,
}: {
    isContractor?: boolean;
}) => {
    const { url } = useParams();
    const { ipAddress, userAgent } = useBrowserDetails();
    const location = useLocation();
    const contentRef = useRef<HTMLDivElement>(null);
    const queryParams = new URLSearchParams(location.search);
    const signKey = queryParams.get('signKey');
    const [estimateData, setEstimateData] =
        useState<ISharedCostEstimation | null>(null);
    const [showInfoAlert, setShowInfoAlert] = useState(true);
    const [images, setImages] = useState<string[] | undefined>(undefined);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [showShareMenu, setShowShareMenu] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });
    const [signatureData, setSignatureData] = useState<ISignatureData>({
        typedSignatureText: '',
        signatureImage: null,
        legallyBindingChecked: false,
        approveEstimationChecked: false,
        signerEmail: '',
        signerName: '',
        signatureType: 'typed',
        originalText: '',
        deviceInfo: {
            userAgent: null,
            language: '',
            platform: '',
            screenSize: '',
            colorDepth: 0,
            timezone: '',
            cookiesEnabled: false,
        },
        ipAddress: null,
        consentText: {
            legallyBinding: '',
            approveEstimation: '',
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [isValidSignKey, setIsValidSignKey] = useState<boolean>(false);
    const [estimateEmailSent, setEstimateEmailSent] = useState<boolean>(false);

    const testUsersMobileNumbers = [
        '+18505178577', //Yiting's number
        '+14375523172', //Amir's number
        '+16099330068', //Sam's number
        '+16467094088', //Allie's number
        '+10000000001',
    ];

    const isTestUser = testUsersMobileNumbers.includes(
        estimateData?.user.mobile || ''
    );

    const clientEmail = estimateData?.metadata.client_info?.client_email || '';
    const businessEmail =
        estimateData?.metadata.business_info?.business_email || '';

    useEffect(() => {
        const fetchImages = async () => {
            if (estimateData?.metadata?.image_ids) {
                const imageIds = estimateData.metadata.image_ids;
                try {
                    const imageData = await Promise.all(
                        imageIds.map(async (imageId) => {
                            const response = await PublicService.getImageData(
                                imageId
                            );
                            return response.data.image_data;
                        })
                    );
                    setImages(imageData);
                } catch (error) {
                    console.error('Error fetching images:', error);
                }
            } else {
                console.log('No image IDs found in metadata');
            }
        };
        fetchImages();
    }, [estimateData]);

    const fetchData = async () => {
        try {
            const response = await PublicService.getEstimationData(url || '');
            const estimationData: ISharedCostEstimation =
                response.data.shared_cost_estimation;
            setEstimateData(estimationData);
            if (signKey) {
                validateSignKey();
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };

    const validateSignKey = async () => {
        if (!url || !signKey) return;
        try {
            const response = await PublicService.validateSignKey(url, signKey);
            if (response.status !== 'success') {
                console.error('Error validating sign key:', response);
                return;
            }
            setIsValidSignKey(true);
        } catch (error) {
            console.error('Error validating sign key:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDownload = async () => {
        setLoading(true);
        onWebAppEstimationPreviewDownload({
            receipt_id: estimateData?.estimate.receipt_id || '',
        });

        try {
            await htmlToPDF(
                'estimation-preview-component',
                `Estimate_#${estimateData?.metadata.estimate_no}`
            );
        } catch (error) {
            console.error('Failed to download PDF:', error);
            setSnackbar({
                open: true,
                message: 'Failed to download estimate',
                severity: 'error',
            });
        }
        setLoading(false);
    };

    const renderActionButtons = () => {
        if (estimateData?.metadata?.approval_status === 'approved') {
            return (
                <>
                    <Grid item sx={{ width: '100%', margin: '0 auto' }}>
                        <Button
                            fullWidth
                            startIcon={<FileDownloadIcon />}
                            sx={{
                                'height': '52px',
                                'borderRadius': '6px',
                                'bgcolor': '#FFFFFF',
                                'color': pageTheme.colors.primary400,
                                'border': `1px solid ${pageTheme.colors.primary400}`,
                                '&:hover': {
                                    bgcolor: '#F5F9FF',
                                    border: `1px solid ${pageTheme.colors.primary400}`,
                                    color: pageTheme.colors.primary400,
                                },
                                'fontWeight': '600',
                                'fontSize': '1rem',
                                'textTransform': 'none',
                            }}
                            onClick={() => {
                                handleDownload();
                            }}
                        >
                            Download
                        </Button>
                    </Grid>
                </>
            );
        } else if (isContractor) {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        sm={estimateEmailSent ? 10 : 6}
                        sx={{ margin: '0 auto' }}
                    >
                        <Button
                            fullWidth
                            sx={{
                                'height': { xs: '48px', sm: '52px' },
                                'borderRadius': '0.5rem',
                                'border': `1px solid ${pageTheme.colors.primary400}`,
                                'color': pageTheme.colors.primary400,
                                '&:hover': {
                                    bgcolor: pageTheme.colors.primary400,
                                    color: 'white',
                                    borderColor: pageTheme.colors.primary400,
                                },
                                'fontWeight': '700',
                                'fontSize': {
                                    xs: '0.875rem',
                                    sm: '1rem',
                                },
                                'textTransform': 'none',
                            }}
                            onClick={handleEdit}
                        >
                            Back
                        </Button>
                    </Grid>
                    {/* If email is sent, remove share button */}
                    {!estimateEmailSent ? (
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                sx={{
                                    'height': { xs: '48px', sm: '52px' },
                                    'borderRadius': '0.5rem',
                                    'bgcolor': pageTheme.colors.primary400,
                                    'border': `1px solid ${pageTheme.colors.primary400}`,
                                    'color': '#fff',
                                    '&:hover': {
                                        bgcolor: 'white',
                                        color: pageTheme.colors.primary400,
                                        borderColor:
                                            pageTheme.colors.primary400,
                                    },
                                    'fontWeight': '700',
                                    'fontSize': {
                                        xs: '0.875rem',
                                        sm: '1rem',
                                    },
                                    'textTransform': 'none',
                                }}
                                onClick={() => setShowShareMenu(true)}
                            >
                                Send Estimate
                            </Button>
                        </Grid>
                    ) : null}
                </>
            );
        }
        return (
            <>
                <Grid item xs={12} sm={8} md={8}>
                    <Button
                        fullWidth
                        disabled={
                            !isValidSignKey
                                ? false
                                : !signatureData.legallyBindingChecked ||
                                  !signatureData.approveEstimationChecked ||
                                  !signatureData.signatureImage
                        }
                        sx={{
                            'height': { xs: '48px', sm: '52px' },
                            'borderRadius': '0.5rem',
                            'bgcolor': pageTheme.colors.primary400,
                            'color': '#fff',
                            '&:hover': {
                                bgcolor: 'white',
                                color: pageTheme.colors.primary400,
                                border: `1px solid ${pageTheme.colors.primary400}`,
                            },
                            'fontWeight': '700',
                            'fontSize': {
                                xs: '0.875rem',
                                sm: '1rem',
                            },
                            'textTransform': 'none',
                            '&.Mui-disabled': {
                                bgcolor: '#e0e0e0',
                                color: '#9e9e9e',
                            },
                        }}
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Button
                        fullWidth
                        sx={{
                            'height': { xs: '48px', sm: '52px' },
                            'borderRadius': '0.5rem',
                            'bgcolor': '#FFFFFF',
                            'color': pageTheme.colors.primary400,
                            'border': `1px solid ${pageTheme.colors.primary400}`,
                            '&:hover': {
                                bgcolor: pageTheme.colors.primary400,
                                border: `1px solid ${pageTheme.colors.primary400}`,
                                color: 'white',
                            },
                            'fontWeight': '700',
                            'fontSize': {
                                xs: '0.875rem',
                                sm: '1rem',
                            },
                            'textTransform': 'none',
                        }}
                        onClick={() => {
                            handleDownload();
                        }}
                    >
                        Download
                    </Button>
                </Grid>
            </>
        );
    };

    const handleEdit = () => {
        if (!estimateData) return;

        onWebAppEstimationPreviewEdit({
            receipt_id: estimateData.estimate.receipt_id,
        });

        window.location.href = `swce://open-estimate/${estimateData.estimate.receipt_id}`;
    };

    const handleEmailShare = async () => {
        if (!estimateData) return;
        onWebAppEstimationPreviewSend({
            receipt_id: estimateData.estimate.receipt_id,
            url: isTestUser
                ? `https://app.simplywise.com/estimation-preview/${url}?signKey=${signKey}`
                : `https://app.simplywise.com/estimation-preview/${url}`,
            method: 'email',
        });

        const clientPhone =
            estimateData.metadata.client_info?.client_phone || '';
        const estimateName = estimateData.estimate.merchant || 'Estimate';
        const estimateNo = estimateData.metadata.estimate_no || estimateData.id;

        // If its test user, send email to client
        if (isTestUser) {
            if (url) {
                const emailResponse =
                    await PublicService.sendSharedCostEstimationEmail(
                        url,
                        clientEmail,
                        clientPhone
                    );

                if (emailResponse.status === 'success') {
                    setSnackbar({
                        open: true,
                        message: 'Email sent to client successfully!',
                        severity: 'success',
                    });
                }
            }
        } else {
            const subject = `${estimateName} #${estimateNo}`;
            const body =
                `Hey, sending over the estimate for your project. Let me know if I can answer any questions.\n\n` +
                `View estimate: https://app.simplywise.com/estimation-preview/${url}`;

            const mailtoLink = `mailto:${encodeURIComponent(
                clientEmail
            )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
                body
            )}`;

            // Navigate to the mailto link
            window.location.href = mailtoLink;
        }
    };

    const handleGeneralShare = () => {
        if (!estimateData) return;
        onWebAppEstimationPreviewSend({
            receipt_id: estimateData.estimate.receipt_id,
            url: `https://app.simplywise.com/estimation-preview/${url}`,
            method: 'general',
        });
        navigator
            .share({
                title: `${estimateData.estimate.merchant || 'Estimate'} #${
                    estimateData.metadata.estimate_no
                }`,
                text: 'Hey, sending over the estimate for your project. Let me know if I can answer any questions.',
                url: `https://app.simplywise.com/estimation-preview/${url}`,
            })
            .catch(console.error);
        setShowShareMenu(false);
    };

    const handleApprove = async () => {
        if (!estimateData) return;

        setLoading(true);

        onWebAppEstimationPreviewApprove({
            receipt_id: estimateData.estimate.receipt_id,
            client_name: estimateData.metadata.client_info?.client_name || '',
            sign_key: signKey || '',
        });

        if (
            estimateData?.metadata?.notification_preferences?.approved &&
            url &&
            !localStorage.getItem(`notification_sent__approved_${url}`)
        ) {
            try {
                PublicService.sharedEstimateNotify(url, 'approved');
                localStorage.setItem(
                    `notification_sent__approved_${url}`,
                    'true'
                );
            } catch (error) {
                console.error('Notification error:', error);
            }
        }

        const signatureMetadata = {
            signature: {
                signer_info: {
                    signer_name: signatureData.signerName,
                    signer_email: signatureData.signerEmail,
                },
                signature_type: signatureData.signatureType,
                signature_image: signatureData.signatureImage,
                original_text: signatureData.originalText,
                device_info: {
                    user_agent: userAgent,
                    language: navigator.language,
                    platform: getOS() || 'unknown',
                    screen_size: `${window.screen.width}x${window.screen.height}`,
                    color_depth: window.screen.colorDepth,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    cookies_enabled: navigator.cookieEnabled,
                },
                legally_binding_checked: signatureData.legallyBindingChecked,
                ip_address: ipAddress,
                approve_estimation_checked:
                    signatureData.approveEstimationChecked,
                consent_text: {
                    legally_binding:
                        'I agree that my electronic signature is legally binding and equivalent to my handwritten signature to the extent permitted by applicable law.',
                    approve_estimation:
                        'I confirm I have reviewed and approve this estimation.',
                },
            },
            is_signed: true,
            signed_at: new Date().toISOString(),
            approval_status: 'approved',
        };

        if (url && signKey && isValidSignKey) {
            if (!EMAIL.isValidSync(signatureData.signerEmail)) {
                setSnackbar({
                    open: true,
                    message: 'Please enter a valid email address',
                    severity: 'error',
                });

                setLoading(false);
                return;
            }
            const updateResponse = await PublicService.updateSharedEstimation(
                url,
                signKey,
                signatureMetadata
            );
            if (updateResponse.status !== 'success') {
                setSnackbar({
                    open: true,
                    message: 'Failed to sign the estimation. Please try again.',
                    severity: 'error',
                });
                setLoading(false);
                return;
            }

            const emailResponse = await PublicService.sendSignedEstimationEmail(
                url
            );

            if (emailResponse.status === 'success') {
                setSnackbar({
                    open: true,
                    message:
                        'Estimate has been signed and approved successfully! ' +
                        'A copy of the signed estimate has been sent to your email.',

                    severity: 'success',
                });
                await new Promise((resolve) => setTimeout(resolve, 500));
                await fetchData();
            } else {
                setSnackbar({
                    open: true,
                    message:
                        'Estimate signed successfully but there was an issue sending the email. Please contact support.',
                    severity: 'error',
                });
            }
        } else {
            const message = `Hi ${estimateData.metadata.business_info?.business_name}, I approved your estimate. https://app.simplywise.com/estimation-preview/${url}`;
            const contractorPhoneNumber = estimateData.user.mobile;
            const smsLink = `sms:${contractorPhoneNumber}?&body=${encodeURIComponent(
                message
            )}`;
            window.location.href = smsLink;
        }

        setLoading(false);
    };

    useEffect(() => {
        if (estimateData?.estimate.receipt_id) {
            onWebAppEstimationPreviewOpen({
                receipt_id: estimateData.estimate.receipt_id,
                is_contractor: isContractor,
                has_sign_key: signKey ? true : false,
                sign_key: signKey || '',
            });

            if (
                !isContractor &&
                estimateData?.metadata?.notification_preferences?.seen &&
                url &&
                !localStorage.getItem(`notification_sent__seen_${url}`)
            ) {
                PublicService.sharedEstimateNotify(url, 'seen');
                localStorage.setItem(`notification_sent__seen_${url}`, 'true');
            }
        }
    }, [estimateData?.estimate.receipt_id, isContractor]);

    // Calculate totals
    const calculateTotal = (): {
        subtotal: number;
        tax: number;
        total: number;
        hasTax: boolean;
        markup: number;
    } => {
        if (!estimateData?.estimate)
            return { subtotal: 0, tax: 0, total: 0, hasTax: false, markup: 0 };

        // Calculate subtotal by summing all non-tax line items
        const subtotal =
            estimateData.estimate.line_items?.reduce(
                (sum: number, item: ISharedCostEstimationLineItem) => {
                    if (item.group === 'tax') {
                        return sum; // Skip tax line item
                    }
                    return sum + parseFloat((item.total || '0').toString());
                },
                0
            ) || 0;

        // Find tax line item
        const taxItems = estimateData.estimate.line_items?.filter(
            (item: ISharedCostEstimationLineItem) => item.group === 'tax'
        );

        // Get tax amount directly from the tax line item's total
        const taxTotal = taxItems.reduce(
            (sum: number, item: ISharedCostEstimationLineItem) => {
                return sum + parseFloat((item.total || '0').toString());
            },
            0
        );

        // Find markup line item
        const markupItem = estimateData.estimate.line_items?.find(
            (item: ISharedCostEstimationLineItem) =>
                item.group === 'other' &&
                item.amount_type === 'percentage' &&
                item.item === 'Markup'
        );

        const markup = markupItem
            ? parseFloat((markupItem.total || '0').toString())
            : 0;

        return {
            subtotal,
            tax: taxTotal,
            total: subtotal + taxTotal,
            hasTax: Boolean(taxTotal),
            markup,
        };
    };
    const totals = calculateTotal();

    const groupedLineItems = estimateData?.estimate.line_items
        .map((l: any, i: number) => ({ ...l, index: i }))
        .reduce((acc: any, l: any) => {
            const showLineItems = Boolean(
                estimateData.metadata.show_line_items
            );
            const showMarkup =
                !Boolean(estimateData.metadata.prorate_markup) &&
                estimateData.metadata.show_markup !== false;

            if (estimateData.metadata.prorate_markup) {
                const proratedMarkup =
                    totals.markup /
                    estimateData?.estimate.line_items.filter(
                        (l) =>
                            l.amount_type !== 'percentage' &&
                            l.group !== 'labor'
                    ).length;
                if (l.amount_type !== 'percentage' && l.group !== 'labor') {
                    l.unit_price =
                        parseFloat(l.unit_price) + proratedMarkup / l.quantity;
                    l.unit_price = l.unit_price.toFixed(2);
                    l.total += proratedMarkup;
                }
            }

            // Group all items in 'Other' trade to 'other'
            if (l.trade === 'Other') {
                l.group = 'other';
            }

            const isMarkupItem = l.group === 'other' && l.item === 'Markup';
            if (isMarkupItem && estimateData.metadata.markup_label) {
                l = {
                    ...l,
                    item: estimateData.metadata.markup_label,
                };
            }
            if (!showLineItems) {
                if (!isMarkupItem) return acc;
            }

            if (!showMarkup) {
                if (isMarkupItem) return acc;
            }

            // Scenario 1: Show both (no filtering needed)
            const group = acc.find((el: any) => el.trade === l.trade);
            const groupIndex = acc.findIndex((el: any) => el.trade === l.trade);

            if (group) {
                if (!group[l.group]) {
                    group[l.group] = [];
                }
                group[l.group].push(l);
                acc[groupIndex] = group;
            } else {
                acc.push({
                    trade: l.trade,
                    labor: [],
                    material: [],
                    equipment: [],
                    other: [],
                });
                if (!acc[acc.length - 1][l.group]) {
                    acc[acc.length - 1][l.group] = [];
                }
                acc[acc.length - 1][l.group].push(l);
            }

            return acc;
        }, [])
        .sort((a: any, b: any) => {
            // Special trades at the bottom, Fees last
            const specialTrades = ['Other', 'Tax', 'Fees'];
            const aIndex = specialTrades.indexOf(a.trade);
            const bIndex = specialTrades.indexOf(b.trade);

            if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
            }

            if (aIndex !== -1) return 1;
            if (bIndex !== -1) return -1;

            return 0;
        });

    const helmet = (
        <Helmet>
            <title>Cost Estimator | SimplyWise</title>
            <meta
                name="description"
                content="Review and manage cost estimations for your projects | SimplyWise"
            />
            <link
                rel="canonical"
                href="https://www.simplywise.com/estimation-preview"
            />
        </Helmet>
    );

    if (!estimateData)
        return (
            <>
                {helmet}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                        gap: 2,
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    <CircularProgress />
                    <Typography>Loading Estimate...</Typography>
                </Box>
            </>
        );

    const businessLogo = estimateData?.metadata?.business_info?.business_logo;

    // Display % instead of $ for percentage items
    const formatUnitPrice = (item: ISharedCostEstimationLineItem) => {
        if (item.amount_type === 'percentage') {
            return `${item.unit_price}%`;
        }
        return `$${item.unit_price}`;
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            {helmet}
            <Box
                sx={{
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: '#fff !important',
                    padding: { xs: 0, md: '24px' },
                    fontFamily: 'Inter, sans-serif',
                    paddingTop: { xs: 2 },
                }}
            >
                {estimateData?.metadata?.approval_status !== 'approved' && (
                    <Box sx={{ px: 2, py: { xs: 2, sm: 3 } }}>
                        <Stack
                            direction="row"
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            justifyContent="space-between"
                            spacing={{ xs: 1, sm: 0 }}
                            width="100%"
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: '1.25rem', sm: '1.75rem' },
                                    px: { xs: '6px' },
                                }}
                            >
                                Review Estimate
                            </Typography>
                            {isContractor && (
                                <Button
                                    startIcon={<FileDownloadIcon />}
                                    sx={{
                                        'color': pageTheme.colors.primary400,
                                        'fontSize': {
                                            xs: '0.875rem',
                                            sm: '1rem',
                                        },
                                        'textTransform': 'none',
                                        'fontWeight': 500,
                                        'padding': {
                                            xs: '0px 8px',
                                            sm: '0px 16px',
                                        },
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                            color: pageTheme.colors
                                                .primary400Light,
                                        },
                                    }}
                                    onClick={() => {
                                        handleDownload();
                                    }}
                                >
                                    Download
                                </Button>
                            )}
                        </Stack>
                    </Box>
                )}

                {/* Info Alert */}
                {showInfoAlert &&
                isContractor &&
                estimateData?.metadata?.approval_status !== 'approved' ? (
                    <Alert
                        severity="info"
                        sx={{
                            'mb': 2,
                            'backgroundColor': '#FDF4E7',
                            '& .MuiAlert-message': {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                            },
                            '& .MuiAlert-icon': {
                                display: 'none',
                            },
                            'ml': { xs: '16px', md: 0 },
                            'mr': { xs: '16px', md: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'stretch', sm: 'center' },
                                justifyContent: 'space-between',
                                gap: 2,
                                color: pageTheme.colors.text,
                                width: '100%',
                            }}
                        >
                            <Typography
                                fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                                sx={{
                                    flex: 1,
                                    textAlign: 'left',
                                }}
                            >
                                This is a preview of how your estimate will
                                appear to your client.
                            </Typography>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setShowInfoAlert(false)}
                                sx={{
                                    'backgroundColor': '#002A3C',
                                    'borderRadius': '4px',
                                    '&:hover': {
                                        backgroundColor: '#002A3C',
                                    },
                                    'whiteSpace': 'nowrap',
                                    'padding': '3px 10px',
                                    'fontSize': {
                                        xs: '0.75rem',
                                        sm: '0.75rem',
                                    },
                                    'alignSelf': 'flex-start',
                                }}
                            >
                                Got it
                            </Button>
                        </Box>
                    </Alert>
                ) : null}
                {/* Approval Banner: we are only showing this section for test users for now */}
                {isTestUser || isValidSignKey ? (
                    estimateData?.metadata?.approval_status === 'approved' ? (
                        <Box
                            sx={{
                                backgroundColor: '#F1F9F1',
                                border: '1px solid #E8F3E8',
                                borderRadius: '8px',
                                p: { xs: 1.5, sm: 2 },
                                mb: 2,
                                mx: { xs: 2, sm: 2, md: 0 },
                                display: 'flex',
                                gap: { xs: 1, sm: 1.5 },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckCircleIcon
                                    sx={{
                                        color: '#2E7D32',
                                        fontSize: { xs: '20px', sm: '24px' },
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: '#2E7D32',
                                        fontSize: {
                                            xs: '0.9rem',
                                            sm: '1.1rem',
                                        },
                                        fontWeight: 'bold',
                                        mb: 0.5,
                                    }}
                                >
                                    This estimate has been signed and approved
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#2E7D32',
                                        fontSize: {
                                            xs: '0.8rem',
                                            sm: '0.9rem',
                                        },
                                    }}
                                >
                                    Signed by{' '}
                                    {capitalizeFirstLetter(
                                        estimateData.metadata.signature
                                            ?.signer_info.signer_name ||
                                            'Client'
                                    )}{' '}
                                    on{' '}
                                    {new Date(
                                        estimateData.metadata.signed_at ?? ''
                                    ).toLocaleDateString()}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                backgroundColor: '#FFF8E5',
                                border: '1px solid #FFE5B2',
                                borderRadius: '8px',
                                p: { xs: 1.5, sm: 2 },
                                mb: 2,
                                mx: { xs: 2, sm: 2, md: 0 },
                                display: 'flex',
                                gap: { xs: 1, sm: 1.5 },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PendingIcon
                                    sx={{
                                        color: '#B76E00',
                                        fontSize: { xs: '20px', sm: '24px' },
                                    }}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    color: '#B76E00',
                                    fontSize: { xs: '0.9rem', sm: '1.1rem' },
                                    fontWeight: 'bold',
                                }}
                            >
                                Pending Approval
                            </Typography>
                        </Box>
                    )
                ) : null}
                {/* Main Content Section */}
                <Paper
                    id="estimation-preview-component"
                    elevation={0}
                    sx={{
                        border: `2px solid ${pageTheme.colors.border}`,
                    }}
                    ref={contentRef}
                >
                    {/* Estimate Header */}
                    <Box
                        sx={{
                            p: '6px 16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Stack direction="row">
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        mb: 0.5,
                                    }}
                                >
                                    <Typography
                                        color={pageTheme.colors.text}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: {
                                                xs: '1rem',
                                                sm: '1.25rem',
                                            },
                                            textAlign: 'left',
                                        }}
                                    >
                                        Estimate #
                                        {estimateData.metadata.estimate_no
                                            ? estimateData.metadata.estimate_no
                                            : estimateData.id}
                                    </Typography>
                                </Box>
                                <Typography
                                    fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                                    fontWeight="600"
                                    color={pageTheme.colors.text}
                                    sx={{ textAlign: 'left' }}
                                >
                                    Date:{' '}
                                    {formatDateWithShortMonth(
                                        new Date(
                                            estimateData.estimate.date ?? ''
                                        )
                                    )}
                                </Typography>
                                {estimateData.metadata.business_info
                                    ?.business_website && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                xs: 'column',
                                                sm: 'row',
                                            },
                                            gap: { xs: 0.5, sm: 1 },
                                            alignItems: {
                                                xs: 'flex-start',
                                                sm: 'center',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.75rem',
                                                    sm: '0.9rem',
                                                },
                                            }}
                                            fontWeight="600"
                                            color={pageTheme.colors.text}
                                        >
                                            Learn more about us at:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.75rem',
                                                    sm: '0.9rem',
                                                },
                                                color: pageTheme.colors
                                                    .primary400,
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                wordBreak: 'break-all',
                                            }}
                                            onClick={() =>
                                                window.open(
                                                    estimateData.metadata
                                                        .business_info
                                                        ?.business_website,
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                )
                                            }
                                        >
                                            {estimateData.metadata.business_info
                                                ?.business_website || '\u00A0'}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                        {businessLogo && (
                            <Box>
                                <img
                                    src={`data:image/png;base64,${businessLogo}`}
                                    alt="Business Logo"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>

                    {/* Business and Client Information */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                        }}
                    >
                        {/* Business Information Section */}
                        {estimateData.metadata.business_info &&
                            Object.values(
                                estimateData.metadata.business_info
                            ).some((value) => value) && (
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            bgcolor: '#D9D9D9',
                                            padding: '6px 16px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.875rem',
                                                    sm: '1rem',
                                                },
                                                fontWeight: 'bold',
                                            }}
                                            color={pageTheme.colors.text}
                                        >
                                            Business Information
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: '6px 16px',
                                            pl: { xs: '16px', sm: '20px' },
                                        }}
                                    >
                                        <Typography
                                            sx={{ minHeight: '24px' }}
                                            fontWeight="bold"
                                            fontSize={{
                                                xs: '0.75rem',
                                                sm: '0.875rem',
                                            }}
                                        >
                                            {estimateData.metadata.business_info
                                                ?.business_name || ''}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Address:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .business_info
                                                    ?.business_address ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Email:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {businessEmail || '\u00A0'}
                                            </Typography>
                                        </Box>{' '}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Phone:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .business_info
                                                    ?.business_phone ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                        {/* Client Information Section */}
                        {estimateData.metadata.client_info &&
                            Object.values(
                                estimateData.metadata.client_info
                            ).some((value) => value) && (
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            bgcolor: '#D9D9D9',
                                            padding: {
                                                xs: '6px 16px',
                                                sm: '6px 6px',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xs: '0.875rem',
                                                    sm: '1rem',
                                                },
                                                fontWeight: 'bold',
                                            }}
                                            color={pageTheme.colors.text}
                                        >
                                            Client Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: '6px 16px' }}>
                                        <Typography
                                            sx={{ minHeight: '24px' }}
                                            fontWeight="bold"
                                            fontSize={{
                                                xs: '0.75rem',
                                                sm: '0.875rem',
                                            }}
                                        >
                                            {estimateData.metadata.client_info
                                                ?.client_name || '\u00A0'}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Address:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .client_info
                                                    ?.client_address ||
                                                    '\u00A0'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Email:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .client_info
                                                    ?.client_email || '\u00A0'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                justifyContent: 'baseline',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={pageTheme.colors.text}
                                            >
                                                Phone:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.75rem',
                                                        sm: '0.9rem',
                                                    },
                                                }}
                                                color={
                                                    pageTheme.colors.grayText
                                                }
                                            >
                                                {estimateData.metadata
                                                    .client_info
                                                    ?.client_phone || '\u00A0'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                    </Box>
                    {/*Project Breakdown*/}
                    {estimateData?.metadata?.show_project_breakdown && (
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    bgcolor: '#D9D9D9',
                                    padding: '6px 16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '0.875rem',
                                            sm: '1rem',
                                        },
                                        fontWeight: 'bold',
                                    }}
                                    color={pageTheme.colors.text}
                                >
                                    Project Breakdown
                                </Typography>
                            </Box>
                            <Box sx={{ p: '6px 16px' }}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                        whiteSpace: 'pre-line', // Preserves line breaks (\n), collapses spaces, allows text wrapping
                                    }}
                                >
                                    {/* Converts string '\n' to actual line breaks, then pre-line renders them as new lines */}
                                    {estimateData?.metadata?.project_breakdown?.replace(
                                        /\\n/g,
                                        '\n'
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {/* Costs Table */}
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                bgcolor: '#D9D9D9',
                                padding: '6px 16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '0.875rem',
                                        sm: '1rem',
                                    },
                                    fontWeight: 'bold',
                                }}
                                color={pageTheme.colors.text}
                            >
                                Costs Table
                            </Typography>
                        </Box>

                        {(estimateData.metadata.show_line_items ||
                            estimateData.metadata.show_markup) && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                bgcolor: '#f2f2f2 !important',
                                            }}
                                        >
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '1rem',
                                                    },
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Item Description
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '1rem',
                                                    },
                                                    fontWeight: 'bold',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Unit Price
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: {
                                                        xs: '0.8rem',
                                                        sm: '1rem',
                                                    },
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupedLineItems?.map(
                                            (
                                                group: any,
                                                groupIndex: number
                                            ) => (
                                                <Fragment key={groupIndex}>
                                                    {/* Group Title Row */}
                                                    <TableRow
                                                        sx={{
                                                            bgcolor:
                                                                '#f5f5f5  !important',
                                                        }}
                                                    >
                                                        <TableCell colSpan={3}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize: {
                                                                        xs: '0.7rem',
                                                                        sm: '0.85rem',
                                                                    },
                                                                }}
                                                            >
                                                                {group.trade}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    {/* SubGroups */}
                                                    {Object.entries(group)
                                                        .filter(
                                                            ([key, items]) =>
                                                                key !==
                                                                    'trade' &&
                                                                Array.isArray(
                                                                    items
                                                                ) &&
                                                                (items as any[])
                                                                    .length > 0
                                                        )
                                                        .map(
                                                            ([
                                                                category,
                                                                items,
                                                            ]) => (
                                                                <Fragment
                                                                    key={`${group.trade}-${category}`}
                                                                >
                                                                    {/* TradeTitle Row */}

                                                                    <TableRow
                                                                        sx={{
                                                                            bgcolor:
                                                                                '#f8f8f8 !important',
                                                                            paddingLeft: 2,
                                                                        }}
                                                                    >
                                                                        <TableCell
                                                                            sx={{
                                                                                fontSize:
                                                                                    {
                                                                                        xs: '0.7rem',
                                                                                        sm: '0.85rem',
                                                                                    },
                                                                                fontWeight:
                                                                                    '500',
                                                                                paddingLeft: 3,
                                                                            }}
                                                                        >
                                                                            {category
                                                                                .charAt(
                                                                                    0
                                                                                )
                                                                                .toUpperCase() +
                                                                                category.slice(
                                                                                    1
                                                                                )}
                                                                        </TableCell>

                                                                        {/* Second Column - Empty for Unit Price */}
                                                                        <TableCell align="center"></TableCell>

                                                                        {/* Third Column - Total */}
                                                                        <TableCell
                                                                            align="right"
                                                                            sx={{
                                                                                fontSize:
                                                                                    {
                                                                                        xs: '0.7rem',
                                                                                        sm: '0.85rem',
                                                                                    },
                                                                                fontWeight:
                                                                                    '500',
                                                                            }}
                                                                        >
                                                                            {formatAmount(
                                                                                (
                                                                                    items as any[]
                                                                                ).reduce(
                                                                                    (
                                                                                        sum: number,
                                                                                        item: any
                                                                                    ) =>
                                                                                        sum +
                                                                                        parseFloat(
                                                                                            item.total ||
                                                                                                '0'
                                                                                        ),
                                                                                    0
                                                                                )
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>

                                                                    {/* Subgroup line items */}
                                                                    {/* Only show individual line items if not in grouped mode */}
                                                                    {!(
                                                                        estimateData
                                                                            .metadata
                                                                            .line_item_display ===
                                                                            'grouped' &&
                                                                        estimateData
                                                                            .metadata
                                                                            .show_line_items
                                                                    ) &&
                                                                        (
                                                                            items as any[]
                                                                        ).map(
                                                                            (
                                                                                item: any,
                                                                                index: number
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={`${category}-${index}`}
                                                                                    sx={{
                                                                                        bgcolor:
                                                                                            'white !important',
                                                                                    }}
                                                                                >
                                                                                    <TableCell
                                                                                        sx={{
                                                                                            pl: 4,
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    {
                                                                                                        xs: '0.65rem',
                                                                                                        sm: '0.8rem',
                                                                                                    },
                                                                                                textAlign:
                                                                                                    'left',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                item.item
                                                                                            }
                                                                                        </Typography>
                                                                                        {item.item !==
                                                                                            'Markup' && (
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                color="text.secondary"
                                                                                                sx={{
                                                                                                    fontSize:
                                                                                                        {
                                                                                                            xs: '0.6rem',
                                                                                                            sm: '0.75rem',
                                                                                                        },
                                                                                                    textAlign:
                                                                                                        'left',
                                                                                                }}
                                                                                            >
                                                                                                Quantity:{' '}
                                                                                                {
                                                                                                    item.quantity
                                                                                                }
                                                                                            </Typography>
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            margin="auto"
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    {
                                                                                                        xs: '0.65rem',
                                                                                                        sm: '0.8rem',
                                                                                                    },
                                                                                            }}
                                                                                        >
                                                                                            {item.unit_price &&
                                                                                                formatUnitPrice(
                                                                                                    item
                                                                                                )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            align="right"
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    {
                                                                                                        xs: '0.65rem',
                                                                                                        sm: '0.8rem',
                                                                                                    },
                                                                                            }}
                                                                                        >
                                                                                            {formatAmount(
                                                                                                parseFloat(
                                                                                                    item.total ||
                                                                                                        '0'
                                                                                                )
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        )}
                                                                </Fragment>
                                                            )
                                                        )}
                                                </Fragment>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>

                    {/* Totals Section */}
                    <Box sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    'display': 'flex',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    'position': 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        left: '80px',
                                        right: '80px',
                                        top: '50%',
                                        borderBottom: '3px dotted #ccc',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontSize: {
                                            xs: '0.7rem',
                                            sm: '1rem',
                                        },
                                    }}
                                >
                                    Sub Total
                                </Typography>
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontSize: {
                                            xs: '0.7rem',
                                            sm: '1rem',
                                        },
                                    }}
                                >
                                    {formatAmount(totals.subtotal)}
                                </Typography>
                            </Box>
                            {totals.hasTax && (
                                <Box
                                    sx={{
                                        'display': 'flex',
                                        'justifyContent': 'space-between',
                                        'alignItems': 'center',
                                        'position': 'relative',
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            left: '80px',
                                            right: '80px',
                                            top: '50%',
                                            borderBottom: '3px dotted #ccc',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            bgcolor: '#fff',
                                            position: 'relative',
                                            zIndex: 1,
                                            fontSize: {
                                                xs: '0.7rem',
                                                sm: '1rem',
                                            },
                                        }}
                                    >
                                        Tax
                                    </Typography>
                                    <Typography
                                        sx={{
                                            bgcolor: '#fff',
                                            position: 'relative',
                                            zIndex: 1,
                                            fontSize: {
                                                xs: '0.7rem',
                                                sm: '1rem',
                                            },
                                        }}
                                    >
                                        {formatAmount(totals.tax)}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    'display': 'flex',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    'position': 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        left: '80px',
                                        right: '80px',
                                        top: '50%',
                                        borderBottom: '3px dotted #ccc',
                                    },
                                }}
                            >
                                <Typography
                                    color={pageTheme.colors.text}
                                    sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xs: '0.8rem',
                                            sm: '1.25rem',
                                        },
                                    }}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    sx={{
                                        bgcolor: '#fff',
                                        position: 'relative',
                                        zIndex: 1,
                                        fontWeight: 'bold',
                                        fontSize: {
                                            xs: '0.8rem',
                                            sm: '1.25rem',
                                        },
                                    }}
                                >
                                    {formatAmount(totals.total)}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>

                    {/* Notes Section */}
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ p: 3 }}>
                            <Typography
                                variant="body1"
                                color={pageTheme.colors.text}
                                sx={{
                                    fontSize: {
                                        xs: '0.875rem',
                                        sm: '1rem',
                                    },
                                    fontWeight: 'bold',
                                }}
                            >
                                Notes:{' '}
                                <Typography
                                    variant="body1"
                                    color={pageTheme.colors.text}
                                    sx={{
                                        fontSize: {
                                            xs: '0.75rem',
                                            sm: '0.875rem',
                                        },
                                    }}
                                >
                                    {estimateData.metadata.notes_content
                                        .project_notes || 'No notes provided'}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>

                    {/**Signature Section */}
                    {isTestUser || isValidSignKey ? (
                        <SignatureSection
                            signatureData={signatureData}
                            setSignatureData={setSignatureData}
                            estimateData={estimateData}
                            isContractor={isContractor}
                        />
                    ) : null}

                    {/* Image Section */}
                    {images && images.length > 0 && (
                        <Box
                            sx={{
                                'width': '100%',
                                'p': '16px',
                                'display': 'flex',
                                'mx': '16px',
                                'flexWrap': 'wrap',
                                'gap': '16px',
                                '& img': {
                                    width: { xs: '6rem', sm: '12rem' },
                                    height: { xs: '6rem', sm: '12rem' },
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                },
                            }}
                        >
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={`data:image/png;base64,${image}`}
                                    alt={`${index + 1}`}
                                    onClick={() => setSelectedImage(image)}
                                />
                            ))}
                        </Box>
                    )}
                </Paper>

                {/* Image Enlarged View */}
                <Dialog
                    open={Boolean(selectedImage)}
                    onClose={() => setSelectedImage(null)}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box
                        sx={{
                            position: 'relative',
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '90vh',
                        }}
                    >
                        <IconButton
                            onClick={() => setSelectedImage(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white',
                                zIndex: 1,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedImage && (
                            <img
                                src={`data:image/png;base64,${selectedImage}`}
                                alt="Enlarged view"
                                style={{
                                    maxWidth: '95%',
                                    maxHeight: '85vh',
                                    objectFit: 'contain',
                                    margin: 'auto',
                                }}
                            />
                        )}
                    </Box>
                </Dialog>

                {/* Action Buttons */}
                <Grid
                    container
                    spacing={2}
                    sx={{
                        flexDirection: 'row',
                        p: '16px',
                        paddingTop: 0,
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                    }}
                >
                    {renderActionButtons()}
                </Grid>
            </Box>

            {/* Show menu with sharing options for non-test users/ ask for business and client email before sending estimate/confirm email delivery */}
            <Dialog
                open={showShareMenu}
                onClose={() => setShowShareMenu(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        minWidth: '300px',
                        padding: '8px',
                    },
                }}
            >
                {isTestUser ? (
                    !clientEmail || !businessEmail ? (
                        <Box
                            sx={{
                                p: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3,
                                alignItems: 'center',
                                maxWidth: '400px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#4A4A4A',
                                    fontSize: {
                                        xs: '1.1rem',
                                        sm: '1.25rem',
                                    },
                                    mb: 1,
                                    lineHeight: 1.6,
                                }}
                            >
                                To send this estimate, please provide both your{' '}
                                <span
                                    style={{
                                        color: pageTheme.colors.primary400,
                                        fontWeight: 600,
                                        display: 'inline',
                                    }}
                                >
                                    Business Email and Client Email
                                </span>
                                . <br />
                                We'll send a copy to you and your client.
                            </Typography>
                            <Button
                                onClick={handleEdit}
                                variant="contained"
                                sx={{
                                    'bgcolor': pageTheme.colors.primary400,
                                    'fontWeight': 600,
                                    'px': { xs: 2, sm: 4 },
                                    'py': { xs: 1, sm: 1.5 },
                                    'borderRadius': '10px',
                                    'textTransform': 'none',
                                    'fontSize': { xs: '0.9rem', sm: '1.1rem' },
                                    'boxShadow': 'none',
                                    '&:hover': {
                                        bgcolor:
                                            pageTheme.colors.primary400Light,
                                        boxShadow: 'none',
                                    },
                                    'gap': 2,
                                }}
                            >
                                <EmailIcon /> Add Email
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ p: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: 2,
                                    pb: 1,
                                    borderBottom: '1px solid #E5E5E5',
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: pageTheme.colors.primary400,
                                        borderRadius: '10px',
                                        width: 32,
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ml: 1,
                                    }}
                                >
                                    <EmailIcon
                                        sx={{
                                            color: '#fff',
                                            fontSize: { xs: 20, sm: 24 },
                                        }}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '1.2rem',
                                            sm: '1.4rem',
                                        },
                                        fontWeight: 700,
                                        color: '#000',
                                    }}
                                >
                                    Confirm Email Delivery
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: 3,
                                    px: { xs: 2, sm: 4 },
                                    py: 2,
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#4A4A4A',
                                            fontSize: {
                                                xs: '1.1rem',
                                                sm: '1.25rem',
                                            },
                                            mb: 1,
                                            lineHeight: 1.6,
                                        }}
                                    >
                                        This estimate will be sent to{' '}
                                        <span
                                            style={{
                                                color: pageTheme.colors
                                                    .primary400,
                                                fontWeight: 600,
                                                display: 'block',
                                            }}
                                        >
                                            {
                                                estimateData?.metadata
                                                    .client_info?.client_email
                                            }
                                        </span>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#666',
                                            fontSize: {
                                                xs: '0.9rem',
                                                sm: '1.1rem',
                                            },
                                        }}
                                    >
                                        You will be CC'd on this email.
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Button
                                    onClick={() => setShowShareMenu(false)}
                                    variant="outlined"
                                    sx={{
                                        'color': '#666',
                                        'fontWeight': 500,
                                        'px': { xs: 2, sm: 4 },
                                        'py': { xs: 1, sm: 1.5 },
                                        'fontSize': {
                                            xs: '0.9rem',
                                            sm: '1rem',
                                        },
                                        'textTransform': 'none',
                                        'border': '1px solid #E5E5E5',
                                        'borderRadius': '10px',
                                        '&:hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.04)',
                                            border: '1px solid #E5E5E5',
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleEmailShare();
                                        setEstimateEmailSent(true);
                                        setShowShareMenu(false);
                                    }}
                                    variant="contained"
                                    endIcon={<SendIcon sx={{ ml: 1 }} />}
                                    sx={{
                                        'bgcolor': pageTheme.colors.primary400,
                                        'fontWeight': 500,
                                        'px': { xs: 2, sm: 4 },
                                        'py': { xs: 1, sm: 1.5 },
                                        'borderRadius': '10px',
                                        'textTransform': 'none',
                                        'fontSize': {
                                            xs: '0.9rem',
                                            sm: '1rem',
                                        },
                                        'boxShadow': 'none',
                                        '&:hover': {
                                            bgcolor:
                                                pageTheme.colors
                                                    .primary400Light,
                                            boxShadow: 'none',
                                        },
                                    }}
                                >
                                    Send Estimate
                                </Button>
                            </Stack>
                        </Box>
                    )
                ) : (
                    <List>
                        <ListItem
                            onClick={handleEmailShare}
                            sx={{
                                'borderRadius': '8px',
                                'mb': 1,
                                'cursor': 'pointer',
                                '&:hover': {
                                    'bgcolor': pageTheme.colors.primary400,
                                    '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                                        {
                                            color: 'white',
                                        },
                                },
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <EmailIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Email"
                                primaryTypographyProps={{
                                    sx: { fontWeight: 500 },
                                }}
                            />
                        </ListItem>
                        <ListItem
                            onClick={handleGeneralShare}
                            sx={{
                                'borderRadius': '8px',
                                'cursor': 'pointer',
                                '&:hover': {
                                    'bgcolor': pageTheme.colors.primary400,
                                    '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                                        {
                                            color: 'white',
                                        },
                                },
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <ShareIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Other sharing options"
                                primaryTypographyProps={{
                                    sx: { fontWeight: 500 },
                                }}
                            />
                        </ListItem>
                    </List>
                )}
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 9999,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={60} />
                    <Typography
                        sx={{
                            mt: 2,
                            fontSize: '1.1rem',
                            fontWeight: 500,
                        }}
                    >
                        Loading Estimate...
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default EstimationPreview;
