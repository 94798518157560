import styled from 'styled-components';
import theme from '../../theme';

export const QuestionaireContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.background,
});

export const ContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: -20, // Overlap with header
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 20,
    padding: 20,
    width: '100%',
    height: '100vh',
    boxShadow: theme.spreadShadow,
    zIndex: 1,
    position: 'relative',
});

export const PreviewBanner = styled.div({
    width: '100%',
    background: 'linear-gradient(135deg, #FF4B2B 0%, #FF416C 100%)',
    color: theme.colors.white,
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 50,
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1)',
});
