import styled from 'styled-components';
import theme from '../../../../theme';
import Devices from '../../../../util/devices';

export const HeaderContainer = styled.div`
    width: 100%;
    height: 150px;
    background-color: ${theme.colors.blue100};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;

    @media ${Devices.TABLET} {
        height: 200px;
    }
`;

export const HeaderLeaf = styled.img`
    position: absolute;
    top: -20px;
    left: 0px;
    z-index: 0;

    @media (min-width: 768px) {
        left: 100px;
    }
`;

export const HeaderTitle = styled.div({
    color: theme.colors.white,
    fontSize: '24px',
    fontWeight: 'bold',
    zIndex: 1,
});
