import { lazy } from 'react';
import SVG from 'react-inlinesvg';
import AIReceiptionistHero from './assets/ai-receptionist-phone.svg';
import MileageTrackerAppIcon from './assets/mileage-tracker-icon.svg';
import CostEstimatorAppIcon from './assets/cost-estimator-icon.svg';
import ReceiptsAppIcon from './assets/receipts-icon.svg';
import AIReceiptionistAppIcon from './assets/ai-receptionist-icon.svg';
import MileageHero from './assets/animations/svGator/LP-MultipleApps-Mileage';
import ReceiptsHero from './assets/animations/svGator/LP-MultipleApps-Reciept';
import CostEstimatorHero from './assets/animations/svGator/LP-MultipleApps-Estimate';
import { PhoneAppJumbo } from './util/inlineSVGs';
import StarsIcon from './assets/icons/stars-icon.svg';
import AmazonReconcile from './assets/landing/amazonReconcile.svg';
import HeaderLogo from './assets/landing/logo_white.svg';
import MetricsImage from './assets/landing/metric.svg';
import MileageTracking from './assets/landing/mileageTracking.svg';
import TeamTexting from './assets/landing/team_text.png';
// import SubAppHeroImagePlacement from './assets/landing/sub-apps-hero-placement.jpg';
import LeafForHeader from './assets/leafForHeader.svg';
import LeafForHeaderVariation from './assets/leaf-watermark-variation.svg';
import LeafFull from './assets/leaf-header-full.svg';
import {
    AnimatedSectionItem,
    LandingPageConfig,
    VariationConfig,
} from './components/LandingPageVariations/configuration/types';
import { SectionAnimation } from './components/LandingPageVariations/configuration/Variation';
import { CTAAlignmentWrapper } from './components/LandingPageVariations/elements/styles';
import { MetricsShowcase } from './components/Onboarding/MetricsShowcase';
import Video from './components/Video';
import { PreSignupSurveyScreen } from './screens/Onboarding/PreSignupSurvey/context';
import theme from './theme';
import StarRating from './assets/star-rating.svg';
import StarRatingVariation from './assets/start-rating-variation.svg';
const SVGComponent = SVG as React.FC<any>;

// you might want to use this if you're making changes to a page that isn't the landing page, but is part of the same cohort
// you'd get the landingPageName from localStorage, then get activeCohort with useExperiment(landingPageName)
export const getVariation = (
    landingPageName: string,
    variationName?: string
) => {
    const config = landingPageVariationsConfig.find(
        (config) => config.landingPageName === landingPageName
    );
    if (!config) return undefined;
    const variation = config.baseVariation;
    const abVariation = !variationName
        ? undefined
        : config.ABVariations?.[variationName];
    const v: VariationConfig = { ...variation, ...abVariation };
    return v;
};

const ConstructionLandingJumbotron = lazy(
    () => import('./assets/animations/svGator/ConstructionLandingJumbotron')
);

/* eslint-disable @typescript-eslint/no-unused-vars */
const BASE_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader: 'Scan paper receipts and our AI extracts the important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'Automatically import email receipts from your email accounts',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader: 'Make custom folders and subfolders that fit your needs',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Run Reports',
        subheader:
            'Generate reports that make expense tracking and taxes a breeze.',
        animation: SectionAnimation.REPORTS,
    },
];

const CONSTRUCTION_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Track Every Expense',
        subheader:
            'Know exactly what you’re making (or losing) by project. Our A.I. organizes all your paper & email receipts and invoices for full cost transparency.',
        animation: SectionAnimation.CON_TRACK,
    },
    {
        header: 'Accurate Instant Bids',
        subheader:
            'Snap a photo to draft an estimate in seconds. Get links to live local prices for materials.',
        animation: SectionAnimation.CON_BIDS,
    },
    {
        header: 'Maximize Writeoffs',
        subheader:
            '1-click tax reports capture every deduction, so you never pay more than you owe.',
        animation: SectionAnimation.CON_WRITEOFFS,
    },
];

const TEAMS_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Collect employee receipts by text',
        subheader:
            'No more lost receipts. Your AI Receipt Admin collects receipts via text, ensuring 100% adoption.',
        animation: SectionAnimation.TEXTING,
    },
    {
        header: 'AI Admin performs magic',
        subheader:
            'Your Receipt Admin extracts the important info from every texted receipts.',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Run Reports',
        subheader: 'Generate reports that make expense tracking a breeze',
        animation: SectionAnimation.REPORTS,
    },
];

const TEXTING_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Collect employee receipts by text',
        subheader: 'Employees digitize receipts in seconds',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Run Reports',
        subheader: 'See receipts in real-time reports',
        animation: SectionAnimation.REPORTS,
    },
    {
        header: 'Sync with Quickbooks',
        subheader: 'Expenses are automatically sent to Quickbooks',
        animation: SectionAnimation.QUICKBOOKS,
    },
];

const SALESTAX_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader:
            'Scan paper receipts and our A.I. extracts the sales tax, along with other important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'A.I. automatically detects email receipts from your inbox and parses the sales tax',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader:
            'Make custom folders and subfolders that fit your needs. Our A.I. suggests folders for your expenses based on your behavior.',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Run Sales Tax Reports',
        subheader:
            'Generate reports that make totalling your sales tax across expenses a breeze.',
        animation: SectionAnimation.REPORTS,
    },
];

const QUICKBOOKS_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader: 'Scan paper receipts and our AI extracts the important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'Automatically import email receipts from your email accounts',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader: 'Make custom folders and subfolders that fit your needs',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Sync',
        subheader: 'Sync your receipts and expenses to Quickbooks',
        animation: SectionAnimation.REPORTS,
    },
];

export const BASE_VARIATION_1: VariationConfig = {
    header: 'Tame Receipt Chaos',
    description: 'Transform email and paper clutter into life-changing reports',
    heroCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    tabCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    finalCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviews: 10,
};

export const BASE_VARIATION_UPDATE: VariationConfig = {
    header: 'Tame Receipt Chaos',
    scrollableHeader: false,
    headerLogo: <img src={HeaderLogo} alt="simplywise logo" />,
    headerWatermark: (
        <img
            src={LeafForHeader}
            alt="watermark"
            style={{
                position: 'absolute',
                top: 0,
                left: 480,
                height: 80,
            }}
        />
    ),
    headerLeafFull: <img src={LeafFull} alt="leaf" />,
    headerLeafPath:
        'M395.2 362.74c.17 1.55-.08 3.3 0 4.9s-.62 4.07.98 4.9c1.26 63.8-9.18 104.46-59.37 146.13-2.67 2.21-16.22 13.23-18.41 12.67-1.68-.43-20.95-22.15-23.76-25.38-54.92-63.34-79.43-121.14-116.73-194.21-.58-1.13-.91-2.71-2.43-2.96 5.3 28.44 16.52 56.68 28.26 83.06 32.91 73.95 82.66 145.51 137.98 204.35 4.14 4.4 31.37 29.73 31.86 31.93.31 1.39-8.75 7.27-10.44 8.09-6.49 3.16-7.72 3.27-12.58-1.78-24.98-25.88-48.47-53.3-69.81-82.29-71.41 13.99-134.14-7.73-182.28-60.92-43.76-48.35-72.24-108.31-86.79-171.67C-10.58 222.68 1.53 128.67 26.66 33.93c2.57-9.68 5.23-22.63 8.55-31.67.3-.83.38-2.07 1.43-2.27 24.6 7.73 49.11 15.93 72.8 26.12 129.93 55.93 269.35 189.77 285.76 336.62Z',
    headerStyle: {
        backgroundColor: theme.colors.primary,
        padding: 10,
        height: 80,
        overflow: 'hidden',
    },
    // slogan: 'We handle the Complexity, You focus on Growth',
    slogan: "You build. We'll do the busywork.",
    description: 'Transform email and paper clutter into life-changing reports',
    heroCallToAction: {
        label: 'Download',
        labelStyle: {
            fontSize: 20,
            marginRight: 5,
        },
        sublabel: "it's free",
        sublabelStyle: {
            fontSize: 20,
        },
        action: 'DownloadApps',
        secondaryAction: 'SendDownloadLink',
        style: {
            backgroundColor: theme.colors.secondary,
            color: theme.colors.white,
            borderRadius: 100,
            width: 300,
            height: 64,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tabCallToAction: {
        label: 'Get Started Free',
        labelStyle: {
            fontSize: 16,
            margin: '0px 5px',
        },
        action: 'DownloadApps',
        secondaryAction: 'SendDownloadLink',
        style: {
            backgroundColor: theme.colors.secondary,
            color: theme.colors.white,
            borderRadius: 30,
            zIndex: 100,
            marginLeft: 20,
        },
    },
    tabLoginButton: {
        label: 'Log in',
        labelStyle: {
            fontSize: 16,
            margin: '0px 5px',
        },
        action: 'Login',
        secondaryAction: 'Login',
        style: {
            backgroundColor: theme.colors.white,
            color: theme.colors.primary,
            borderRadius: 30,
            zIndex: 100,
        },
    },
    primaryVisualComp: <SVGComponent src={PhoneAppJumbo} />,
    primaryVisualWidth: 500,
    primaryVisualHeight: 500,
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <img src={StarsIcon} alt="metrics showcase" />
        </CTAAlignmentWrapper>
    ),
    title: 'A.I. that Automates Every Job',
    subApps: [
        {
            name: 'Cost Estimator',
            // url: '/lp-cost-estimator',
            url: 'swcostestimator.app.link',
            appStoreLink:
                'https://apps.apple.com/us/app/simplywise-cost-estimator/id6670619664',
            playStoreLink:
                'https://play.google.com/store/apps/details?id=com.simplywise.costestimator',
            icon: <img src={CostEstimatorAppIcon} alt="app icon" />,
            heroImage: <CostEstimatorHero />,
            title: 'Win More Bids',
            description: '',
            bulletPoints: [
                'AI-powered estimator gives you reliable quotes for construction projects.',
                'Save time and avoid costly mistakes.',
                'Plan faster and win more business.',
            ],
            isActive: false,
            redirectToStores: false,
            appId: 'com.simplywise.costestimator',
        },
        {
            name: 'Receipt Scanner',
            // url: '/lp-receipt-scanner',
            url: 'simplywise.app.link',
            appStoreLink:
                'https://apps.apple.com/us/app/simplywise-receipt-scanner/id1538521095',
            playStoreLink:
                'https://play.google.com/store/apps/details?id=com.simplywise.mobile',
            icon: <img src={ReceiptsAppIcon} alt="app icon" />,
            heroImage: <ReceiptsHero />,
            title: 'Ditch the Shoebox of Receipts',
            description: '',
            bulletPoints: [
                'Scan & organize receipts in seconds.',
                'Auto-import email receipts.',
                'Generate reports that make expense tracking and taxes a breeze.',
            ],
            isActive: true,
            redirectToStores: false,
            appId: 'com.simplywise.mobile',
        },
        {
            name: 'A.I. Receptionist',
            url: 'swvoiceassistant.app.link',
            appStoreLink:
                'https://apps.apple.com/us/app/simplywise-ai-receptionist/id6738157198',
            playStoreLink: '',
            icon: <img src={AIReceiptionistAppIcon} alt="app icon" />,
            heroImage: (
                <img
                    src={AIReceiptionistHero}
                    style={{
                        width: '35%',
                        alignSelf: 'center',
                        objectFit: 'contain',
                    }}
                    alt="ai receptionist hero"
                />
            ),
            title: 'Never Miss a Call',
            description: '',
            bulletPoints: [
                'Flags urgent messages, ensuring you never miss what matters most.',
                'Speaks every language, so you can communicate effortlessly with anyone.',
                'Handles scheduling and messages so naturally, your callers will think they’re speaking to a real person.',
            ],
            isActive: false,
            redirectToStores: false,
            appId: 'com.simplywise.voiceassistant',
        },
        {
            name: 'Mileage Tracker',
            url: 'swmileagetracker.app.link',
            appStoreLink:
                'https://apps.apple.com/us/app/simplywise-mileage-tracker/id6701986810',
            playStoreLink: '',
            icon: <img src={MileageTrackerAppIcon} alt="app icon" />,
            // heroImage: <img src={SubAppHeroImagePlacement} alt="hero" />,
            heroImage: <MileageHero />,
            title: 'Capture Every Mile',
            description: '',
            bulletPoints: [
                'Track mileage automatically, so you don’t have to pull over and log a drive.',
                'Get tax-compliant mileage reports with a tap.',
                'Save thousands in tax deductions every year.',
            ],
            isActive: false,
            redirectToStores: false,
            appId: 'com.simplywise.mileagetracker',
        },
    ],
    ratingAndReviews: {
        ratingVisual: <img src={StarRating} alt="star rating" />,
        title: 'Loved by 1M+ Small Business Owners',
        reviews: [
            {
                quote: 'SimplyWise makes running my business so much easier. The apps are simple and save me HOURS every month on receipts, mileage, and estimates. I can’t imagine going back to manual tracking.',
                user: 'Jason L., Elite Construction LLC',
            },
        ],
    },
    footerData: {
        slogan: 'You build. We’ll do the busywork.',
        callToAction: {
            label: 'Download',
            labelStyle: {
                fontSize: 16,
                margin: '0px 5px',
            },
            action: 'DownloadApps',
            secondaryAction: 'SendDownloadLink',
            style: {
                backgroundColor: theme.colors.secondary,
                color: theme.colors.white,
                borderRadius: 30,
                alignSelf: 'center',
                marginBottom: 30,
                zIndex: 100,
            },
            render: true,
            icon: false,
            type: 'ButtonElement',
            assignedPosition: 'final',
        },
        ratingVisual: <img src={StarRatingVariation} alt="star rating" />,
        footerLogo: <img src={HeaderLogo} alt="simplywise logo" />,
        footerWatermark: <img src={LeafForHeaderVariation} alt="watermark" />,
        links: [
            {
                label: 'About Us',
                url: '/about',
            },
            {
                label: 'Privacy Policy',
                url: '/privacy',
            },
            {
                label: 'Terms & Confitions',
                url: '/terms',
            },
        ],
    },
};

export const BASE_VARIATION_1_DL_CTA: VariationConfig = {
    header: 'Tame Receipt Chaos',
    description: 'Transform email and paper clutter into life-changing reports',
    heroCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    tabCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    // tabCallToAction: {
    //     label: 'Start for free',
    //     action: 'DownloadPage',
    // },
    finalCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviews: 10,
};

export const TEAMS_VARIATION_1: VariationConfig = {
    header: 'Stop chasing people for receipts',
    description:
        'No more manual tracking. Organize business expenses in seconds.',
    heroCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    tabCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    finalCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviewsVertical: {
        title: 'Join 400,000+ Small Businesses',
        reviews: [
            {
                quote: `Saved us so much time and headache... it’s so easy that my guys actually remember to ‘submit’ their receipts now.\nBest app I have found for small business expense management. I recommend it to everyone I know.`,
                user: `- David, David’s Flooring of FL`,
            },
        ],
    },
};

export const TEAMS_VARIATION_2: VariationConfig = {
    header: '#1 Receipt App for Construction',
    description:
        'Stop chasing people for receipts! No more manual tracking. Organize business expenses in seconds.',
    heroCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    tabCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    finalCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviewsVertical: {
        title: 'Join 400,000+ Small Businesses',
        reviews: [
            {
                quote: `Saved us so much time and headache... it’s so easy that my guys actually remember to ‘submit’ their receipts now.\nBest app I have found for small business expense management. I recommend it to everyone I know.`,
                user: `- David, David’s Flooring of FL`,
            },
        ],
    },
};

const LANDING_SUSPENSE_VIDEO = (
    // @ts-ignore
    <Video src={require('./assets/video/landingSuspense.mp4')} />
);

enum EXP_NAME {
    'welcome' = 'welcome',
    'home-page' = 'home-page',
    'download-apps' = 'download-apps',
    'may-16-30and90-demo-report' = 'may-16-30and90-demo-report',
    'may-5-30and90-baseline' = 'may-5-30and90-baseline',
    'may-5-30and90-manual-entry' = 'may-5-30and90-manual-entry',
    'may-01-send-signup-link' = 'may-01-send-signup-link',
    'may-5-declutter-value-prop' = 'may-5-declutter-value-prop',
    'may-5-salestax-value-prop' = 'may-5-salestax-value-prop',
    'jul-03-quickbooks-flow' = 'jul-03-quickbooks-flow',
    'jun-30-redirect-app-flow' = 'jun-30-redirect-app-flow',
    'Aug-18-redirect-with-cta' = 'Aug-18-redirect-with-cta',
    'july-19-sales-tax' = 'july-19-sales-tax',
    'july-28-redirect-branch' = 'july-28-redirect-branch',
    'sep-25-amazon-transaction' = 'sep-25-amazon-transaction',
    'sep-27-customized-referral-flow' = 'sep-27-customized-referral-flow',
    'oct-18-mileage' = 'oct-18-mileage',
    'oct-18-amazon-reconciliation' = 'oct-18-amazon-reconciliation',
    'jan-24-teams' = 'jan-24-teams',
    'mar-24-teams-construction' = 'mar-24-teams-construction',
    'mar-29-teams-typeform' = 'mar-29-teams-typeform',
    'collect-text-receipts' = 'collect-text-receipts',
    'cost-estimator' = 'cost-estimator',
    'welcome-bidcap-aug-14' = 'welcome-bidcap-aug-14',
    'welcome-bundle-aug-15' = 'welcome-bundle-aug-15',
    'photo-ai' = 'photo-ai',
    'photo-ai-multi' = 'photo-ai-multi',
    'photo-ai-progress' = 'photo-ai-progress',
    'voice-ai-receptionist2' = 'voice-ai-receptionist2',
    'bidiq' = 'bidiq',
    'voice-ai-receptionist-value1' = 'voice-ai-receptionist-value1',
    'voice-ai-receptionist-debt' = 'voice-ai-receptionist-debt',
    'voice-ai-assistant-invoice' = 'voice-ai-assistant-invoice',
}

export const EXP_NAME_TO_CONFIG: {
    [key in EXP_NAME]: {
        expName: string;
        baseVariation: VariationConfig;
        ABVariations?: {
            [key: string]: Partial<VariationConfig>;
        };
        branchAppStoreUrl?: string;
        branchPlayStoreUrl?: string;
    };
} = {
    // This is the old '/' route!!
    'welcome': {
        expName: 'welcome',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                teamsName: 'Teams',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                teamsPrice: '$89.99/year/employee',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
                teamsFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                    'View employee receipts in real-time reports',
                    'Sync to Quickbooks',
                ],
            },
            headerMode: 'login',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize your receipts using A.I. Generate reports instantly.',
            },
        },
    },
    // This is the new '/' route!!
    'home-page': {
        expName: 'home-page',
        baseVariation: {
            ...BASE_VARIATION_UPDATE,
            // sections: BASE_ANIMATED_SECTIONS,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            // pricing: {
            //     personalName: 'Personal',
            //     businessName: 'Business',
            //     teamsName: 'Teams',
            //     personalPrice: '7-days free, then $29.99/year',
            //     businessPrice: '7-days free, then $89.99/year',
            //     teamsPrice: '$89.99/year/employee',
            //     iconsOn: true,
            //     personalFeatures: [
            //         'Digitize paper receipts',
            //         'Import email receipts',
            //         'Unlimited storage and scans',
            //     ],
            //     businessFeatures: [
            //         'Everything in personal',
            //         'Track expenses by category',
            //         'Export itemized expenses',
            //         'Use across multiple devices',
            //     ],
            //     teamsFeatures: [
            //         'Digitize paper receipts',
            //         'Import email receipts',
            //         'Unlimited storage and scans',
            //         'View employee receipts in real-time reports',
            //         'Sync to Quickbooks',
            //     ],
            // },
            headerMode: 'signup',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Eliminate Admin Work',
                headerTitleStyle: {
                    fontSize: 40,
                    color: theme.colors.primary,
                },
                description:
                    'Our simple apps automate admin work so you can focus on profits.',
                descriptionStyle: {
                    fontSize: 20,
                    color: theme.colors.primary,
                    opacity: 0.7,
                },
            },
        },
    },
    // This is the new '/download-apps' route!!
    'download-apps': {
        expName: 'download-apps',
        baseVariation: {
            ...BASE_VARIATION_UPDATE,
            // sections: BASE_ANIMATED_SECTIONS,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            // pricing: {
            //     personalName: 'Personal',
            //     businessName: 'Business',
            //     teamsName: 'Teams',
            //     personalPrice: '7-days free, then $29.99/year',
            //     businessPrice: '7-days free, then $89.99/year',
            //     teamsPrice: '$89.99/year/employee',
            //     iconsOn: true,
            //     personalFeatures: [
            //         'Digitize paper receipts',
            //         'Import email receipts',
            //         'Unlimited storage and scans',
            //     ],
            //     businessFeatures: [
            //         'Everything in personal',
            //         'Track expenses by category',
            //         'Export itemized expenses',
            //         'Use across multiple devices',
            //     ],
            //     teamsFeatures: [
            //         'Digitize paper receipts',
            //         'Import email receipts',
            //         'Unlimited storage and scans',
            //         'View employee receipts in real-time reports',
            //         'Sync to Quickbooks',
            //     ],
            // },
            headerMode: 'signup',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Stop wasting time on admin work.',
                headerTitleStyle: {
                    fontSize: 40,
                    color: theme.colors.primary,
                },
                description:
                    'Our simple apps automate the tasks that slow you down, so you can spend more time making money and less time on paperwork.',
                descriptionStyle: {
                    fontSize: 20,
                    color: theme.colors.primary,
                    opacity: 0.7,
                },
            },
        },
    },
    'jan-24-teams': {
        expName: 'jan-24-teams',
        baseVariation: {
            ...TEAMS_VARIATION_1,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'may-5-30and90-baseline': {
        expName: 'may-5-30and90-baseline',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Tame Receipt Chaos',
            },
        },
    },
    'jul-03-quickbooks-flow': {
        expName: 'jul-03-quickbooks-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: QUICKBOOKS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
        },
        ABVariations: {
            VariantTameChaos: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Easily sync your receipts to Quickbooks. No more receipt chaos. Save time and money at tax time.',
            },
        },
    },
    'may-5-declutter-value-prop': {
        expName: 'Aug-9-short-quiz-only',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'may-16-30and90-demo-report': {
        expName: 'may-16-30and90-demo-report',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                onboardingIntroductions: true,
                onboardingIntroductionReport: true,
                directPaywall: true,
            },
        },
    },
    'may-5-30and90-manual-entry': {
        expName: 'may-5-30and90-manual-entry',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Tame Receipt Chaos',
            },
            VariantSkipEnabled: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
            },
        },
    },
    'jun-30-redirect-app-flow': {
        expName: 'jun-30-redirect-app-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
            },
        },
        ABVariations: {
            VariantTameChaos: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize receipts with A.I. Generate reports instantly and simplify tax time',
            },
        },
    },
    'may-5-salestax-value-prop': {
        expName: 'may-29-salestax-entry',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: SALESTAX_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Instant Sales Tax Calculator',
                description:
                    'Total up sales tax from paper and email receipts instantly.',
            },
            VariantSkipEnabled: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
            },
        },
    },
    'may-01-send-signup-link': {
        expName: 'may-01-send-signup-link',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
            },
        },
    },
    'Aug-18-redirect-with-cta': {
        expName: 'Aug-18-redirect-with-cta',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
            heroCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            tabCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            finalCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            reviews: 10,
        },
        ABVariations: {},
    },
    'july-19-sales-tax': {
        expName: 'july-19-sales-tax',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                onboardingPresignupInitialScreen: PreSignupSurveyScreen.Usage,
                onboardingUsageSurveryDisplayQuestion:
                    'What are you itemizing deductions for?',
                onboardingPresignupExtendedSurvey: true,
                onboardingShowRecommendedPlan: false,
            },
        },
    },
    'july-28-redirect-branch': {
        expName: 'july-28-redirect-branch',
        baseVariation: {
            ...BASE_VARIATION_1,
            redirectBranch: true,
        },
    },
    'sep-25-amazon-transaction': {
        expName: 'sep-28-amazon-transaction',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-baseline/',
            },
            VariantTitleFraud: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-titlefraud/',
            },
            VariantImageFraud: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-fraudimage/',
            },
        },
    },
    'sep-27-customized-referral-flow': {
        expName: 'sep-27-customized-referral-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: (searchParams) =>
                searchParams.get('ref_name') &&
                searchParams.get('ref_name')?.length
                    ? `${searchParams.get(
                          'ref_name'
                      )} Invites you to use SimplyWise!`
                    : 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'oct-18-mileage': {
        expName: 'oct-18-mileage',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: <img src={MileageTracking} alt="" />,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Track Mileage and Expenses',
            description: 'Everything for taxes, all in one place',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
        branchAppStoreUrl:
            'https://apps.apple.com/us/app/simplywise-receipt-scanner/id1538521095?ppid=1f4cac98-5024-40e6-96c1-2c140a9aa6dd',
        branchPlayStoreUrl:
            'https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=mileage-tracking-1',
    },
    'oct-18-amazon-reconciliation': {
        expName: 'oct-18-amazon-reconciliation',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: <img src={AmazonReconcile} alt="" />,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Easy Expense Reconciling',
            description:
                'No more mystery charges. Organize your expenses in seconds.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'mar-29-teams-typeform': {
        expName: 'mar-29-teams-typeform',
        baseVariation: {
            ...TEAMS_VARIATION_1,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            heroCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            finalCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            pricingCallToAction: {
                action: 'TeamsTypeform',
            },
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'mar-24-teams-construction': {
        expName: 'mar-24-teams-construction',
        baseVariation: {
            ...TEAMS_VARIATION_2,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            heroCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            finalCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            pricingCallToAction: {
                action: 'TeamsTypeform',
            },
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'collect-text-receipts': {
        expName: 'collect-text-receipts',
        baseVariation: {
            header: 'Easy Team \n Receipt Collection',
            primaryVisualComp: (
                <img
                    alt="Team"
                    src={TeamTexting}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            ),
            description:
                'Save hours every week. The #1 AI-powered \n Receipt Admin for teams',
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsCollectionSignup',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'TeamsCollectionSignup',
            },
            pricingCallToAction: {
                action: 'TeamsCollectionSignup',
            },
            sections: TEAMS_ANIMATED_SECTIONS,
            sectionsTitle: 'Stop Chasing Receipts',
            reviewsVertical: {
                title: 'Zero learning curve. Employees love it',
                reviews: [
                    {
                        quote: `First time ever that the guys submitted 100% of our monthly receipts 😮`,
                        user: `- Mikec67`,
                    },
                ],
            },
            singlePricing: {
                price: '$7.50 / User / Month',
                bulletPoints: [
                    'Scan & digitize unlimited receipts',
                    'Instantly turn receipts into spreadsheets',
                    'Instantly reconcile scanned receipts with bank & credit card statements',
                ],
            },
            finalCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'TeamsCollectionSignup',
            },
            finalCallToActionHeader: {
                text: 'Get Started',
                type: 'TextElement',
                render: true,
            },
        },
        ABVariations: {
            VariantBaseline: {},
            VariantEnterEmail: {},
        },
    },
    'cost-estimator': {
        expName: 'cost-estimator',
        baseVariation: {
            header: 'Instant construction \nestimates',
            description:
                'Take a photo. Describe the project. Instantly get an estimate. 100% free',
            primaryVisualComp: (
                // @ts-ignore
                <Video
                    src={require('./assets/video/CostEstimation.mp4')}
                    width={280}
                    style={{
                        width: 280,
                        borderRadius: 20,
                        marginLeft: 20,
                    }}
                />
            ),
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'CostEstimation',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'CostEstimation',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'CostEstimation',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'CostEstimation',
                },
            },
            // VariantDownloadDirect: {
            //     tabCallToAction: {
            //         label: 'Get Started',
            //         action: 'CostEstimationDirectToApp',
            //     },
            //     heroCallToAction: {
            //         label: 'Get Started',
            //         sublabel: "It's free",
            //         action: 'CostEstimationDirectToApp',
            //     },
            // },
        },
    },
    'bidiq': {
        expName: 'bidiq',
        baseVariation: {
            header: 'Bid Smarter',
            description: `Compare your bid to 100,000's of others. Know if you are bidding too high or low. 100% Free`,
            // primaryVisualComp: (
            //     // @ts-ignore
            //     <Video
            //         src={require('./assets/video/CostEstimation.mp4')}
            //         width={280}
            //         style={{
            //             width: 280,
            //             borderRadius: 20,
            //             marginLeft: 20,
            //         }}
            //     />
            // ),
            // // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'BidIQ',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'BidIQ',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'BidIQ',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'BidIQ',
                },
            },
        },
    },
    'photo-ai': {
        expName: 'photo-ai',
        baseVariation: {
            header: 'Organize Job Photos Instantly',
            description: 'Job documentation & communication, made easy.',
            primaryVisualComp: (
                // @ts-ignore
                <Video
                    src={require('./assets/video/PhotoAI2.mp4')}
                    style={{
                        width: 280,
                        borderRadius: 20,
                        marginLeft: 20,
                    }}
                />
            ),
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'PhotoAI',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'PhotoAI',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAI',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAI',
                },
            },
            VariantDownloadDirect: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAI',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAI',
                },
            },
        },
    },
    'photo-ai-multi': {
        expName: 'photo-ai-multi',
        baseVariation: {
            header: 'Organize Job Photos Instantly',
            description: 'Job documentation & communication, made easy.',
            primaryVisualComp: (
                // @ts-ignore
                <Video
                    src={require('./assets/video/PhotoAI2.mp4')}
                    style={{
                        width: 280,
                        borderRadius: 20,
                        marginLeft: 20,
                    }}
                />
            ),
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'PhotoAIMulti',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'PhotoAIMulti',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAIMulti',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAIMulti',
                },
            },
            VariantDownloadDirect: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAIMulti',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAIMulti',
                },
            },
        },
    },
    'photo-ai-progress': {
        expName: 'photo-ai-progress',
        baseVariation: {
            header: 'Document Jobs in Seconds with A.I.',
            description:
                'No more scrolling up and down through the phone gallery. No more texting photos. Fast access the crew photos you need, when you need them.',
            primaryVisualComp: (
                // @ts-ignore
                <Video
                    src={require('./assets/video/PhotoAI3.mp4')}
                    style={{
                        width: 280,
                        borderRadius: 20,
                        marginLeft: 20,
                    }}
                />
            ),
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'PhotoAIProgressReport',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'PhotoAIProgressReport',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAIProgressReport',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAIProgressReport',
                },
            },
            VariantDownloadDirect: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'PhotoAIProgressReport',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'PhotoAIProgressReport',
                },
            },
        },
    },
    'voice-ai-receptionist2': {
        expName: 'voice-ai-receptionist2',
        baseVariation: {
            header: 'Get an A.I. Receptionist',
            description:
                'Never miss a work call again. Your A.I. receptionist answers every call, schedules meetings, and transcribes messages.',
            primaryVisualComp: <div />,
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'AIReceptionistSignUp',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'AIReceptionistSignUp',
            },
        },
        ABVariations: {
            // VariantBaseline: {
            //     tabCallToAction: {
            //         label: 'Get Started',
            //         action: 'VoiceAI',
            //     },
            //     heroCallToAction: {
            //         label: 'Get Started',
            //         sublabel: "It's free",
            //         action: 'VoiceAI',
            //     },
            // },
            VariantCall: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'AIReceptionistSignUp',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'AIReceptionistSignUp',
                },
            },
        },
    },
    'voice-ai-receptionist-value1': {
        expName: 'voice-ai-receptionist-value1',
        baseVariation: {
            header: 'Get an A.I. Receptionist',
            description:
                'Never miss a call again. Your AI Receptionist mans your phone 24/7.',
            primaryVisualComp: <div />,
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'AIReceptionistFakeFlow',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'AIReceptionistFakeFlow',
            },
        },
        ABVariations: {
            // VariantBaseline: {
            //     tabCallToAction: {
            //         label: 'Get Started',
            //         action: 'VoiceAI',
            //     },
            //     heroCallToAction: {
            //         label: 'Get Started',
            //         sublabel: "It's free",
            //         action: 'VoiceAI',
            //     },
            // },
            VariantCall: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'AIReceptionistFakeFlow',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'AIReceptionistFakeFlow',
                },
            },
        },
    },
    'voice-ai-receptionist-debt': {
        expName: 'voice-ai-receptionist-debt',
        baseVariation: {
            header: 'Stop Chasing Clients \n for Payments',
            description:
                'Get the AI receptionist that follows up on overdue invoices—automatically. It calls, texts, and emails until you get paid.',
            primaryVisualComp: <div />,
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'AIReceptionistFakeFlow',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'AIReceptionistFakeFlow',
            },
        },
        ABVariations: {
            // VariantBaseline: {
            //     tabCallToAction: {
            //         label: 'Get Started',
            //         action: 'VoiceAI',
            //     },
            //     heroCallToAction: {
            //         label: 'Get Started',
            //         sublabel: "It's free",
            //         action: 'VoiceAI',
            //     },
            // },
            VariantCall: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'AIReceptionistFakeFlow',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'AIReceptionistFakeFlow',
                },
            },
        },
    },
    'voice-ai-assistant-invoice': {
        expName: 'voice-ai-assistant-invoice',
        baseVariation: {
            header: 'Meet your \n AI Invoice Assistant.',
            description:
                'No typing, no templates. Create, send, & search \n invoices—with your voice.',
            primaryVisualComp: <div />,
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'AIReceptionistFakeFlow',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'AIReceptionistFakeFlow',
            },
        },
        ABVariations: {
            // VariantBaseline: {
            //     tabCallToAction: {
            //         label: 'Get Started',
            //         action: 'VoiceAI',
            //     },
            //     heroCallToAction: {
            //         label: 'Get Started',
            //         sublabel: "It's free",
            //         action: 'VoiceAI',
            //     },
            // },
            VariantCall: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'AIReceptionistFakeFlow',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'AIReceptionistFakeFlow',
                },
            },
        },
    },
    'welcome-bidcap-aug-14': {
        expName: 'welcome-bidcap-aug-14',
        baseVariation: {
            ...BASE_VARIATION_1_DL_CTA,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                teamsName: 'Teams',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                teamsPrice: '$89.99/year/employee',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
                teamsFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                    'View employee receipts in real-time reports',
                    'Sync to Quickbooks',
                ],
            },
            headerMode: 'signup',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize your receipts using A.I. Generate reports instantly.',
            },
        },
    },
    'welcome-bundle-aug-15': {
        expName: 'welcome-bundle-aug-15',
        baseVariation: {
            ...BASE_VARIATION_1_DL_CTA,
            header: 'Make every job profitable.',
            description:
                'Get accurate bids & track project costs in seconds. All in one place.',
            primaryVisualComp: <ConstructionLandingJumbotron />,
            sections: CONSTRUCTION_ANIMATED_SECTIONS,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
        },
        ABVariations: {},
    },
};

export const landingPageVariationsConfig: LandingPageConfig[] = [
    {
        // This is the old '/' route!!
        path: 'welcome',
        landingPageName: 'Welcome',
        ...EXP_NAME_TO_CONFIG['welcome'],
    },
    {
        // This is the new '/' route!!
        path: 'home-page',
        landingPageName: 'Home Page',
        ...EXP_NAME_TO_CONFIG['home-page'],
    },
    {
        path: 'download-apps',
        landingPageName: 'Download Apps',
        ...EXP_NAME_TO_CONFIG['download-apps'],
    },
    {
        path: 'google/emailonb',
        landingPageName: 'Google Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/pm',
        landingPageName: 'Google Pm Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/brex',
        landingPageName: 'Google Brex Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/gen_ex',
        landingPageName: 'Google GenEx Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/gp',
        landingPageName: 'Google Gp Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'facebook/emailonb',
        landingPageName: 'Facebook Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'bing/emailonb',
        landingPageName: 'Bing Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'facebook/demoreport',
        landingPageName: 'Facebook Report First Demo Report',
        ...EXP_NAME_TO_CONFIG['may-16-30and90-demo-report'],
    },
    {
        path: 'google/demoreport',
        landingPageName: 'Google Report First Demo Report',
        ...EXP_NAME_TO_CONFIG['may-16-30and90-demo-report'],
    },
    {
        path: 'google/salestax',
        landingPageName: 'Google Sales Tax Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-salestax-value-prop'],
    },
    // {
    //     path: 'google/quickbooks',
    //     landingPageName: 'Google Quickbooks Flow',
    //     ...EXP_NAME_TO_CONFIG['jul-03-quickbooks-flow'],
    // },
    {
        path: 'facebook/rocketship',
        landingPageName: 'Facebook declutter Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/declutter',
        landingPageName: 'Facebook declutter Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/iosonly',
        landingPageName: 'Facebook Declutter Value Prop iOS only',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/inbeat',
        landingPageName: 'Facebook Inbeat iOS only',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/retarget',
        landingPageName: 'Facebook Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'all/sales-tax',
        landingPageName: 'Sales Tax',
        ...EXP_NAME_TO_CONFIG['july-19-sales-tax'],
    },
    {
        path: 'ref',
        landingPageName: 'Referral Flow',
        ...EXP_NAME_TO_CONFIG['sep-27-customized-referral-flow'],
    },
    {
        path: 'facebook/iosonlybizopt',
        landingPageName: 'Facebook iOS only Biz Optimized',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/fernando',
        landingPageName: 'Influencer Redirect Branch Example',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/fernando-direct-to-app',
        landingPageName: 'Influencer Redirect Branch Example',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/retarget-registered-non-payers',
        landingPageName: 'Facebook Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/retarget-how-to-videos',
        landingPageName: 'Facebook Retarget what if videos',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'google/pmax-retarget',
        landingPageName: 'Google Pmax Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'google/pmax',
        landingPageName: 'Google Pmax Landing Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'google/youtube',
        landingPageName: 'Google Pmax Landing Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'blog/return-policy',
        landingPageName: 'SimplyWise Blog Redirect Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'google/amazon-transaction',
        landingPageName: 'SimplyWise Amazon Transaction',
        ...EXP_NAME_TO_CONFIG['sep-25-amazon-transaction'],
    },
    {
        path: 'mileage',
        landingPageName: 'SimplyWise Mileage',
        ...EXP_NAME_TO_CONFIG['oct-18-mileage'],
    },
    {
        path: 'amazon-reconciliation',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'google/teams',
        landingPageName: 'SimplyWise Teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'facebook/teams',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'linkedin/teams',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'facebook/teams-tf',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-29-teams-typeform'],
    },
    {
        path: 'email/teams-tf',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-29-teams-typeform'],
    },
    {
        path: 'email/teams/construction',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-24-teams-construction'],
    },
    {
        path: 'google/whats-that-charge-retarget',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'facebook/whats-that-charge-retarget',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'linkedin',
        landingPageName: 'Welcome',
        ...EXP_NAME_TO_CONFIG['welcome'],
    },
    {
        path: 'facebook/teams-texting',
        landingPageName: 'Text Receipt Collection Aug 1',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'google/teams-texting',
        landingPageName: 'Text Receipt Collection Aug 1',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'userbrain/teams-texting',
        landingPageName: 'Text Receipt Collection Userbrain',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'facebook/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'google/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'userbrain/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'reddit/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'email/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'facebook/bidiq',
        landingPageName: 'BidIQ',
        ...EXP_NAME_TO_CONFIG['bidiq'],
    },
    {
        path: 'facebook/bidcap',
        landingPageName: 'Welcome Bidcap',
        ...EXP_NAME_TO_CONFIG['welcome-bidcap-aug-14'],
    },
    {
        path: 'facebook/bundle',
        landingPageName: 'Welcome Bundle',
        ...EXP_NAME_TO_CONFIG['welcome-bundle-aug-15'],
    },
    {
        path: 'facebook/photo-ai',
        landingPageName: 'Photo AI',
        ...EXP_NAME_TO_CONFIG['photo-ai'],
    },
    {
        path: 'google/photo-ai',
        landingPageName: 'Photo AI',
        ...EXP_NAME_TO_CONFIG['photo-ai'],
    },
    {
        path: 'facebook/photo-ai-multi',
        landingPageName: 'Photo AI',
        ...EXP_NAME_TO_CONFIG['photo-ai-multi'],
    },
    {
        path: 'google/photo-ai-multi',
        landingPageName: 'Photo AI',
        ...EXP_NAME_TO_CONFIG['photo-ai-multi'],
    },
    {
        path: 'facebook/photo-ai-progress',
        landingPageName: 'Photo AI 2',
        ...EXP_NAME_TO_CONFIG['photo-ai-progress'],
    },
    {
        path: 'google/photo-ai-progress',
        landingPageName: 'Photo AI 2',
        ...EXP_NAME_TO_CONFIG['photo-ai-progress'],
    },
    {
        path: 'facebook/voice-ai-receptionist',
        landingPageName: 'Voice AI Receptionist (Payment)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist2'],
    },
    {
        path: 'google/voice-ai-receptionist',
        landingPageName: 'Voice AI Receptionist (Payment)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist2'],
    },
    {
        path: 'userbrain/voice-ai-receptionist',
        landingPageName: 'Voice AI Receptionist (Payment Userbrain)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist2'],
    },
    {
        path: 'cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'receipt-scanner',
        landingPageName: 'Receipts App',
        ...EXP_NAME_TO_CONFIG['welcome'],
    },
    {
        path: 'facebook/voice-ai-receptionist-flow',
        landingPageName: 'Voice AI Receptionist (Value Props)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist-value1'],
    },
    {
        path: 'google/voice-ai-receptionist-flow',
        landingPageName: 'Voice AI Receptionist (Value Props)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist-value1'],
    },
    {
        path: 'facebook/voice-ai-receptionist-debt',
        landingPageName: 'Voice AI Receptionist (Debt Collection)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist-debt'],
    },
    {
        path: 'google/voice-ai-receptionist-debt',
        landingPageName: 'Voice AI Receptionist (Debt Collection)',
        ...EXP_NAME_TO_CONFIG['voice-ai-receptionist-debt'],
    },
    {
        path: 'facebook/voice-ai-assistant-invoice',
        landingPageName: 'Voice AI Assistant (Invoice)',
        ...EXP_NAME_TO_CONFIG['voice-ai-assistant-invoice'],
    },
    {
        path: 'google/voice-ai-assistant-invoice',
        landingPageName: 'Voice AI Assistant (Invoice)',
        ...EXP_NAME_TO_CONFIG['voice-ai-assistant-invoice'],
    },
];
