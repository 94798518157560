import {
    PageContainer,
    SectionContentWrapper,
} from '../../../../screens/Landing/styles';
import theme from '../../../../theme';
import { Body, BodyBold } from '../../../Text';

const RatingAndReviews = ({
    ratingAndReviews,
    isMobile,
}: {
    ratingAndReviews: any;
    isMobile: boolean;
}) => {
    const renderReview = (review: any) => (
        <div
            style={{
                width: '100%',
                padding: isMobile ? '30px' : '40px',
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
            }}
        >
            <Body
                style={{
                    color: theme.colors.primary,
                    fontStyle: 'italic',
                    textAlign: 'center',
                    fontSize: isMobile ? 16 : 25,
                }}
            >
                "{review.quote}"
            </Body>
            <BodyBold
                style={{
                    color: theme.colors.secondary,
                    fontStyle: 'italic',
                    textAlign: 'center',
                    fontSize: isMobile ? 16 : 25,
                    marginTop: 20,
                }}
            >
                — {review.user}
            </BodyBold>
        </div>
    );

    return (
        <PageContainer
            style={{
                backgroundColor: theme.colors.offWhite,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '40px 10px',
            }}
        >
            <SectionContentWrapper
                style={{
                    backgroundColor: theme.colors.offWhite,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {ratingAndReviews.ratingVisual && (
                    <img
                        alt={'rating visual'}
                        src={ratingAndReviews.ratingVisual.props.src}
                        style={{ width: isMobile ? '50%' : 200 }}
                    />
                )}
                {ratingAndReviews?.title && (
                    <BodyBold
                        style={{
                            fontSize: isMobile ? 30 : 40,
                            textAlign: 'center',
                            width: isMobile ? '90%' : 400,
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                    >
                        {ratingAndReviews.title}
                    </BodyBold>
                )}
                {ratingAndReviews.reviews?.map((review: any, index: number) => (
                    <div key={`review-${index}`}>{renderReview(review)}</div>
                ))}
            </SectionContentWrapper>
        </PageContainer>
    );
};

export default RatingAndReviews;
