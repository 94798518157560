import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { ILoadAllProcessingResponse, ReceiptPlatform } from '../receipts/types';
import { onWebAppPoolingRequest } from '../../tracking/trackers';
import {
    IEmailProviderResponse,
    IGmailImportResultResponse,
    ITaxSavingsReportResponse,
} from './types';

export default class EReceiptsService {
    public static getEmailProvider(
        email: string
    ): Promise<IEmailProviderResponse> {
        return axios
            .post('/receipts/ereceipts/get-email-provider', { email })
            .then((value: AxiosResponse<IEmailProviderResponse>) => value.data);
    }

    public static requestHistoricalEmails(
        email: string
    ): Promise<IEmailProviderResponse> {
        return axios
            .post('/receipts/ereceipts/get-historical-receipts', { email })
            .then((value: AxiosResponse<IEmailProviderResponse>) => value.data);
    }

    public static gmailImportResult(
        email: string
    ): Promise<IGmailImportResultResponse> {
        onWebAppPoolingRequest({ url: 'gmailImportResult-ereceipts' });
        return axios
            .post('/receipts/ereceipts/gmail-import-result', { email })
            .then(
                (value: AxiosResponse<IGmailImportResultResponse>) => value.data
            );
    }

    public static createTaxSavingsReport(): Promise<ITaxSavingsReportResponse> {
        return axios
            .post('/receipts/ereceipts/tax-savings-report')
            .then(
                (value: AxiosResponse<ITaxSavingsReportResponse>) => value.data
            );
    }

    public static getTaxSavingsReport(
        id: string
    ): Promise<ITaxSavingsReportResponse> {
        return axios
            .get(`/receipts/ereceipts/tax-savings-report/${id}`)
            .then(
                (value: AxiosResponse<ITaxSavingsReportResponse>) => value.data
            );
    }

    public static getProcessingReceipts(
        token: string,
        createdAt?: string | undefined,
        platform?: ReceiptPlatform | undefined,
        platforms?: ReceiptPlatform[]
    ): Promise<ILoadAllProcessingResponse> {
        onWebAppPoolingRequest({ url: 'loadProcessingReceipts-ereceipts' });
        const created_at = createdAt || '1970-01-01 00:00:00';
        return axios
            .post(
                '/receipts/processing/load-many',
                { created_at, platform, platforms },
                {
                    headers: { Authorization: `Token ${token}` },
                }
            )
            .then((value: AxiosResponse<ILoadAllProcessingResponse>) => {
                return value.data;
            });
    }

    public static importHistorical(
        token: string,
        email: string,
        after: Date,
        max_receipts?: number,
        import_method?: string
    ): Promise<IGmailImportResultResponse> {
        return axios
            .post(
                'receipts/ereceipts/gmail-manual-import',
                {
                    email,
                    after: after.toLocaleDateString(),
                    max_receipts,
                    import_method,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then(
                (value: AxiosResponse<IGmailImportResultResponse>) => value.data
            );
    }
}
