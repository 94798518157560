import Resizer from 'react-image-file-resizer';

function cleanJSONString(jsonString: string) {
    // eslint-disable-next-line no-control-regex
    return jsonString.replace(/[\u0000-\u001F]/g, '');
}
const resizeImage = async (blob: Blob): Promise<string | undefined> => {
    try {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                blob,
                2048, // Using Claude's recommended size
                2048,
                'JPEG',
                75, // Using Claude's recommended quality
                0,
                (
                    resizedBlob:
                        | string
                        | Blob
                        | File
                        | ProgressEvent<FileReader>
                ) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result as string;
                        resolve(base64String.split(',')[1]);
                    };
                    reader.readAsDataURL(resizedBlob as Blob);
                },
                'blob'
            );
        });
    } catch (error) {
        console.error('Error resizing image:', error);
        return undefined;
    }
};

export const callClaude = async ({
    prompt,
    instructions,
    imageUris,
    quick = false,
    messages = [],
    isPartial = false,
}: {
    prompt?: string;
    instructions?: string;
    imageUris?: string[];
    quick?: boolean;
    messages?: any;
    isPartial?: boolean;
}) => {
    let base64Images = [];
    if (imageUris && imageUris.length > 0) {
        for (const imageUri of imageUris) {
            const response = await fetch(imageUri);
            const blob = await response.blob();
            base64Images.push(await resizeImage(blob));
        }
    }
    const messagesToSend = [
        ...messages,
        {
            role: isPartial ? 'assistant' : 'user',
            content: [
                ...(base64Images.length > 0
                    ? base64Images.map((base64Image, index) => ({
                          type: 'image',
                          source: {
                              type: 'base64',
                              media_type: imageUris?.[index]?.endsWith('.png')
                                  ? 'image/png'
                                  : 'image/jpeg',
                              data: base64Image,
                          },
                      }))
                    : []),
                {
                    type: 'text',
                    text: prompt,
                },
            ],
        },
    ];

    console.log(
        'messagesToSend',
        instructions,
        JSON.stringify(
            messagesToSend.map((m) => ({
                text: m.content.filter((c: any) => c.type === 'text'),
                images: m.content
                    .filter((c: any) => c.type === 'image')
                    .map((img: any) => ({
                        type: img.source.type,
                        media_type: img.source.media_type,
                        data_preview: img.source.data?.substring(0, 50) + '...',
                    })),
            }))
        )
    );
    let response = '';
    let previousTimeout = null;
    for (let i = 0; i < 3; i++) {
        try {
            if (previousTimeout) clearTimeout(previousTimeout);
            const controller = new AbortController();
            previousTimeout = setTimeout(
                () => controller.abort(),
                quick ? 24000 : 30000
            );
            const res = await fetch(
                'https://api.simplywise.com/api/v2/public/call-claude',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        body: {
                            model: !quick
                                ? 'claude-3-5-sonnet-20241022'
                                : 'claude-3-5-haiku-20241022',
                            temperature: 0.2,
                            max_tokens: 5000,
                            system: instructions,
                            messages: messagesToSend,
                        },
                    }),
                    signal: controller.signal,
                }
            );
            response = cleanJSONString(
                (await res.json()).data.output.content[0].text
            );
            break;
        } catch (e) {
            console.log('error', e);
            if (i === 2) {
                throw e;
            }
        }
    }

    return {
        response,
        messages: [
            ...messagesToSend,
            {
                role: 'assistant',
                content: [
                    {
                        type: 'text',
                        text: response,
                    },
                ],
            },
        ],
    };
};
