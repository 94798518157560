import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@mui/material';
import theme from '../../../theme';
import { onWebAppCostEstimationSaveToFolder } from '../../../tracking/trackers';
import { useFolders } from '../../../screens/ReceiptsHome/folderContext';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import FolderIcon from '../../../components/FolderIcon';
import { useCostEstimator } from './CostEstimatorProvider';
const StepSaveFolder = () => {
    const { saveEstimate, estimateName } = useCostEstimator();
    const navigate = useNavigate();
    const { folders } = useFolders();
    const { setSnackbar } = useSnackbar();
    const [isSaving, setIsSaving] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState<string>('');

    const handleSave = async () => {
        try {
            setIsSaving(true);
            setSnackbar({
                message: 'Saving estimate...',
                status: 'info',
            });

            await saveEstimate(estimateName, [selectedFolderId], {});
            onWebAppCostEstimationSaveToFolder({
                folder_id: selectedFolderId,
            });

            setSnackbar({
                message: 'Estimate saved successfully!',
                status: 'success',
            });

            setTimeout(() => {
                navigate('/app/cost-estimator');
            }, 1000);
        } catch (error) {
            console.error('Error saving estimate:', error);
            setSnackbar({
                message: 'Error saving estimate',
                status: 'error',
            });
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div
            style={{
                padding: '2.5rem',
                maxWidth: '37.5rem',
                margin: '0 auto',
                width: '100%',
            }}
        >
            <h2
                style={{
                    textAlign: 'center',
                    fontSize: '1.75rem',
                    marginBottom: '2.5rem',
                    color: theme.colors.blue100,
                    fontWeight: '600',
                }}
            >
                Select a Folder to Save Your Estimate
            </h2>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    padding: '0.5rem',
                    marginBottom: '0.5rem',
                }}
            >
                {folders.map((folder) => (
                    <div
                        key={folder.tag_id}
                        onClick={() => setSelectedFolderId(folder.tag_id)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0.75rem 1.25rem',
                            cursor: 'pointer',
                            backgroundColor:
                                selectedFolderId === folder.tag_id
                                    ? theme.colors.blue10
                                    : 'white',
                            borderRadius: '0.5rem',
                            border: `0.0625rem solid ${
                                selectedFolderId === folder.tag_id
                                    ? theme.colors.blue80
                                    : '#eee'
                            }`,
                            boxShadow:
                                selectedFolderId === folder.tag_id
                                    ? '0 0.125rem 0.25rem rgba(0,0,0,0.05)'
                                    : 'none',
                        }}
                    >
                        <FolderIcon
                            iconId={folder.icon_id ?? null}
                            folderName={folder.name.toLowerCase()}
                            size={20}
                        />
                        <span
                            style={{
                                fontSize: '0.9375rem',
                                marginLeft: '0.75rem',
                                color: theme.colors.blue100,
                                fontWeight:
                                    selectedFolderId === folder.tag_id
                                        ? '500'
                                        : '400',
                            }}
                        >
                            {folder.name}
                        </span>
                    </div>
                ))}
            </div>

            <Button
                variant="contained"
                onClick={handleSave}
                disabled={isSaving || !selectedFolderId}
                style={{
                    marginTop: '2.5rem',
                    width: '100%',
                    height: '3.125rem',
                    borderRadius: '1.5625rem',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    backgroundColor: theme.colors.secondary,
                    textTransform: 'none',
                }}
            >
                {isSaving ? 'Saving...' : 'Save'}
            </Button>
        </div>
    );
};

export default StepSaveFolder;
