import styled from 'styled-components';
import theme from '../../theme';
import { HEADER_HEIGHT } from '../../util/constants';
import Devices from '../../util/devices';

export const WebAppHeaderContainer = styled.div<{ alignLeft?: boolean }>`
    z-index: 100;
    background-color: ${theme.colors.blue100};
    height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    padding-left: 20px;
    padding-right: 20px;

    @media ${Devices.LAPTOP_LG} {
        justify-content: ${(props) =>
            props.alignLeft ? 'flex-start' : 'center'};
    }
`;

export const HeaderButtonContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    text-decoration: none;
    border-radius: ${theme.roundness}px;
    cursor: pointer;
    text-decoration: none;
    height: 100%;
    padding-left: 20px;

    :hover {
        color: ${theme.colors.secondary};
    }
`;

export const VisibleWhenNotPad = styled.div`
    display: none;
    @media ${Devices.TABLET} {
        display: flex;
    }
`;

export const VisibleWhenNotPhone = styled.div`
    display: none;
    @media ${Devices.TABLET_SM} {
        display: flex;
    }
`;

export const VisibleWhenPhone = styled.div`
    display: flex;
    @media ${Devices.TABLET_SM} {
        display: none;
    }
`;

export const HeaderSearchResultsContainer = styled.div<{
    width: number;
    maxheight: number;
}>`
    position: absolute;
    left: 0;
    width: ${(props) => props.width}px;
    zindex: 1000;
    overflow-y: scroll;
    max-height: ${(props) => props.maxheight}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: 8px;
    box-shadow: ${theme.defaultShadow};
`;
