import Auto from '../assets/icons/folders/auto.svg';
import AutoGreen from '../assets/icons/folders/autoGreen.svg';
import AutoPurple from '../assets/icons/folders/autoPurple.svg';
import AutoPink from '../assets/icons/folders/autoPink.svg';
import AutoYellow from '../assets/icons/folders/autoYellow.svg';
import Clothes from '../assets/icons/folders/clothes.svg';
import Donation from '../assets/icons/folders/donation.svg';
import Gifts from '../assets/icons/folders/gifts.svg';
import Groceries from '../assets/icons/folders/groceries.svg';
import Home from '../assets/icons/folders/home.svg';
import HomeOrange from '../assets/icons/folders/homeOrange.svg';
import HomeGreen from '../assets/icons/folders/homeGreen.svg';
import HomePurple from '../assets/icons/folders/homePurple.svg';
import HomePink from '../assets/icons/folders/homePink.svg';
import HomeCyan from '../assets/icons/folders/homeCyan.svg';
import Medical from '../assets/icons/folders/medical.svg';
import Restaurant from '../assets/icons/folders/restaurant.svg';
import Return from '../assets/icons/folders/return.svg';
import Tax from '../assets/icons/folders/tax.svg';
import ID from '../assets/icons/folders/vault.svg';
import AllDocuments from '../assets/icons/folders/alldocs.svg';
import Uncategorized from '../assets/icons/folders/uncategorized.svg';
import eReceipts from '../assets/icons/emailBadge.svg';
import AddBtn from '../assets/icons/folders/addbtn.svg';
import Edit from '../assets/icons/folders/edit.svg';
import Christmas from '../assets/icons/folders/christmas.svg';
import Vault from '../assets/icons/folders/vault.svg';
import Pets from '../assets/icons/folders/pets.svg';
import Travel from '../assets/icons/folders/travel.svg';
import Work from '../assets/icons/folders/work.svg';
import Doctor from '../assets/icons/folders/doctor.svg';
import Insurance from '../assets/icons/folders/insurance.svg';
import Benefits from '../assets/icons/folders/benefits.svg';
import Walmart from '../assets/icons/folders/walmart.svg';
import HomeDepot from '../assets/icons/folders/homeDepot.svg';
import Tools from '../assets/icons/folders/tools.svg';
import ToolsYellow from '../assets/icons/folders/toolsYellow.svg';
import ToolsGreen from '../assets/icons/folders/toolsGreen.svg';
import ToolsBlue from '../assets/icons/folders/toolsBlue.svg';
import Target from '../assets/icons/folders/target.svg';
import Lowes from '../assets/icons/folders/lowes.svg';
import Utilities from '../assets/icons/folders/utilities.svg';
import Farm from '../assets/icons/folders/farm.svg';
import Supplies from '../assets/icons/folders/supplies.svg';
import Entertainment from '../assets/icons/folders/entertainment.svg';
import CreditCard from '../assets/icons/folders/creditCard.svg';
import Recipes from '../assets/icons/folders/recipes.svg';
import Bank from '../assets/icons/folders/bank.svg';
import Crafts from '../assets/icons/folders/crafts.svg';
import Church from '../assets/icons/folders/church.svg';
import Appliances from '../assets/icons/folders/appliances.svg';
import Subscriptions from '../assets/icons/folders/subscriptions.svg';
import Computer from '../assets/icons/folders/computer.svg';
import Phone from '../assets/icons/folders/phone.svg';
import SelfCare from '../assets/icons/folders/selfCare.svg';

import Aldi from '../assets/icons/folders/aldi.svg';
import Amazon from '../assets/icons/folders/amazon.svg';
import Bicycle from '../assets/icons/folders/bicycle.svg';
import Bills from '../assets/icons/folders/bills.svg';
import BlackFolder from '../assets/icons/folders/blackFolder.svg';
import BlueFolder from '../assets/icons/folders/blueFolder.svg';
import Boat from '../assets/icons/folders/boat.svg';
import Books from '../assets/icons/folders/books.svg';
import Budget from '../assets/icons/folders/budget.svg';
import BusinessCard from '../assets/icons/folders/businessCard.svg';
import Camper from '../assets/icons/folders/camper.svg';
import Coffee from '../assets/icons/folders/coffee.svg';
import Contracts from '../assets/icons/folders/contracts.svg';
import Construction from '../assets/icons/folders/construction.svg';
import Costco from '../assets/icons/folders/costco.svg';
import Coupon from '../assets/icons/folders/coupon.svg';
import CVS from '../assets/icons/folders/cvs.svg';
import Dental from '../assets/icons/folders/dental.svg';
import Electronics from '../assets/icons/folders/electronics.svg';
import Factory from '../assets/icons/folders/factory.svg';
import Family from '../assets/icons/folders/family.svg';
import Food from '../assets/icons/folders/food.svg';
import Garden from '../assets/icons/folders/garden.svg';
import Golf from '../assets/icons/folders/golf.svg';
import GreenFolder from '../assets/icons/folders/greenFolder.svg';
import HarborFreight from '../assets/icons/folders/harborFreight.svg';
import IGA from '../assets/icons/folders/iga.svg';
import JayC from '../assets/icons/folders/jayC.svg';
import Jewelry from '../assets/icons/folders/jewelry.svg';
import Kohls from '../assets/icons/folders/kohls.svg';
import Kroger from '../assets/icons/folders/kroger.svg';
import Legal from '../assets/icons/folders/legal.svg';
import Menards from '../assets/icons/folders/menards.svg';
import Motorcycle from '../assets/icons/folders/motorcycle.svg';
import Office from '../assets/icons/folders/office.svg';
import OrangeFolder from '../assets/icons/folders/orangeFolder.svg';
import Parking from '../assets/icons/folders/parking.svg';
import Pharmacy from '../assets/icons/folders/pharmacy.svg';
import PinkFolder from '../assets/icons/folders/pinkFolder.svg';
import Plane from '../assets/icons/folders/plane.svg';
import Postage from '../assets/icons/folders/postage.svg';
import RedFolder from '../assets/icons/folders/redFolder.svg';
import Ross from '../assets/icons/folders/ross.svg';
import School from '../assets/icons/folders/school.svg';
import Security from '../assets/icons/folders/security.svg';
import Shopping from '../assets/icons/folders/shopping.svg';
import SkyBlueFolder from '../assets/icons/folders/skyBlueFolder.svg';
import LabResults from '../assets/icons/folders/labResults.svg';
import TractorSupply from '../assets/icons/folders/tractorSupply.svg';
import TraderJoes from '../assets/icons/folders/traderJoes.svg';
import Truck from '../assets/icons/folders/truck.svg';
import TV from '../assets/icons/folders/tv.svg';
import Verizon from '../assets/icons/folders/verizon.svg';
import VioletFolder from '../assets/icons/folders/violetFolder.svg';
import Vision from '../assets/icons/folders/vision.svg';
import Warranties from '../assets/icons/folders/warranties.svg';
import YellowFolder from '../assets/icons/folders/yellowFolder.svg';

import Bucees from '../assets/icons/folders/bucees.svg';
import Camera from '../assets/icons/folders/camera.svg';
import Tennis from '../assets/icons/folders/tennis.svg';
import Sports from '../assets/icons/folders/sports.svg';
import Bowling from '../assets/icons/folders/bowling.svg';

import Remax from '../assets/icons/folders/remax.svg';
import Mail from '../assets/icons/folders/mail.svg';
import BankOfAmerica from '../assets/icons/folders/bankOfAmerica.svg';
import Pnc from '../assets/icons/folders/pnc.svg';
import WellsFargo from '../assets/icons/folders/wellsFargo.svg';
import Chase from '../assets/icons/folders/chase.svg';
import SamsClub from '../assets/icons/folders/samsClub.svg';
import Citi from '../assets/icons/folders/citi.svg';
import AmericanExpress from '../assets/icons/folders/americanExpress.svg';
import Discover from '../assets/icons/folders/discover.svg';
import Sewing from '../assets/icons/folders/sewing.svg';
import BestBuy from '../assets/icons/folders/bestBuy.svg';
import CarBlue from '../assets/icons/folders/car.svg';
import CarOrange from '../assets/icons/folders/carOrange.svg';
import CarPurple from '../assets/icons/folders/carPurple.svg';
import CarGreen from '../assets/icons/folders/carGreen.svg';
import Walgreens from '../assets/icons/folders/walgreens.svg';
import Income from '../assets/icons/folders/income.svg';
import Internet from '../assets/icons/folders/internet.svg';

import Art1 from '../assets/icons/folders/art1.svg';
import Art2 from '../assets/icons/folders/art2.svg';
import AutoParts from '../assets/icons/folders/autoParts.svg';
import Banking from '../assets/icons/folders/banking.svg';
import BestOffer from '../assets/icons/folders/bestOffer.svg';
import Books2 from '../assets/icons/folders/books2.svg';
import Car2 from '../assets/icons/folders/car2.svg';
import Cash from '../assets/icons/folders/cash.svg';
import CellPhone from '../assets/icons/folders/cellPhone.svg';
import Charity from '../assets/icons/folders/charity.svg';
import ChickenFeed from '../assets/icons/folders/chickenFeed.svg';
import Clothing1 from '../assets/icons/folders/clothing1.svg';
import Clothing2 from '../assets/icons/folders/clothing2.svg';
import Clothing3 from '../assets/icons/folders/clothing3.svg';
import Clothing4 from '../assets/icons/folders/clothing4.svg';
import Coupon2 from '../assets/icons/folders/coupon2.svg';
import Cow from '../assets/icons/folders/cow.svg';
import Equipment from '../assets/icons/folders/equipment.svg';
import Feed from '../assets/icons/folders/feed.svg';
import GiftCard from '../assets/icons/folders/giftCard.svg';
import Goat from '../assets/icons/folders/goat.svg';
import GreetingCard from '../assets/icons/folders/greetingCard.svg';
import Health1 from '../assets/icons/folders/health1.svg';
import Health2 from '../assets/icons/folders/health2.svg';
import HomeImprovement from '../assets/icons/folders/homeImprovement.svg';
import Horse from '../assets/icons/folders/horse.svg';
import Hotel from '../assets/icons/folders/hotel.svg';
import Hotels from '../assets/icons/folders/hotels.svg';
import Household from '../assets/icons/folders/household.svg';
import Kids from '../assets/icons/folders/kids.svg';
import Liquor from '../assets/icons/folders/liquor.svg';
import Material from '../assets/icons/folders/material.svg';
import Materials from '../assets/icons/folders/materials.svg';
import Meals from '../assets/icons/folders/meals.svg';
import Megaphone from '../assets/icons/folders/megaphone.svg';
import Miscellaneous from '../assets/icons/folders/miscellaneous.svg';
import OtherFile from '../assets/icons/folders/otherFile.svg';
import Perscriptions from '../assets/icons/folders/perscriptions.svg';
import PersonalCare1 from '../assets/icons/folders/personalCare1.svg';
import PersonalCare2 from '../assets/icons/folders/personalCare2.svg';
import RealEstate1 from '../assets/icons/folders/realEstate1.svg';
import RealEstate2 from '../assets/icons/folders/realEstate2.svg';
import Rent1 from '../assets/icons/folders/rent1.svg';
import Rent2 from '../assets/icons/folders/rent2.svg';
import Rental1 from '../assets/icons/folders/rental1.svg';
import Rental2 from '../assets/icons/folders/rental2.svg';
import Rental3 from '../assets/icons/folders/rental3.svg';
import Repairs from '../assets/icons/folders/repairs.svg';
import Sheep from '../assets/icons/folders/sheep.svg';
import Shipping1 from '../assets/icons/folders/shipping1.svg';
import Shipping2 from '../assets/icons/folders/shipping2.svg';
import Shipping3 from '../assets/icons/folders/shipping3.svg';
import Sports2 from '../assets/icons/folders/sports2.svg';
import Tolls from '../assets/icons/folders/tolls.svg';
import Tractor from '../assets/icons/folders/tractor.svg';
import UncategorizedBlue from '../assets/icons/folders/uncategorizedBlue.svg';
import Wine1 from '../assets/icons/folders/wine1.svg';
import Wine2 from '../assets/icons/folders/wine2.svg';

import Bike from '../assets/icons/folders/bike.svg';
import CatBlack from '../assets/icons/folders/catBlack.svg';
import CatGrey from '../assets/icons/folders/catGrey.svg';
import CatOrange from '../assets/icons/folders/catOrange.svg';
import CatWhite from '../assets/icons/folders/catWhite.svg';
import CoffeeCup from '../assets/icons/folders/coffeeCup.svg';
import CoffeeMug from '../assets/icons/folders/coffeeMug.svg';
import ConvertibleBlue from '../assets/icons/folders/convertibleBlue.svg';
import ConvertibleRed from '../assets/icons/folders/convertibleRed.svg';
import ElectricalPlug from '../assets/icons/folders/electricalPlug.svg';
import Lightbulb from '../assets/icons/folders/lightbulb.svg';
import Magazine from '../assets/icons/folders/magazine.svg';
import Newspaper1 from '../assets/icons/folders/newspaper2.svg';
import Newspaper2 from '../assets/icons/folders/newspaper1.svg';
import Rake from '../assets/icons/folders/rake.svg';
import RV from '../assets/icons/folders/rv.svg';
import Sailboat from '../assets/icons/folders/sailboat.svg';
import Shears from '../assets/icons/folders/shears.svg';
import SmallPlane from '../assets/icons/folders/smallPlane.svg';
import WateringCan from '../assets/icons/folders/wateringCan.svg';
import Wegmans from '../assets/icons/folders/wegmans.svg';

import BabyYellow from '../assets/icons/folders/babyYellow.svg';
import BabyBrown from '../assets/icons/folders/babyBrown.svg';
import BabySwaddleYellow from '../assets/icons/folders/babySwaddleYellow.svg';
import BabySwaddleBrown from '../assets/icons/folders/babySwaddleBrown.svg';
import DadYellow from '../assets/icons/folders/dadYellow.svg';
import DadBrown from '../assets/icons/folders/dadBrown.svg';
import GrandmaYellow from '../assets/icons/folders/grandmaYellow.svg';
import GrandmaBrown from '../assets/icons/folders/grandpaBrown.svg';
import GrandpaYellow from '../assets/icons/folders/grandpaYellow.svg';
import GrandpaBrown from '../assets/icons/folders/grandpaBrown.svg';
import MomYellow from '../assets/icons/folders/momYellow.svg';
import MomBrown from '../assets/icons/folders/momBrown.svg';
import BrotherYellow from '../assets/icons/folders/brotherYellow.svg';
import BrotherBrown from '../assets/icons/folders/brotherBrown.svg';
import SisterYellow from '../assets/icons/folders/sisterYellow.svg';
import SisterBrown from '../assets/icons/folders/sisterBrown.svg';

import Makeup from '../assets/icons/folders/makeup.svg';
import Microphone from '../assets/icons/folders/microphone.svg';
import Projector from '../assets/icons/folders/projector.svg';
import Radio from '../assets/icons/folders/radio.svg';
import Scissors from '../assets/icons/folders/scissors.svg';
import Wheelchair from '../assets/icons/folders/wheelchair.svg';
import Film from '../assets/icons/folders/film.svg';
import VideoCamera from '../assets/icons/folders/videoCamera.svg';
import VideoCamera2 from '../assets/icons/folders/videoCamera2.svg';

import PercentUp from '../assets/icons/folders/percentUp.svg';
import PercentDown from '../assets/icons/folders/percentDown.svg';
import Wallet from '../assets/icons/folders/wallet.svg';
import Secure from '../assets/icons/folders/secure.svg';

import Guitar from '../assets/icons/folders/guitar.svg';
import Music from '../assets/icons/folders/music.svg';
import Golf2 from '../assets/icons/folders/golf2.svg';
import Furniture from '../assets/icons/folders/furniture.svg';
import Bagpipes from '../assets/icons/folders/bagpipes.svg';

import Cake1 from '../assets/icons/folders/cake1.svg';
import Cake2 from '../assets/icons/folders/cake2.svg';
import CarWash from '../assets/icons/folders/carWash.svg';
import CleaningSupplies from '../assets/icons/folders/cleaningSupplies.svg';
import ConstructionLabor from '../assets/icons/folders/constructionLabor.svg';
import Diapers from '../assets/icons/folders/diapers.svg';
import Fraud from '../assets/icons/folders/fraud.svg';
import Income2 from '../assets/icons/folders/income2.svg';
import Lyft from '../assets/icons/folders/lyft.svg';
import Uber from '../assets/icons/folders/uber2.svg';
import Mower from '../assets/icons/folders/mower.svg';
import PestControl from '../assets/icons/folders/pestControl.svg';
import Pool from '../assets/icons/folders/pool.svg';
import Salon1 from '../assets/icons/folders/salon1.svg';
import Salon2 from '../assets/icons/folders/salon2.svg';
import Shower from '../assets/icons/folders/shower.svg';
import Toll from '../assets/icons/folders/toll.svg';
import ToyBear from '../assets/icons/folders/toyBear.svg';
import ToyDuck from '../assets/icons/folders/toyDuck.svg';
import Fruit from '../assets/icons/folders/fruit.svg';

export const DEFAULT_FOLDER_NAMES = [
    'Groceries',
    'Gas & Auto',
    'Home',
    'Medical',
    'Personal',
];

export const ICON_SIZE = 50;

export const Folder = (props: {
    src: string;
    width?: number;
    height?: number;
}) => {
    return (
        <img
            alt=""
            src={props.src}
            width={props.width || ICON_SIZE}
            height={props.height || ICON_SIZE}
        />
    );
};

type IFolderType = {
    name: string;
    icon: JSX.Element;
};

export const FOLDERS: IFolderType[] = [
    {
        name: 'Gas & Auto',
        icon: <Folder src={Auto} />,
    },
    {
        name: 'Christmas',
        icon: <Folder src={Christmas} />,
    },
    {
        name: 'Clothes',
        icon: <Folder src={Clothes} />,
    },
    {
        name: 'Credit Card',
        icon: <Folder src={CreditCard} />,
    },
    {
        name: 'Donation',
        icon: <Folder src={Donation} />,
    },
    {
        name: 'Gifts',
        icon: <Folder src={Gifts} />,
    },
    {
        name: 'Groceries',
        icon: <Folder src={Groceries} />,
    },
    {
        name: 'Home',
        icon: <Folder src={Home} />,
    },
    {
        name: 'Medical',
        icon: <Folder src={Medical} />,
    },
    {
        name: 'Pets',
        icon: <Folder src={Pets} />,
    },
    {
        name: 'Restaurant',
        icon: <Folder src={Restaurant} />,
    },
    {
        name: 'Return',
        icon: <Folder src={Return} />,
    },
    {
        name: 'Tax',
        icon: <Folder src={Tax} />,
    },
    {
        name: 'Personal',
        icon: <Folder src={ID} />,
    },
];

export interface IIconItem {
    searchTerms: string[];
    id: string;
    icon: string;
    scale?: number;
}

/**
 * NOTE: Below should be a direct copy from the mobile repo. Do not modify, because it will be overwritten on the next batch of folders we import
 * Additionally, the assets/icons/folders directory should be the same (copy/pastable) as the mobile assets/icons/folders
 */

export const FOLDER_ICONS: { [key: string]: string } = {
    'all documents': AllDocuments,
    'uncategorized': Uncategorized,
    'medical': Medical,
    'groceries': Groceries,
    'restaurant': Restaurant,
    'return': Return,
    'tax deduction': Tax,
    'gas & auto': Auto,
    'add category': AddBtn,
    'donations': Donation,
    'clothes': Clothes,
    'home': Home,
    'edit \n categories': Edit,
    'christmas': Christmas,
    'gifts': Gifts,
    'personal': Vault,
    'pets': Pets,
    'travel': Travel,
    'work': Work,
    'doctor': Doctor,
    'insurance': Insurance,
    'benefits': Benefits,
    'walmart': Walmart,
    'home depot': HomeDepot,
    'tools': Tools,
    'target': Target,
    'lowes': Lowes,
    'utilities': Utilities,
    'farm': Farm,
    'supplies': Supplies,
    'entertainment': Entertainment,
    'credit card': CreditCard,
    'credit cards': CreditCard,
    'recipes': Recipes,
    'bank': Bank,
    'banking': Bank,
    'crafts': Crafts,
    'hobbies': Crafts,
    'hobby': Crafts,
    'church': Church,
    'appliances': Appliances,
    'subscriptions': Subscriptions,
    'computer': Computer,
    'phone': Phone,
    'self-care': SelfCare,
    'self care': SelfCare,
    'wellness': SelfCare,
};

export const FOLDER_ICONS_V2: IIconItem[] = [
    // FOLDERS
    {
        searchTerms: ['files', 'folder', 'document'],
        id: 'all-documents',
        icon: AllDocuments,
    },
    {
        searchTerms: ['files', 'folder', 'document'],
        id: 'group',
        icon: SkyBlueFolder,
    },
    {
        searchTerms: ['folder', 'red'],
        id: 'red-folder',
        icon: RedFolder,
    },
    {
        searchTerms: ['yellow', 'folder'],
        id: 'yellow-folder',
        icon: YellowFolder,
    },
    {
        searchTerms: ['orange', 'folder'],
        id: 'orange-folder',
        icon: OrangeFolder,
    },
    {
        searchTerms: ['green', 'folder'],
        id: 'green-folder',
        icon: GreenFolder,
    },
    {
        searchTerms: ['sky blue', 'blue', 'folder', 'light blue'],
        id: 'sky-blue-folder',
        icon: SkyBlueFolder,
    },
    {
        searchTerms: ['blue', 'folder'],
        id: 'blue-folder',
        icon: BlueFolder,
    },
    {
        searchTerms: ['violet', 'purple', 'folder'],
        id: 'violet-folder',
        icon: VioletFolder,
    },
    {
        searchTerms: ['pink', 'folder'],
        id: 'pink-folder',
        icon: PinkFolder,
    },
    {
        searchTerms: ['black', 'folder'],
        id: 'black-folder',
        icon: BlackFolder,
    },
    {
        searchTerms: ['drawer', 'cabinet'],
        id: 'uncategorized',
        icon: Uncategorized,
    },
    {
        searchTerms: ['uncategorized', 'files'],
        id: 'uncategorized-blue',
        icon: UncategorizedBlue,
        scale: 1.5,
    },
    {
        searchTerms: ['uncategorized', 'files'],
        id: 'ereceipts',
        icon: eReceipts,
    },
    // INSURANCE + MEDICAL + HEALTH
    {
        searchTerms: ['doctor', 'hospital', 'checkups'],
        id: 'doctor',
        icon: Doctor,
    },
    {
        searchTerms: ['insurance', 'hospital', 'form', 'medical'],
        id: 'insurance',
        icon: Insurance,
    },
    {
        searchTerms: ['medical', 'prescription'],
        id: 'medical',
        icon: Medical,
    },
    {
        searchTerms: ['medical', 'therapy', 'pharmacy', 'drugstore', 'health'],
        id: 'pharmacy',
        icon: Pharmacy,
    },
    {
        searchTerms: ['benefits', 'social security', 'government'],
        id: 'benefits',
        icon: Benefits,
    },
    {
        searchTerms: ['perscriptions'],
        id: 'perscriptions',
        icon: Perscriptions,
        scale: 1.5,
    },
    {
        searchTerms: ['health', 'insurance', 'medical', 'hospital', 'doctor'],
        id: 'health1',
        icon: Health1,
        scale: 1.5,
    },
    {
        searchTerms: ['health', 'insurance', 'medical', 'hospital', 'doctor'],
        id: 'health2',
        icon: Health2,
        scale: 1.5,
    },
    {
        searchTerms: ['dental', 'dentist'],
        id: 'dental',
        icon: Dental,
    },
    {
        searchTerms: ['vision', 'glasses', 'eye'],
        id: 'vision',
        icon: Vision,
        scale: 1.3,
    },
    {
        searchTerms: [
            'lab results',
            'tests',
            'bloodwork',
            'lab tests',
            'lab results',
        ],
        id: 'lab-results',
        icon: LabResults,
    },
    // FINANCE + TAX
    {
        searchTerms: ['bank', 'piggy bank', 'savings'],
        id: 'bank',
        icon: Bank,
    },
    {
        searchTerms: ['budget', 'finances'],
        id: 'budget',
        icon: Budget,
        scale: 1.2,
    },
    {
        searchTerms: ['wallet', 'budget', 'finances', 'fees', 'commissions'],
        id: 'wallet',
        icon: Wallet,
        scale: 1.2,
    },
    {
        searchTerms: ['income', 'check', 'finance'],
        id: 'income',
        icon: Income,
    },
    {
        searchTerms: ['income', 'check', 'finance'],
        id: 'income2',
        icon: Income2,
    },
    {
        searchTerms: ['banking', 'bank', 'stocks'],
        id: 'banking',
        icon: Banking,
        scale: 1.5,
    },
    {
        searchTerms: ['interest', 'stocks', 'investment'],
        id: 'percent-up',
        icon: PercentUp,
        scale: 1.5,
    },
    {
        searchTerms: ['depreciation', 'interest', 'stocks'],
        id: 'percent-down',
        icon: PercentDown,
        scale: 1.5,
    },
    {
        searchTerms: ['credit card', 'cards', 'debit'],
        id: 'credit-card',
        icon: CreditCard,
        scale: 1.3,
    },
    {
        searchTerms: ['bill', 'bills'],
        id: 'bills',
        icon: Bills,
    },
    {
        searchTerms: ['cash', 'money', 'atm'],
        id: 'cash',
        icon: Cash,
        scale: 1.5,
    },
    // DONATIONS
    {
        searchTerms: ['taxes', 'bills'],
        id: 'tax-deduction',
        icon: Tax,
    },
    {
        searchTerms: ['donation', 'heart', 'love', 'charity'],
        id: 'donation',
        icon: Donation,
    },
    {
        searchTerms: ['charity', 'donations', 'deduction'],
        id: 'charity',
        icon: Charity,
        scale: 1.5,
    },
    {
        searchTerms: ['self care', 'self-care', 'wellness'],
        id: 'self-care',
        icon: SelfCare,
    },
    // BUSINESS
    {
        searchTerms: ['contracts', 'contract'],
        id: 'contracts',
        icon: Contracts,
    },
    {
        searchTerms: ['work', 'business', 'bag'],
        id: 'work',
        icon: Work,
    },
    {
        searchTerms: ['business card', 'card'],
        id: 'business-card',
        icon: BusinessCard,
        scale: 1.5,
    },
    {
        searchTerms: ['legal', 'lawyer', 'attorney', 'court'],
        id: 'legal',
        icon: Legal,
        scale: 1.2,
    },
    {
        searchTerms: ['fraud', 'email', 'legal', 'lawyer'],
        id: 'fraud',
        icon: Fraud,
        scale: 1.5,
    },
    // FOOD + GROCERY
    {
        searchTerms: ['groceries', 'food', 'market'],
        id: 'groceries',
        icon: Groceries,
    },
    {
        searchTerms: ['restaurant', 'food', 'eatery'],
        id: 'restaurant',
        icon: Restaurant,
        scale: 1.6,
    },
    {
        searchTerms: [
            'food',
            'meals',
            'gluten free food',
            'healthy food',
            'health food',
        ],
        id: 'food',
        icon: Food,
    },
    {
        id: 'fruit',
        icon: Fruit,
        searchTerms: ['apple', 'fruit', 'food', 'health'],
    },
    {
        searchTerms: ['meals', 'cooking', 'dinner', 'restaurant', 'spaghetti'],
        id: 'meals',
        icon: Meals,
        scale: 1.5,
    },
    {
        searchTerms: ['returns', 'package', 'boxes'],
        id: 'return',
        icon: Return,
        scale: 1.3,
    },
    {
        id: 'cake1',
        icon: Cake1,
        searchTerms: ['bakery', 'baking', 'cake', 'birthday', 'birthday cake'],
    },
    {
        id: 'cake2',
        icon: Cake2,
        searchTerms: ['bakery', 'baking', 'cake', 'birthday', 'birthday cake'],
    },
    {
        searchTerms: ['wine', 'alcohol', 'beverage'],
        id: 'wine1',
        icon: Wine1,
        scale: 1.5,
    },
    {
        searchTerms: ['wine', 'alcohol', 'beverage'],
        id: 'wine2',
        icon: Wine2,
        scale: 1.5,
    },
    {
        searchTerms: ['liquor', 'alcohol'],
        id: 'liquor',
        icon: Liquor,
        scale: 1.5,
    },
    {
        searchTerms: ['recipes', 'baking', 'cooking'],
        id: 'recipes',
        icon: Recipes,
        scale: 1.4,
    },
    {
        searchTerms: ['coffee', 'cafe', 'meetings'],
        id: 'coffee',
        icon: Coffee,
    },
    {
        searchTerms: ['coffee', 'cafe', 'meetings'],
        id: 'coffee-mug',
        icon: CoffeeMug,
    },
    {
        searchTerms: ['coffee', 'cafe', 'meetings'],
        id: 'coffee-cup',
        icon: CoffeeCup,
    },
    // AUTO + CAR
    {
        searchTerms: ['gas', 'auto', 'car', 'fuel'],
        id: 'gas-and-auto',
        icon: Auto,
    },
    {
        searchTerms: ['gas', 'auto', 'car', 'fuel'],
        id: 'gas-and-auto-green',
        icon: AutoGreen,
    },
    {
        searchTerms: ['gas', 'auto', 'car', 'fuel'],
        id: 'gas-and-auto-pink',
        icon: AutoPink,
    },
    {
        searchTerms: ['gas', 'auto', 'car', 'fuel'],
        id: 'gas-and-auto-purple',
        icon: AutoPurple,
    },
    {
        searchTerms: ['gas', 'auto', 'car', 'fuel'],
        id: 'gas-and-auto-yellow',
        icon: AutoYellow,
    },
    {
        searchTerms: ['car', 'cars', 'vehicle', 'auto', 'automobile'],
        id: 'car-blue',
        icon: CarBlue,
    },
    {
        searchTerms: ['car', 'cars', 'vehicle', 'auto', 'automobile'],
        id: 'car-green',
        icon: CarGreen,
    },
    {
        searchTerms: ['car', 'cars', 'vehicle', 'auto', 'automobile'],
        id: 'car-orange',
        icon: CarOrange,
    },
    {
        searchTerms: ['car', 'cars', 'vehicle', 'auto', 'automobile'],
        id: 'car-purple',
        icon: CarPurple,
    },
    {
        searchTerms: ['car', 'cars', 'vehicle', 'auto', 'automobile'],
        id: 'car2',
        icon: Car2,
        scale: 1.5,
    },
    {
        searchTerms: [
            'car',
            'cars',
            'vehicle',
            'auto',
            'automobile',
            'convertible',
        ],
        id: 'convertible-blue',
        icon: ConvertibleBlue,
    },
    {
        searchTerms: [
            'car',
            'cars',
            'vehicle',
            'auto',
            'automobile',
            'convertible',
        ],
        id: 'convertible-red',
        icon: ConvertibleRed,
    },
    {
        searchTerms: ['uber', 'ride share', 'transportation'],
        id: 'uber',
        icon: Uber,
    },
    {
        searchTerms: ['lyft', 'ride share', 'transportation'],
        id: 'lyft',
        icon: Lyft,
    },
    {
        searchTerms: ['car', 'truck', 'wash', 'car wash', 'truck wash'],
        id: 'car-wash',
        icon: CarWash,
        scale: 1.5,
    },
    {
        searchTerms: ['toll', 'tolls'],
        id: 'tolls',
        icon: Tolls,
        scale: 1.5,
    },
    {
        searchTerms: ['toll', 'tolls'],
        id: 'toll',
        icon: Toll,
        scale: 1.5,
    },
    {
        searchTerms: ['auto', 'parts', 'shop', 'car', 'motor', 'mechanic'],
        id: 'auto-parts',
        icon: AutoParts,
        scale: 1.5,
    },
    {
        searchTerms: ['truck', 'rig'],
        id: 'truck',
        icon: Truck,
        scale: 1.2,
    },
    {
        searchTerms: ['plane', 'airplane', 'travel', 'vacation', 'trips'],
        id: 'plane',
        icon: Plane,
    },
    {
        searchTerms: ['plane', 'airplane', 'travel', 'vacation', 'trips'],
        id: 'small-plane',
        icon: SmallPlane,
    },
    {
        searchTerms: ['travel', 'trip', 'vacation', 'suitcase', 'luggage'],
        id: 'travel',
        icon: Travel,
    },
    {
        searchTerms: ['parking'],
        id: 'parking',
        icon: Parking,
    },
    // CLOTHES + SHOPPING
    {
        searchTerms: ['clothes', 'shirt'],
        id: 'clothes',
        icon: Clothes,
    },
    {
        searchTerms: ['diapers', 'baby', 'child'],
        id: 'diapers',
        icon: Diapers,
    },
    {
        searchTerms: ['sewing', 'crafts', 'clothes', 'etsy'],
        id: 'sewing',
        icon: Sewing,
    },
    {
        searchTerms: ['clothing', 'shopping', 'clothes', 'dresses', 'fashion'],
        id: 'clothing1',
        icon: Clothing1,
        scale: 1.5,
    },
    {
        searchTerms: ['clothing', 'shopping', 'clothes', 'dresses', 'fashion'],
        id: 'clothing2',
        icon: Clothing2,
        scale: 1.5,
    },
    {
        searchTerms: ['clothing', 'shopping', 'clothes', 'dresses', 'fashion'],
        id: 'clothing3',
        icon: Clothing3,
        scale: 1.5,
    },
    {
        searchTerms: ['clothing', 'shopping', 'clothes', 'dresses', 'fashion'],
        id: 'clothing4',
        icon: Clothing4,
        scale: 1.5,
    },
    {
        searchTerms: ['material', 'clothing', 'clothes', 'fabric', 'fashion'],
        id: 'material',
        icon: Material,
        scale: 1.5,
    },
    {
        searchTerms: ['shopping'],
        id: 'shopping',
        icon: Shopping,
    },
    // HOME + PROPERTY
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home',
        icon: Home,
        scale: 2,
    },
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home-cyan',
        icon: HomeCyan,
        scale: 2,
    },
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home-pink',
        icon: HomePink,
        scale: 2,
    },
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home-purple',
        icon: HomePurple,
        scale: 2,
    },
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home-orange',
        icon: HomeOrange,
        scale: 2,
    },
    {
        searchTerms: [
            'home',
            'house',
            'airbnb',
            'air bnb',
            'condo',
            'apartment',
            'rental',
        ],
        id: 'home-green',
        icon: HomeGreen,
        scale: 2,
    },
    {
        searchTerms: ['real estate', 'house'],
        id: 'real-estate1',
        icon: RealEstate1,
        scale: 1.5,
    },
    {
        searchTerms: ['real estate', 'house', 'home'],
        id: 'real-estate2',
        icon: RealEstate2,
        scale: 1.5,
    },
    {
        searchTerms: ['rent', 'assets', 'mortgage', 'property'],
        id: 'rent1',
        icon: Rent1,
        scale: 1.5,
    },
    {
        searchTerms: ['rent', 'assets', 'mortgage', 'property'],
        id: 'rent2',
        icon: Rent2,
        scale: 1.5,
    },
    {
        searchTerms: ['rental', 'property'],
        id: 'rental1',
        icon: Rental1,
        scale: 1.5,
    },
    {
        searchTerms: ['rental', 'property'],
        id: 'rental2',
        icon: Rental2,
        scale: 1.5,
    },
    {
        searchTerms: ['rental', 'property'],
        id: 'rental3',
        icon: Rental3,
        scale: 1.5,
    },
    {
        searchTerms: ['hotel'],
        id: 'hotel',
        icon: Hotel,
        scale: 1.5,
    },
    {
        searchTerms: ['hotels'],
        id: 'hotels',
        icon: Hotels,
        scale: 1.5,
    },
    // FAMILY
    {
        searchTerms: [
            'household',
            'furniture',
            'moving',
            'sofa',
            'couch',
            'chair',
            'house',
            'home',
        ],
        id: 'furniture',
        icon: Furniture,
        scale: 1.5,
    },
    {
        searchTerms: ['household', 'furniture'],
        id: 'household',
        icon: Household,
        scale: 1.5,
    },
    {
        searchTerms: ['kids', 'toys', 'education', 'daycare', 'child care'],
        id: 'kids',
        icon: Kids,
        scale: 1.5,
    },
    {
        searchTerms: ['kids', 'toys', 'teddy bear', 'child care'],
        id: 'toy-bear',
        icon: ToyBear,
        scale: 1.5,
    },
    {
        searchTerms: ['kids', 'toys', 'child care'],
        id: 'toy-duck',
        icon: ToyDuck,
        scale: 1.5,
    },
    {
        searchTerms: ['school', 'education', 'college', 'university'],
        id: 'school',
        icon: School,
        scale: 1.4,
    },
    {
        searchTerms: ['family', 'mom', 'kids', 'dad', 'parents'],
        id: 'family',
        icon: Family,
        scale: 1.3,
    },
    {
        searchTerms: ['church'],
        id: 'church',
        icon: Church,
    },
    {
        searchTerms: ['christmas', 'holidays', 'tree'],
        id: 'christmas',
        icon: Christmas,
    },
    {
        searchTerms: ['safe', 'vault', 'personal'],
        id: 'personal',
        icon: Vault,
    },
    {
        searchTerms: ['pets', 'dog', 'animals'],
        id: 'pets',
        icon: Pets,
    },
    {
        searchTerms: ['pets', 'cats', 'animals', 'kitty', 'kittens', 'gato'],
        id: 'cat-orange',
        icon: CatOrange,
    },
    {
        searchTerms: ['pets', 'cats', 'animals', 'kitty', 'kittens', 'gato'],
        id: 'cat-white',
        icon: CatWhite,
    },
    {
        searchTerms: ['pets', 'cats', 'animals', 'kitty', 'kittens', 'gato'],
        id: 'cat-gray',
        icon: CatGrey,
    },
    {
        searchTerms: ['pets', 'cats', 'animals', 'kitty', 'kittens', 'gato'],
        id: 'cat-black',
        icon: CatBlack,
    },
    {
        searchTerms: ['baby'],
        id: 'baby-yellow',
        icon: BabyYellow,
    },
    {
        searchTerms: ['baby'],
        id: 'baby-brown',
        icon: BabyBrown,
    },
    {
        searchTerms: ['baby'],
        id: 'baby-swaddle-yellow',
        icon: BabySwaddleYellow,
    },
    {
        searchTerms: ['baby'],
        id: 'baby-swaddle-brown',
        icon: BabySwaddleBrown,
    },
    {
        searchTerms: ['dad', 'father'],
        id: 'dad-yellow',
        icon: DadYellow,
    },
    {
        searchTerms: ['dad', 'father'],
        id: 'dad-brown',
        icon: DadBrown,
    },
    {
        searchTerms: ['mom', 'mother'],
        id: 'mom-yellow',
        icon: MomYellow,
    },
    {
        searchTerms: ['mom', 'mother'],
        id: 'mom-brown',
        icon: MomBrown,
    },
    {
        searchTerms: ['grandma', 'grandmother'],
        id: 'grandma-yellow',
        icon: GrandmaYellow,
    },
    {
        searchTerms: ['grandma', 'grandmother'],
        id: 'grandma-brown',
        icon: GrandmaBrown,
    },
    {
        searchTerms: ['grandpa', 'grandfather'],
        id: 'grandpa-yellow',
        icon: GrandpaYellow,
    },
    {
        searchTerms: ['grandpa', 'grandfather'],
        id: 'grandpa-brown',
        icon: GrandpaBrown,
    },
    {
        searchTerms: ['brother', 'boy', 'kid'],
        id: 'brother-yellow',
        icon: BrotherYellow,
    },
    {
        searchTerms: ['brother', 'boy', 'kid'],
        id: 'brother-brown',
        icon: BrotherBrown,
    },
    {
        searchTerms: ['sister', 'girl', 'kid'],
        id: 'sister-yellow',
        icon: SisterYellow,
    },
    {
        searchTerms: ['sister', 'girl', 'kid'],
        id: 'sister-brown',
        icon: SisterBrown,
    },
    // TOOLS + HOME IMPROVEMENT
    {
        searchTerms: [
            'home improvement',
            'home',
            'housework',
            'paint',
            'construction',
            'repairs',
            'maintenance',
        ],
        id: 'home-improvement',
        icon: HomeImprovement,
        scale: 1.5,
    },
    {
        searchTerms: ['repairs', 'tools', 'construction', 'home'],
        id: 'repairs',
        icon: Repairs,
        scale: 1.5,
    },
    {
        searchTerms: ['construction', 'labor'],
        id: 'construction-labor',
        icon: ConstructionLabor,
    },
    {
        id: 'cleaning-supplies',
        icon: CleaningSupplies,
        searchTerms: ['cleaning', 'supplies', 'home', 'housework'],
    },
    {
        id: 'pest-control',
        icon: PestControl,
        searchTerms: ['pest', 'pest control'],
        scale: 1.5,
    },
    {
        searchTerms: ['utilities', 'water', 'home', 'bills'],
        id: 'utilities',
        icon: Utilities,
    },
    {
        searchTerms: ['tools', 'fix', 'repair', 'home', 'maintenance'],
        id: 'tools',
        icon: Tools,
    },
    {
        searchTerms: ['tools', 'fix', 'repair', 'home', 'maintenance'],
        id: 'tools-yellow',
        icon: ToolsYellow,
    },
    {
        searchTerms: ['tools', 'fix', 'repair', 'home', 'maintenance'],
        id: 'tools-green',
        icon: ToolsGreen,
    },
    {
        searchTerms: ['tools', 'fix', 'repair', 'home', 'maintenance'],
        id: 'tools-blue',
        icon: ToolsBlue,
    },
    {
        searchTerms: ['tools', 'construction', 'contracting', 'supplies'],
        id: 'construction',
        icon: Construction,
    },
    {
        searchTerms: ['shipping', 'handling', 'packages', 'delivery', 'boxes'],
        id: 'shipping1',
        icon: Shipping1,
        scale: 1.5,
    },
    {
        searchTerms: ['shipping', 'handling', 'packages', 'delivery', 'boxes'],
        id: 'shipping2',
        icon: Shipping2,
        scale: 1.5,
    },
    {
        searchTerms: ['shipping', 'handling', 'packages', 'delivery', 'boxes'],
        id: 'shipping3',
        icon: Shipping3,
        scale: 1.5,
    },
    {
        searchTerms: ['factory'],
        id: 'factory',
        icon: Factory,
    },
    {
        searchTerms: ['lawn', 'landscaping', 'mower', 'lawnmower'],
        id: 'mower',
        icon: Mower,
    },
    {
        searchTerms: [
            'garden',
            'gardening',
            'plants',
            'flowers',
            'yardwork',
            'hedging',
            'landscaping',
        ],
        id: 'garden',
        icon: Garden,
    },
    {
        id: 'pool',
        icon: Pool,
        searchTerms: ['pool', 'swimming', 'swimming pool'],
        scale: 1.5,
    },
    {
        searchTerms: [
            'garden',
            'gardening',
            'plants',
            'flowers',
            'yardwork',
            'hedging',
            'landscaping',
            'watering can',
        ],
        id: 'watering-can',
        icon: WateringCan,
    },
    {
        searchTerms: [
            'garden',
            'gardening',
            'plants',
            'flowers',
            'yardwork',
            'hedging',
            'landscaping',
            'shears',
        ],
        id: 'shears',
        icon: Shears,
    },
    {
        searchTerms: [
            'garden',
            'gardening',
            'plants',
            'flowers',
            'yardwork',
            'hedging',
            'landscaping',
            'rake',
        ],
        id: 'rake',
        icon: Rake,
    },
    // SPORT + RECREATION
    {
        searchTerms: ['tennis'],
        id: 'tennis',
        icon: Tennis,
    },
    {
        searchTerms: [
            'sports',
            'basketball',
            'football',
            'soccer',
            'league',
            'gym',
            'recreation',
        ],
        id: 'sports',
        icon: Sports,
    },
    {
        searchTerms: ['bowling', 'sports', 'league'],
        id: 'bowling',
        icon: Bowling,
    },
    {
        searchTerms: ['sports'],
        id: 'sports2',
        icon: Sports2,
        scale: 1.5,
    },
    {
        searchTerms: ['bicycle', 'bike', 'racing'],
        id: 'bicycle',
        icon: Bicycle,
    },
    {
        searchTerms: ['bicycle', 'bike', 'racing'],
        id: 'bike',
        icon: Bike,
    },
    {
        searchTerms: ['motorcycle', 'motorbike'],
        id: 'motorcycle',
        icon: Motorcycle,
    },
    {
        searchTerms: ['golf'],
        id: 'golf',
        icon: Golf,
    },
    {
        searchTerms: ['golf'],
        id: 'golf2',
        icon: Golf2,
    },
    {
        searchTerms: ['boat', 'yacht', 'ship', 'sailboat', 'boating'],
        id: 'boat',
        icon: Boat,
        scale: 1.5,
    },
    {
        searchTerms: ['boat', 'yacht', 'ship', 'sailboat', 'boating'],
        id: 'sailboat',
        icon: Sailboat,
        scale: 1.5,
    },
    {
        searchTerms: ['camper'],
        id: 'camper',
        icon: Camper,
        scale: 1.2,
    },
    {
        searchTerms: ['camper', 'rv', 'motorhome', 'motor home', 'van'],
        id: 'rv',
        icon: RV,
        scale: 1.2,
    },
    // BANKS
    {
        searchTerms: ['citi'],
        id: 'citi',
        icon: Citi,
    },
    {
        searchTerms: ['bank of america', 'bofa', 'b of a'],
        id: 'bank-of-america',
        icon: BankOfAmerica,
        scale: 1.5,
    },
    {
        searchTerms: ['pnc'],
        id: 'pnc',
        icon: Pnc,
        scale: 1.5,
    },
    {
        searchTerms: ['wells fargo'],
        id: 'wells-fargo',
        icon: WellsFargo,
    },
    {
        searchTerms: ['chase'],
        id: 'chase',
        icon: Chase,
        scale: 1.5,
    },
    // FARM
    {
        searchTerms: ['farm', 'crops', 'barn'],
        id: 'farm',
        icon: Farm,
        scale: 1.4,
    },
    {
        searchTerms: ['farm', 'feed', 'animal', 'chicken'],
        id: 'chicken-feed',
        icon: ChickenFeed,
        scale: 1.5,
    },
    {
        searchTerms: ['farm', 'feed', 'animal', 'cow', 'dairy', 'milk'],
        id: 'cow',
        icon: Cow,
        scale: 1.5,
    },
    {
        searchTerms: ['farm', 'feed', 'animal', 'goat'],
        id: 'goat',
        icon: Goat,
        scale: 1.5,
    },
    {
        searchTerms: ['farm', 'feed', 'animal', 'sheep', 'flock'],
        id: 'sheep',
        icon: Sheep,
        scale: 1.5,
    },
    {
        searchTerms: ['horse', 'farm', 'stable', 'riding'],
        id: 'horse',
        icon: Horse,
        scale: 1.5,
    },
    {
        searchTerms: ['feed', 'farm', 'farming'],
        id: 'feed',
        icon: Feed,
        scale: 1.5,
    },
    {
        searchTerms: ['tractor supply'],
        id: 'tractor-supply',
        icon: TractorSupply,
        scale: 1.3,
    },
    {
        searchTerms: ['tractor', 'farm', 'farming'],
        id: 'tractor',
        icon: Tractor,
        scale: 1.5,
    },
    {
        searchTerms: [
            'equipment',
            'machinery',
            'construction',
            'build',
            'landscaping',
        ],
        id: 'equipment',
        icon: Equipment,
        scale: 1.5,
    },
    // CRAFTS + SUPPLIES + CONVINIENCE ITEMS
    {
        searchTerms: ['supplies', 'school'],
        id: 'supplies',
        icon: Supplies,
    },
    {
        searchTerms: [
            'materials',
            'construction',
            'hardware',
            'labor',
            'contract',
        ],
        id: 'materials',
        icon: Materials,
        scale: 1.5,
    },
    {
        searchTerms: ['miscellaneous', 'office', 'supplies', 'work'],
        id: 'miscellaneous',
        icon: Miscellaneous,
        scale: 1.5,
    },
    {
        searchTerms: ['other', 'files'],
        id: 'other-file',
        icon: OtherFile,
        scale: 1.5,
    },
    {
        searchTerms: ['crafts', 'arts', 'scissors'],
        id: 'crafts',
        icon: Crafts,
    },
    {
        searchTerms: ['scissors', 'grooming', 'haircut'],
        id: 'scissors',
        icon: Scissors,
    },
    {
        searchTerms: [
            'beauty',
            'personal',
            'care',
            'toiletries',
            'bathroom',
            'hygiene',
        ],
        id: 'personal-care1',
        icon: PersonalCare1,
        scale: 1.5,
    },
    {
        searchTerms: [
            'beauty',
            'personal',
            'care',
            'toiletries',
            'bathroom',
            'hygiene',
        ],
        id: 'personal-care2',
        icon: PersonalCare2,
        scale: 1.5,
    },
    {
        searchTerms: ['shower'],
        id: 'shower',
        icon: Shower,
    },
    {
        searchTerms: ['makeup', 'cosmetics', 'beauty'],
        id: 'makeup',
        icon: Makeup,
        scale: 1.5,
    },
    {
        searchTerms: ['salon', 'beauty', 'hair'],
        id: 'salon1',
        icon: Salon1,
    },
    {
        searchTerms: ['salon', 'beauty', 'hair'],
        id: 'salon2',
        icon: Salon2,
    },
    {
        searchTerms: ['books', 'research', 'school'],
        id: 'books',
        icon: Books,
        scale: 1.4,
    },
    {
        searchTerms: ['books', 'research', 'school', 'reading'],
        id: 'books2',
        icon: Books2,
        scale: 1.5,
    },
    {
        searchTerms: ['art', 'paint', 'painting', 'crafts', 'arts'],
        id: 'art1',
        icon: Art1,
        scale: 1.5,
    },
    {
        searchTerms: ['art', 'paint', 'painting', 'crafts', 'arts', 'crafting'],
        id: 'art2',
        icon: Art2,
        scale: 1.5,
    },
    {
        searchTerms: ['guitar', 'music', 'instrument', 'lessons'],
        id: 'guitar',
        icon: Guitar,
    },
    {
        searchTerms: ['music', 'instrument', 'lessons'],
        id: 'music',
        icon: Music,
    },
    {
        searchTerms: ['bagpipes', 'music', 'instrument', 'lessons'],
        id: 'bagpipes',
        icon: Bagpipes,
    },
    {
        searchTerms: ['appliances', 'home'],
        id: 'appliances',
        icon: Appliances,
    },
    {
        searchTerms: ['jewelry', 'jewellery-c', 'jewelery'],
        id: 'jewelry',
        icon: Jewelry,
    },
    {
        searchTerms: ['office'],
        id: 'office',
        icon: Office,
    },
    {
        searchTerms: ['gifts', 'presents', 'personal'],
        id: 'gifts',
        icon: Gifts,
    },
    // TECHNOLOGY
    {
        searchTerms: ['phone', 'cell', 'cell phone'],
        id: 'cell-phone',
        icon: CellPhone,
        scale: 1.5,
    },
    {
        searchTerms: ['phone', 'call'],
        id: 'phone',
        icon: Phone,
    },
    {
        searchTerms: ['computer', 'electronics', 'mac'],
        id: 'computer',
        icon: Computer,
        scale: 1.2,
    },
    {
        searchTerms: ['internet', 'wifi', 'router'],
        id: 'internet',
        icon: Internet,
    },
    {
        searchTerms: ['tv', 'television', 'streaming', 'streaming tv'],
        id: 'tv',
        icon: TV,
    },
    {
        searchTerms: ['camera', 'photos', `photography`],
        id: 'camera',
        icon: Camera,
    },
    {
        searchTerms: ['camera', 'photos', `photography`, 'film'],
        id: 'video-camera',
        icon: VideoCamera,
    },
    {
        searchTerms: ['camera', 'photos', `photography`, 'film'],
        id: 'video-camera-2',
        icon: VideoCamera2,
    },
    {
        searchTerms: ['microphone', 'studio', 'audio'],
        id: 'microphone',
        icon: Microphone,
    },
    {
        searchTerms: ['radio', 'speaker'],
        id: 'radio',
        icon: Radio,
    },
    {
        searchTerms: ['projector'],
        id: 'projector',
        icon: Projector,
    },
    {
        searchTerms: ['camera', 'photos', `photography`, 'film'],
        id: 'film',
        icon: Film,
    },
    {
        searchTerms: ['electronics', 'electric', 'utility', 'utilities'],
        id: 'electronics',
        icon: Electronics,
    },
    {
        searchTerms: ['electronics', 'electric', 'utility', 'utilities'],
        id: 'electrical-plug',
        icon: ElectricalPlug,
    },
    {
        searchTerms: [
            'electronics',
            'electric',
            'utility',
            'utilities',
            'lightbulb',
        ],
        id: 'lightbulb',
        icon: Lightbulb,
    },
    {
        searchTerms: ['security', 'computer', 'vpn'],
        id: 'security',
        icon: Security,
    },
    {
        searchTerms: ['security', 'secure', 'insurance', 'lock'],
        id: 'secure',
        icon: Secure,
    },
    // MISC
    {
        searchTerms: ['entertainment', 'party', 'fun'],
        id: 'entertainment',
        icon: Entertainment,
    },
    {
        searchTerms: ['subscriptions'],
        id: 'subscriptions',
        icon: Subscriptions,
    },
    {
        searchTerms: ['wheelchair', 'handicap'],
        id: 'wheelchair',
        icon: Wheelchair,
    },
    {
        searchTerms: ['subscriptions', 'news', 'newspapers'],
        id: 'newspaper1',
        icon: Newspaper1,
    },
    {
        searchTerms: ['subscriptions', 'news', 'newspapers'],
        id: 'newspaper2',
        icon: Newspaper2,
    },
    {
        searchTerms: ['subscriptions', 'news', 'magazines'],
        id: 'magazine',
        icon: Magazine,
    },
    {
        searchTerms: ['coupon', 'coupons'],
        id: 'coupon',
        icon: Coupon,
    },
    {
        searchTerms: ['coupon', 'coupons'],
        id: 'coupon2',
        icon: Coupon2,
        scale: 1.5,
    },
    {
        searchTerms: ['warranties', 'warranty'],
        id: 'warranties',
        icon: Warranties,
    },
    {
        searchTerms: ['mail', 'postage', 'post'],
        id: 'mail',
        icon: Mail,
        scale: 1.4,
    },
    {
        searchTerms: ['postage', 'post office'],
        id: 'postage',
        icon: Postage,
    },
    {
        searchTerms: ['advertising', 'savings', 'coupon', 'ads', 'sales'],
        id: 'best-offer',
        icon: BestOffer,
        scale: 1.5,
    },
    {
        searchTerms: ['advertising', 'savings', 'coupon', 'ads', 'sales'],
        id: 'megaphone',
        icon: Megaphone,
        scale: 1.5,
    },
    {
        searchTerms: ['gift card', 'card'],
        id: 'gift-card',
        icon: GiftCard,
        scale: 1.5,
    },
    {
        searchTerms: ['greeting card', 'card'],
        id: 'greeting-card',
        icon: GreetingCard,
        scale: 1.3,
    },
    // STORES
    {
        searchTerms: ['home depot'],
        id: 'home-depot',
        icon: HomeDepot,
    },
    {
        searchTerms: ['costco'],
        id: 'costco',
        icon: Costco,
        scale: 1.5,
    },
    {
        searchTerms: ['target'],
        id: 'target',
        icon: Target,
    },
    {
        searchTerms: ['lowes'],
        id: 'lowes',
        icon: Lowes,
    },
    {
        searchTerms: ['walmart'],
        id: 'walmart',
        icon: Walmart,
    },
    {
        searchTerms: ['cvs'],
        id: 'cvs',
        icon: CVS,
        scale: 1.5,
    },
    {
        searchTerms: ['walgreens'],
        id: 'walgreens',
        icon: Walgreens,
    },
    {
        searchTerms: ['amazon'],
        id: 'amazon',
        icon: Amazon,
        scale: 1.4,
    },
    {
        searchTerms: ['iga'],
        id: 'iga',
        icon: IGA,
        scale: 1.2,
    },
    {
        searchTerms: ['jayc', 'jay-c', 'jay c'],
        id: 'jayc',
        icon: JayC,
        scale: 1.3,
    },
    {
        searchTerms: ['kohls', `kohl's`],
        id: 'kohls',
        icon: Kohls,
        scale: 1.5,
    },
    {
        searchTerms: ['kroger'],
        id: 'kroger',
        icon: Kroger,
    },
    {
        searchTerms: ['harborfreight'],
        id: 'harbor-freight',
        icon: HarborFreight,
        scale: 1.5,
    },
    {
        searchTerms: ['remax', 're/max', 're / max', 're max'],
        id: 'remax',
        icon: Remax,
    },
    {
        searchTerms: ['bucees', 'buc-ees', `buc-ee's`, 'buckees'],
        id: 'bucees',
        icon: Bucees,
        scale: 1.2,
    },
    {
        searchTerms: ['menards'],
        id: 'menards',
        icon: Menards,
        scale: 1.5,
    },
    {
        searchTerms: ['sams club', `sam's club`],
        id: 'sams-club',
        icon: SamsClub,
        scale: 1.5,
    },
    {
        searchTerms: ['american express', 'amex'],
        id: 'american-express',
        icon: AmericanExpress,
    },
    {
        searchTerms: ['discover'],
        id: 'discover',
        icon: Discover,
    },
    {
        searchTerms: ['best buy', 'bestbuy'],
        id: 'best-buy',
        icon: BestBuy,
    },
    {
        searchTerms: ['verizon'],
        id: 'verizon',
        icon: Verizon,
        scale: 1.4,
    },
    {
        searchTerms: ['wegmans'],
        id: 'wegmans',
        icon: Wegmans,
    },
    {
        searchTerms: ['trader joes', `trader joe's`],
        id: 'trader-joes',
        icon: TraderJoes,
    },
    {
        searchTerms: ['aldi'],
        id: 'aldi',
        icon: Aldi,
    },
    {
        searchTerms: ['ross'],
        id: 'ross',
        icon: Ross,
        scale: 1.3,
    },
];
