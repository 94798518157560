import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import { ConfirmationCodeInput } from '../../../../components/Inputs';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';
import { BodyBold } from '../../../../components/Text';
import { useQuery } from '../../../../util/hooks';
import CircleWrapper from '../../../../assets/onboarding/circle-wrapper.svg';
import PhoneSecure from '../../../../assets/onboarding/phoneSecure.svg';
import theme from '../../../../theme';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SignupScreen, useSignup } from '../context';
import { useAuth } from '../../../../providers/AuthProvider';
import { onWebAppCostEstimatorLoadCodeScreen } from '../../../../tracking/trackers';
import CEPaymentVariants from '../../Payment/components/CEPaymentVariants';

const ConfirmationCodeManualEntry = () => {
    const navigate = useNavigate();
    const { refreshUser } = useAuth();
    const query = useQuery();
    const location = useLocation();
    const {
        mobile,
        sendCodeToMobile,
        shareToken,
        organizationToken,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setScreen,
        submitCode,
    } = useSignup();
    const variant = query.get('variant');
    const method = query.get('method');
    const couponApplied = query.get('couponApplied');

    const isCostEstimatorPaywall = variant === 'cost-estimator';

    const [code, setCode] = useState<string>('');
    const [hasResentCode, setHasResentCode] = useState<string | undefined>(
        undefined
    );

    const expName = CEPaymentVariants.name;
    const { selectVariant } = useExperiment(expName);
    const abVariant = selectVariant(CEPaymentVariants.variants);

    useEffect(() => {
        if (isCostEstimatorPaywall) {
            onWebAppCostEstimatorLoadCodeScreen({
                mobile,
                experiment: expName,
                cohort: abVariant,
            });
        }
    }, [isCostEstimatorPaywall]);

    const onSubmitCode = () => {
        // if they are on the cost estimator paywall, send them to the payment page
        if (isCostEstimatorPaywall) {
            return submitCode(code, mobile, () => {
                refreshUser();
                navigate('/welcome/sign-up?variant=cost-estimator');
            });
        }
        // if we set in query params, it will be captured by useReceiveVerificationParams and verify the code
        // lets us re-use the logic implemented when they verify the code via opening a link
        navigate(
            location.pathname +
                `?manualCodeEntry=true&mobile=${mobile}&code=${code}${
                    shareToken ? `&share_token=${shareToken}` : ''
                }${
                    organizationToken
                        ? `&organization_token=${organizationToken}`
                        : ''
                }&variant=${variant}&method=${method}&couponApplied=${couponApplied}`
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resendCode = () => {
        if (!mobile || hasResentCode) return;
        sendCodeToMobile(mobile);
        setHasResentCode('Code resent!');
    };

    const changeNumber = () => {
        window.location.href =
            location.pathname +
            `?manualCodeEntry=true${
                shareToken ? `&share_token=${shareToken}` : ''
            }${
                organizationToken
                    ? `&organization_token=${organizationToken}`
                    : ''
            }&variant=${variant}&method=${method}&couponApplied=${couponApplied}`;
    };

    return (
        <OnboardingCardTemplate
            title={'We texted you a code'}
            subtitle={`Enter the code sent to ${mobile}`}
            subtitleStyle={{
                textAlign: 'center',
                width: '100%',
                fontSize: 15,
            }}
            topComponent={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            minWidth: '250px',
                            background: `url(${CircleWrapper}) no-repeat center center / contain`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={PhoneSecure}
                            alt={'phone'}
                            style={{ width: 100 }}
                        />
                    </div>
                </div>
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ConfirmationCodeInput
                        value={code}
                        setValue={setCode}
                        autoFocus={true}
                    />
                    {/* <button
                        onClick={resendCode}
                        style={{
                            border: 0,
                            backgroundColor: 'transparent',
                            marginTop: 10,
                        }}
                    >
                        <BodyBold
                            style={{
                                display: 'flex',
                                color: hasResentCode
                                    ? theme.colors.success
                                    : theme.colors.grayText,
                            }}
                        >
                            {hasResentCode ? 'Code resent' : 'Resend Code'}
                        </BodyBold>
                    </button> */}
                    {variant === 'ai-receptionist' ||
                    isCostEstimatorPaywall ? null : (
                        <button
                            onClick={changeNumber}
                            style={{
                                border: 0,
                                backgroundColor: 'transparent',
                                marginTop: 10,
                            }}
                        >
                            <BodyBold
                                style={{
                                    display: 'flex',
                                    color: hasResentCode
                                        ? theme.colors.success
                                        : theme.colors.grayText,
                                }}
                            >
                                Change number
                            </BodyBold>
                        </button>
                    )}
                </div>
            }
            buttons={[
                {
                    text: 'Continue',
                    onClick: onSubmitCode,
                    disabled: !code || code.length !== 6,
                },
            ]}
        />
    );
};

export default ConfirmationCodeManualEntry;
