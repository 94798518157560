import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import theme from '../../../../theme';

export const Container = styled('div')({
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

export const ImageContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

export const CircleContainer = styled('div')<{ dimension: number }>(
    (props) => ({
        width: props.dimension,
        height: props.dimension,
        backgroundColor: theme.colors.offWhite,
        borderRadius: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        margin: '50px 0',
    })
);

export const TitleContainer = styled('div')({
    textAlign: 'center',
    width: '100%',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'center',
    maxWidth: 350,
});

export const Description = styled('div')({
    color: theme.colors.grayText,
    width: '90%',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 1.5,
    marginBottom: 20,
});

export const TakePhotoButton = styled(Button)({
    color: 'white',
    boxShadow: 'none',
    borderRadius: 25,
    height: 50,
    width: '60%',
    fontWeight: '700',
    fontSize: 20,
    minWidth: 350,
});
