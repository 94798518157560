import React, { useState } from 'react';
import { Button, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuestionaire } from '../QuestionaireProvider';
import { BodyBold } from '../../../../components/Text';
import { onWebAppCostEstimationBookingLinkStep } from '../../../../tracking/trackers';
import PublicService from '../../../../services/public/service';
import { PHONE_NUMBER, EMAIL } from '../../../../util/validators';
import {
    Container,
    TitleContainer,
    FormContainer,
    InputGroup,
    StyledLabel,
    StyledInput,
    RequiredIndicator,
    ButtonContainer,
} from './styles';

const StepClientInfo = () => {
    const { setStep, submitProgressToForm, formId, projectName } =
        useQuestionaire();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        mobile: '',
        email: '',
    });
    const [error, setError] = useState('');
    const { url: urlParam } = useParams();
    const url = urlParam?.replace('.simplywise.com', '');

    const validateForm = () => {
        if (!EMAIL.isValidSync(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }

        const phoneNumber = formData.mobile.startsWith('+')
            ? formData.mobile
            : `+1${formData.mobile}`;
        if (!PHONE_NUMBER.isValidSync(phoneNumber)) {
            setError('Please enter a valid phone number');
            return false;
        }
        if (formData.name.trim() === '') {
            setError('Please enter your full name');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const phoneNumber = formData.mobile.startsWith('+')
            ? formData.mobile
            : `+1${formData.mobile}`;

        await submitProgressToForm({
            client_info: {
                name: formData.name,
                address: formData.address,
                mobile: phoneNumber,
                email: formData.email,
            },
            project_name: projectName,
        });
        onWebAppCostEstimationBookingLinkStep({
            step: 'client-info',
            form_id: formId || 0,
            metadata: {
                client_info: formData,
            },
        });
        setStep('step-done');

        PublicService.bookingLinkNotify(url ?? '', 'form_submitted', {
            name: formData.name,
        });
    };

    return (
        <Container>
            {error && (
                <Alert
                    severity="error"
                    sx={{
                        'margin': { xs: '16px', sm: '0 0 16px 0' },
                        'fontSize': { xs: '1rem', sm: '1.125rem' },
                        'borderRadius': '12px',
                        '& .MuiAlert-message': {
                            padding: { xs: '12px 0', sm: '10px 0' },
                            fontWeight: 500,
                            textAlign: 'center',
                            width: '100%',
                        },
                        '& .MuiAlert-icon': {
                            fontSize: '28px',
                            marginRight: '16px',
                            alignSelf: 'center',
                        },
                        'backgroundColor': '#FFF1F0',
                        'color': '#FF3B30',
                    }}
                >
                    {error}
                </Alert>
            )}
            <TitleContainer>
                <BodyBold style={{ fontSize: 24 }}>Your Info</BodyBold>
            </TitleContainer>

            <FormContainer onSubmit={handleSubmit}>
                <InputGroup>
                    <StyledLabel>
                        Your Name
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>
                    <StyledInput
                        type="text"
                        value={formData.name}
                        onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                        }
                        placeholder="e.g., John Smith"
                        autoComplete="name"
                        required
                    />
                </InputGroup>
                <InputGroup>
                    <StyledLabel>Your Address</StyledLabel>
                    <StyledInput
                        type="text"
                        value={formData.address}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                address: e.target.value,
                            })
                        }
                        placeholder="e.g., 123 Main St, Apt 4B"
                        autoComplete="street-address"
                        spellCheck="false"
                    />
                </InputGroup>
                <InputGroup>
                    <StyledLabel>
                        Mobile
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>

                    <StyledInput
                        type="tel"
                        value={formData.mobile}
                        onChange={(e) => {
                            const value = e.target.value
                                .replace(/\D/g, '')
                                .slice(0, 15);
                            setFormData({ ...formData, mobile: value });
                        }}
                        placeholder="e.g., 1234567890"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        autoComplete="tel"
                        required
                    />
                </InputGroup>
                <InputGroup>
                    <StyledLabel>
                        Email
                        <RequiredIndicator>*</RequiredIndicator>
                    </StyledLabel>
                    <StyledInput
                        type="email"
                        value={formData.email}
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                        }
                        placeholder="e.g., john.smith@email.com"
                        autoComplete="email"
                        inputMode="email"
                        enterKeyHint="next"
                        spellCheck="false"
                        required
                    />
                </InputGroup>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            color: 'white',
                            boxShadow: 'none',
                            borderRadius: 25,
                            height: 50,
                            width: '80%',
                            fontWeight: '700',
                            fontSize: 20,
                            minWidth: 350,
                            textTransform: 'none',
                        }}
                        type="submit"
                    >
                        Continue
                    </Button>
                </ButtonContainer>
            </FormContainer>
        </Container>
    );
};

export default StepClientInfo;
