import { useLocation } from 'react-router-dom';
import { VariationButtonElement } from '../configuration/types';
import { onWebAppLandingPageCTAClick } from '../../../tracking/trackers';
import { isIOS } from '../../../util/isIOS';
import multiChannelEvents from '../../../tracking/multiChannelEvents';
import TextingCollectionSmallCTA from '../../Onboarding/TextingCollectionSmallCTA';
import { BodyStyle, LoginButtonStyle } from './SectionStickyHeaderLogo';
import { useCTAAction } from './useCTAAction';

export const VariationCallToActionSmall = (
    props: VariationButtonElement & {
        baseName: string;
        variationName: string;
        isMobile?: boolean;
    }
) => {
    const location = useLocation() as any;
    const ctaAction = useCTAAction(
        props.isMobile ? props.action : props.secondaryAction
    );

    const onCTAClick = () => {
        ctaAction();
        multiChannelEvents.addToCart();
        onWebAppLandingPageCTAClick({
            pageId: props.baseName,
            expName: props.variationName,
            ctaPosition: props.assignedPosition,
            isIOS,
            url: location.pathname,
        });
    };

    if (props.action === 'TeamsCollectionSignup') {
        return (
            <TextingCollectionSmallCTA
                label={props.label}
                onCTAClick={onCTAClick}
            />
        );
    }

    return (
        <LoginButtonStyle
            style={{
                ...props.style,
            }}
            onClick={() => onCTAClick()}
        >
            <BodyStyle
                style={{
                    ...props.labelStyle,
                }}
            >
                {props.label}
            </BodyStyle>
        </LoginButtonStyle>
    );
};
