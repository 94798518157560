import { useState } from 'react';
import { Divider, Drawer as MuiDrawer } from '@mui/material';
import {
    Menu,
    Logout,
    AssuredWorkload,
    DirectionsCar,
    People,
    Email,
    Bolt,
    Settings,
    LiveHelp,
    Calculate,
    Description,
    ArrowForwardIos,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../theme';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { onWebAppLogOut } from '../../tracking/trackers';
import { useAuth } from '../../providers/AuthProvider';
import { useSubscriptionInfo } from '../../hooks/useSubscriptionInfo';
import { STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY } from '../../services/subscriptions/products';
import DrawerItem from './components/DrawerItem';
import DrawerHeader from './components/DrawerHeader';

export const APP_FOOTER_HEIGHT = 40;

const SectionDivider = () => (
    <Divider
        style={{
            height: 1,
            width: '100%',
            alignSelf: 'center',
            backgroundColor: 'rgba(0,0,0,0.2)',
            marginTop: 8,
            marginBottom: 8,
            opacity: 0.2,
        }}
    />
);

const Drawer = () => {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const location = useLocation();
    const { setToken, user } = useAuth();
    const { isAnOrganziationEmployee } = useSubscriptionInfo();
    const isLegacyOrganizationUser =
        user?.subscription_data?.product?.id ===
        STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY;

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const w = width < 500 ? width : 500;

    const quickActionProps = {
        style: { paddingLeft: 40 },
        iconStyle: {
            color: theme.colors.blue60,
        },
    };

    return (
        <>
            <Menu
                style={{
                    color: theme.colors.white,
                    cursor: 'pointer',
                    margin: 5,
                    marginTop: 9,
                }}
                onClick={() => setDrawerVisible(true)}
            />
            <MuiDrawer
                anchor={'left'}
                open={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    width: w,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <DrawerHeader />
                        <SectionDivider />
                        <DrawerItem
                            text={
                                isAnOrganziationEmployee
                                    ? 'Share'
                                    : 'Shared Folders'
                            }
                            MuiIcon={People}
                            onClick={() => {
                                navigate('/share');
                            }}
                        />
                        {isLegacyOrganizationUser &&
                        !isAnOrganziationEmployee ? (
                            <DrawerItem
                                text={'My Team'}
                                MuiIcon={People}
                                onClick={() => {
                                    navigate('/settings/teams/members');
                                }}
                            />
                        ) : null}
                        <SectionDivider />
                        <DrawerItem text="Quick Actions" MuiIcon={Bolt} />
                        {false && (
                            <DrawerItem
                                text="Cost Estimation"
                                MuiIcon={Calculate}
                                onClick={() =>
                                    navigate('/welcome/cost-estimation')
                                }
                                rightSection={
                                    <div
                                        style={{
                                            display: 'flex',
                                            backgroundColor:
                                                theme.colors.secondary,
                                            color: theme.colors.white,
                                            borderRadius: 5,
                                            fontSize: 14,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginLeft: 15,
                                            width: 50,
                                        }}
                                    >
                                        NEW
                                    </div>
                                }
                                {...quickActionProps}
                            />
                        )}
                        <DrawerItem
                            text="Reports"
                            MuiIcon={Description}
                            onClick={() => navigate('/reports')}
                            rightSection={
                                <div
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: 50,
                                    }}
                                >
                                    <ArrowForwardIos
                                        style={{
                                            color: '#aaa',
                                            marginRight: -5,
                                            height: 20,
                                            width: 20,
                                        }}
                                    />
                                </div>
                            }
                            {...quickActionProps}
                        />
                        <DrawerItem
                            text="Reconciliation"
                            MuiIcon={AssuredWorkload}
                            onClick={() => navigate('/plaid')}
                            rightSection={
                                <div
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: 50,
                                    }}
                                >
                                    <ArrowForwardIos
                                        style={{
                                            color: '#aaa',
                                            marginRight: -5,
                                            height: 20,
                                            width: 20,
                                        }}
                                    />
                                </div>
                            }
                            {...quickActionProps}
                        />
                        <DrawerItem
                            text="Mileage Tracking"
                            MuiIcon={DirectionsCar}
                            onClick={() => navigate('/mileage')}
                            rightSection={
                                <div
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: 50,
                                    }}
                                >
                                    <ArrowForwardIos
                                        style={{
                                            color: '#aaa',
                                            marginRight: -5,
                                            height: 20,
                                            width: 20,
                                        }}
                                    />
                                </div>
                            }
                            {...quickActionProps}
                        />
                        <SectionDivider />
                        <DrawerItem
                            text="Integrations"
                            MuiIcon={Email}
                            onClick={() => navigate('/integrations')}
                        />
                        <SectionDivider />
                        <DrawerItem
                            text="Get Help"
                            MuiIcon={LiveHelp}
                            onClick={() => navigate('/help')}
                        />
                        <DrawerItem
                            text="Settings"
                            MuiIcon={Settings}
                            onClick={() => navigate('/settings')}
                        />
                        <DrawerItem
                            text="Log Out"
                            MuiIcon={Logout}
                            onClick={() => {
                                onWebAppLogOut();
                                setToken(null);
                                localStorage.setItem('guestToken', '');
                                navigate('/login', {
                                    state: {
                                        from: {
                                            pathname: location.pathname,
                                            state: location.state,
                                        },
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            </MuiDrawer>
        </>
    );
};

export default Drawer;
