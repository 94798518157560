import { Suspense, lazy, useEffect, useState } from 'react';
import Footer from '../../../screens/Landing/Footer';
import {
    CTAContainer,
    HeaderWrapper,
    LandingBody,
    LeftSection,
    PageContainer,
    SectionWrapper,
} from '../../../screens/Landing/styles';
// import { CheckApplePayAvailability } from '../../Experiments/CheckApplePayAvailability';
import LandingSection from '../../../screens/Landing/LandingSection';
import theme from '../../../theme';
import { heroGradient1 } from '../../../util/styleConstants';
import { BodyBold, SectionHeader, SectionSubtitle } from '../../Text';
import SectionStickyHeaderLogo from '../elements/SectionStickyHeaderLogo';
import { VariationCallToAction } from '../elements/VariationCallToAction';
import { VariationCallToActionSmall } from '../elements/VariationCallToActionSmall';
import { VariationDescription } from '../elements/VariationDescription';
import { VariationHeader } from '../elements/VariationHeader';
import {
    MAIN_VISUAL_DIMENSIONS,
    VariationMainVisual,
} from '../elements/VariationMainVisual';
import { VariationSecondaryVisual } from '../elements/VariationSecondaryVisual';
import {
    AnimatedSectionContainer,
    AnimatedSectionContainerInner,
} from './styles';
import { PageVariation, SubAppInfo } from './types';
import useMobileDetect from './useMobileDetect';
import useWindowSize from './useWindowSize';
import RatingAndReviews from './components/RatingAndReviews';

const SubAppBranchWrapper = lazy(
    () => import('./components/SubAppBranchWrapper')
);

const __svGatorDir = '../../../assets/animations/svGator';
const ReportsDemo = lazy(() => import(`${__svGatorDir}/ReportsDemo`));
const FoldersDemo = lazy(() => import(`${__svGatorDir}/FoldersDemo`));
const OcrDemo = lazy(() => import(`${__svGatorDir}/OcrDemo`));
const EmailDemo = lazy(() => import(`${__svGatorDir}/EmailDemo`));
const QuickbooksDemo = lazy(() => import(`${__svGatorDir}/QuickbooksSync`));
const TextingDemo = lazy(() => import(`${__svGatorDir}/Texting`));
const ConstructionLandingTrack = lazy(
    () => import(`${__svGatorDir}/ConstructionLandingTrack`)
);
const ConstructionLandingWriteOffs = lazy(
    () => import(`${__svGatorDir}/ConstructionLandingWriteOffs`)
);
const ConstructionLandingEstimate = lazy(
    () => import(`${__svGatorDir}/ConstructionLandingEstimate`)
);

export enum SectionAnimation {
    REPORTS = 'reports',
    FOLDERS = 'folders',
    OCR = 'ocr',
    EMAIL = 'email',
    QUICKBOOKS = 'quickbooks',
    TEXTING = 'texting',
    CON_BIDS = 'con_bids',
    CON_TRACK = 'con_track',
    CON_WRITEOFFS = 'con_writeoffs',
}

const DEMO_ANIMATIONS: {
    [key in SectionAnimation]: JSX.Element;
} = {
    [SectionAnimation.REPORTS]: <ReportsDemo />,
    [SectionAnimation.FOLDERS]: <FoldersDemo />,
    [SectionAnimation.OCR]: <OcrDemo />,
    [SectionAnimation.EMAIL]: <EmailDemo />,
    [SectionAnimation.QUICKBOOKS]: <QuickbooksDemo />,
    [SectionAnimation.TEXTING]: <TextingDemo />,
    [SectionAnimation.CON_BIDS]: <ConstructionLandingEstimate />,
    [SectionAnimation.CON_TRACK]: <ConstructionLandingTrack />,
    [SectionAnimation.CON_WRITEOFFS]: <ConstructionLandingWriteOffs />,
};

const renderSections = (sections: any) => {
    if (!sections) return null;

    return (
        <AnimatedSectionContainer>
            <AnimatedSectionContainerInner>
                {sections.map(
                    (
                        section: {
                            animation: SectionAnimation;
                            header: string;
                            subheader: string;
                        },
                        i: number
                    ) => {
                        const isRight = i % 2 === 0;
                        return (
                            <LandingSection
                                key={`section-${i}`}
                                mode={isRight ? 'right' : 'left'}
                                header={section.header}
                                section={section.subheader}
                                asset={
                                    <div style={{ width: 400, height: 400 }}>
                                        {!process.isServer ? (
                                            <Suspense>
                                                {
                                                    DEMO_ANIMATIONS[
                                                        section.animation as SectionAnimation
                                                    ]
                                                }
                                            </Suspense>
                                        ) : null}
                                    </div>
                                }
                            />
                        );
                    }
                )}
            </AnimatedSectionContainerInner>
        </AnimatedSectionContainer>
    );
};

const renderMainVisual = (props: any, isMobile: boolean) => {
    if (props.baseName.includes('Home')) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 40,
                }}
            >
                <div
                    style={{
                        borderRadius: 16,
                        backgroundColor: isMobile ? '#EEE7DD' : 'unset',
                        width:
                            props.mainVisual.type === 'ImageElementComponent' &&
                            props.mainVisual.width
                                ? props.mainVisual.width
                                : MAIN_VISUAL_DIMENSIONS.width,
                        height: isMobile
                            ? 250
                            : props.mainVisual.type ===
                                  'ImageElementComponent' &&
                              props.mainVisual.height
                            ? props.mainVisual.height
                            : MAIN_VISUAL_DIMENSIONS.height,
                        position: 'relative',
                        marginTop: isMobile ? 30 : 0,
                    }}
                >
                    {!process.isServer &&
                        (isMobile ? (
                            <div
                                style={{
                                    overflow: 'hidden',
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    alignSelf: 'center',
                                }}
                            >
                                <VariationMainVisual
                                    props={props.mainVisual}
                                    isMobile={isMobile}
                                />
                            </div>
                        ) : (
                            <div style={{ marginLeft: 75 }}>
                                <VariationMainVisual
                                    props={props.mainVisual}
                                    isMobile={isMobile}
                                />
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 40,
            }}
        >
            <div
                style={{
                    borderRadius: theme.roundness,
                    overflow: 'hidden',
                    width:
                        props.mainVisual.type === 'ImageElementComponent' &&
                        props.mainVisual.width
                            ? props.mainVisual.width
                            : MAIN_VISUAL_DIMENSIONS.width,
                    height:
                        props.mainVisual.type === 'ImageElementComponent' &&
                        props.mainVisual.height
                            ? props.mainVisual.height
                            : MAIN_VISUAL_DIMENSIONS.height,
                }}
            >
                {!process.isServer && (
                    <VariationMainVisual
                        props={props.mainVisual}
                        isMobile={isMobile}
                    />
                )}
            </div>
        </div>
    );
};

export const Variation = (props: PageVariation) => {
    const currentDevice = useMobileDetect();
    const [, innerWidth] = useWindowSize();

    const [subApps] = useState<SubAppInfo[]>(props.subApps || []);
    const [headerMarginTop, setHeaderMarginTop] = useState<string | number>(
        '0px'
    );
    const [isMobile, setIsMobile] = useState<boolean>(currentDevice.isMobile());
    const [scrollableHeader, setScrollableHeader] = useState<
        boolean | undefined
    >(props.scrollableHeader);

    useEffect(() => {
        setIsMobile(currentDevice.isMobile());
        if (currentDevice.isMobile() && props.scrollableHeader) {
            setScrollableHeader(false);
        } else {
            setScrollableHeader(props.scrollableHeader);
        }
    }, [currentDevice, innerWidth, props.scrollableHeader]);

    if (innerWidth === undefined && props.baseName.includes('Home')) {
        return null;
    }

    const renderHeader = () => (
        <SectionStickyHeaderLogo
            expName={props.variationName}
            pageId={props.baseName}
            headerMode={props.headerMode}
            style={props.headerStyle}
            headerLogo={props.headerLogo}
            scrollableHeader={scrollableHeader}
            setHeaderMarginTop={setHeaderMarginTop}
            headerWatermark={props.headerWatermark}
            headerLeafFull={props.headerLeafFull}
            headerLeafPath={props.headerLeafPath}
            slogan={props.slogan}
            isMobile={isMobile}
        >
            <>
                {scrollableHeader &&
                props.tabCallToAction &&
                headerMarginTop === `${window.innerHeight * 0.4}px` ? (
                    <VariationCallToActionSmall
                        {...props.tabCallToAction}
                        baseName={props.baseName}
                        variationName={props.variationName}
                        isMobile={isMobile}
                    />
                ) : props.tabCallToAction && !scrollableHeader ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        {props.tabLoginButton && (
                            <VariationCallToActionSmall
                                {...props.tabLoginButton}
                                baseName={props.baseName}
                                variationName={props.variationName}
                                isMobile={isMobile}
                            />
                        )}
                        {!isMobile && (
                            <VariationCallToActionSmall
                                {...props.tabCallToAction}
                                baseName={props.baseName}
                                variationName={props.variationName}
                                isMobile={isMobile}
                            />
                        )}
                    </div>
                ) : null}
            </>
        </SectionStickyHeaderLogo>
    );

    const renderTopSection = () => (
        <PageContainer
            style={{
                marginTop: headerMarginTop,
                marginBottom: props.baseName.includes('Home') ? 20 : 0,
                scrollBehavior: 'smooth',
            }}
        >
            <SectionWrapper style={{ backgroundImage: heroGradient1 }}>
                <HeaderWrapper>
                    <LeftSection>
                        <SectionHeader>
                            <VariationHeader
                                props={props.header}
                                isMobile={isMobile}
                            />
                        </SectionHeader>
                        <SectionSubtitle style={{ marginBottom: 40 }}>
                            <VariationDescription
                                props={props.description}
                                isMobile={isMobile}
                            />
                        </SectionSubtitle>
                        <CTAContainer>
                            <VariationSecondaryVisual
                                {...props.secondaryVisual}
                            />
                            <VariationCallToAction
                                {...props.callToAction}
                                baseName={props.baseName}
                                variationName={props.variationName}
                                isMobile={isMobile}
                            />
                        </CTAContainer>
                    </LeftSection>
                    {renderMainVisual(props, isMobile)}
                </HeaderWrapper>
            </SectionWrapper>
        </PageContainer>
    );

    const renderSubApps = () => {
        if (subApps.length === 0) return null;

        return (
            <AnimatedSectionContainer
                style={{ paddingBottom: 40 }}
                marginTop={50}
                marginBottom={0}
            >
                <AnimatedSectionContainerInner>
                    {subApps.map((subApp, index) => (
                        <SubAppBranchWrapper
                            key={`subapp-${index}`}
                            subApp={subApp}
                            isMobile={isMobile}
                            webUrl="/"
                        />
                    ))}
                </AnimatedSectionContainerInner>
            </AnimatedSectionContainer>
        );
    };

    const renderTitle = () => {
        if (!props.title) return null;

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 50,
                }}
            >
                <BodyBold
                    style={{
                        fontSize: isMobile ? 32 : 40,
                        alignSelf: 'center',
                        textAlign: 'center',
                    }}
                >
                    {props.title}
                </BodyBold>
            </div>
        );
    };

    const renderFinalCTA = () => {
        if (!props.finalCallToActionHeader && !props.finalCallToAction)
            return null;

        return (
            <SectionWrapper
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#F5F3E',
                    ...(props.finalCallToActionHeader
                        ? { padding: '200px 0' }
                        : {}),
                }}
            >
                {props.finalCallToActionHeader && (
                    <SectionHeader style={{ marginBottom: 80 }}>
                        Get Started
                    </SectionHeader>
                )}
                {props.finalCallToAction && (
                    <VariationCallToAction
                        {...props.finalCallToAction}
                        isFooter
                        baseName={props.baseName}
                        variationName={props.variationName}
                        isMobile={isMobile}
                    />
                )}
            </SectionWrapper>
        );
    };

    return (
        <>
            {renderHeader()}
            {/* <CheckApplePayAvailability landingPageId={'todo'} /> */}
            {renderTopSection()}
            <LandingBody>
                {props.howItWorks.component}
                {props.trustedBy.component}
                {props.sectionsTitle?.component}
                {renderTitle()}
                {renderSubApps()}
                {renderSections(props.sections)}
                {props.pricing.component}
                {props.reviews.component}
                {props.reviewsVertical.component}
                {props.singlePricing.component}
                {renderFinalCTA()}
            </LandingBody>
            {props.ratingAndReviews && (
                <RatingAndReviews
                    ratingAndReviews={props.ratingAndReviews}
                    isMobile={isMobile}
                />
            )}
            <Footer
                mode={'normal'}
                hideFooterLinks={[]}
                footerData={props.footerData}
                isMobile={isMobile}
                baseName={props.baseName}
                variationName={props.variationName}
            />
        </>
    );
};
