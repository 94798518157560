import { useQuestionaire } from '../QuestionaireProvider';
import { BodyBold } from '../../../../components/Text';
import { onWebAppCostEstimationBookingLinkStep } from '../../../../tracking/trackers';

import {
    Container,
    TitleContainer,
    StyledTextArea,
    ButtonsContainer,
    ContinueButton,
} from './styles';

const StepDescription = () => {
    const {
        setStep,
        setPrompt,
        prompt,
        generateQuestions,
        submitProgressToForm,
        formId,
    } = useQuestionaire();

    const handleContinue = async () => {
        if (prompt?.trim()) {
            await submitProgressToForm({
                project_description: prompt,
            });
            onWebAppCostEstimationBookingLinkStep({
                step: 'project-description',
                form_id: formId || 0,
                metadata: {
                    project_description: prompt,
                },
            });
            setStep('step-questions');
            await generateQuestions();
        }
    };

    return (
        <Container>
            <TitleContainer>
                <BodyBold style={{ fontSize: 24 }}>Describe Project</BodyBold>
            </TitleContainer>

            <StyledTextArea
                value={prompt ?? ''}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Tell us about what you want us to work on, e.g. 'Remodel my bathroom'. Any dimensions you can include would be helpful."
            />

            <ButtonsContainer>
                <ContinueButton
                    variant="contained"
                    color="secondary"
                    disabled={!prompt?.trim()}
                    onClick={handleContinue}
                >
                    Continue
                </ContinueButton>
            </ButtonsContainer>
        </Container>
    );
};

export default StepDescription;
