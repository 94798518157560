import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { onWebAppPoolingRequest } from '../../tracking/trackers';
import {
    IAccountMember,
    IGetMembersResponse,
    IMemberAccessResponse,
} from './types';

export default class ShareService {
    public static getMembers(token: string): Promise<IGetMembersResponse> {
        onWebAppPoolingRequest({ url: 'getMembers-share' });
        return axios
            .post(
                '/receipts/share/members/get',
                {},
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then((value: AxiosResponse<IGetMembersResponse>) => value.data);
    }

    public static setMemberAccess(
        token: string,
        member: IAccountMember
    ): Promise<IMemberAccessResponse> {
        return axios
            .post(
                '/receipts/share/members/access',
                {
                    ...member,
                    tags: member.tags?.map((tag) => tag.tag),
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then((value: AxiosResponse<IMemberAccessResponse>) => value.data);
    }

    public static resendMemberTokenEmail(
        token: string,
        token_id: string | number
    ): Promise<IMemberAccessResponse> {
        return axios
            .post(
                '/receipts/share/members/resend-email',
                {
                    token_id,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then((value: AxiosResponse<IMemberAccessResponse>) => value.data);
    }
}
