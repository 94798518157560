import { useLocation, useNavigate } from 'react-router-dom';
import {
    ContentPaste,
    FolderOpen,
    Home,
    IosShare,
    PictureAsPdf,
} from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import logo from '../../assets/logoWhite.svg';
import theme from '../../theme';
import {
    onMobileChargeFinderClickCTA,
    onWebAppClickExport,
    onWebAppClickUncategorizedTab,
    onWebAppSelectFromSearch,
} from '../../tracking/trackers';
import LeafForHeader from '../../assets/leafForHeader.svg';
import Devices from '../../util/devices';
import { SearchInput } from '../Inputs';
import { ALL_DOCUMENTS, HEADER_HEIGHT } from '../../util/constants';
import { CARD_DIMENSIONS } from '../FolderCard/styles';
import { Body, OnboardingSubheader } from '../Text';
import FixedLoadingIndicator from '../FixedLoadingIndicator';
import { useIsProUser } from '../../hooks/useIsProUser';
import Drawer from '../Drawer';
import { IReceipt, InboxTab } from '../../services/receipts/types';
import WhiteSave from '../../assets/whiteSave.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useReceiptSearch from '../../screens/Receipts/useReceiptSearch';
import ReceiptListItem from '../../screens/Receipts/ReceiptListItem';
import { IReceiptState } from '../../screens/Receipts';
import { useHandleOutsideClick } from '../../hooks/useHandleOutsideClick';
import { REACT_APP_BASE_URL } from '../../configuration';
import ChargeFinderVariants from '../../screens/Onboarding/ChargeFinder/Components/ChargeFinderVariants';
import useHandleDownload from '../../screens/Report/useHandleDownload';
import { useFolders } from '../../screens/ReceiptsHome/folderContext';
import { RedNumberCircle } from '../../screens/ReceiptsHome/styles';
import { useInbox } from '../../screens/Inbox/context';
import HeaderDropdown from '../HeaderDropdown';
import { HeaderSearchResultsContainer, WebAppHeaderContainer } from './styles';
import HeaderButton from './HeaderButton';

export const HeaderLeafContainer = styled.img`
    position: absolute;
    top: -20px;
    left: 0px;
    z-index: 0;

    @media ${Devices.TABLET} {
        left: 100px;
    }
`;

const Header = ({
    showSearch,
    showExport,
    showDrawer,
    showInbox,
    forceShowLogo,
    showDownload,
    downloadLabel,
    downloadWebUrl,
    folderIds,
    title,
    disableClick,
    navigatePath,
    style,
    maxWidth: maxWidthProp,
    setIsDisplayingSearchHeader,
    onClickDownload,
    searchPlaceholder,
    alignLeft,
}: {
    showSearch?: boolean;
    showExport?: boolean;
    showDrawer?: boolean;
    showInbox?: boolean;
    alignLeft?: boolean;
    forceShowLogo?: boolean;
    showDownload?: boolean;
    downloadLabel?: string;
    downloadWebUrl?: string;
    folderIds?: string[];
    title?: string;
    disableClick?: boolean;
    navigatePath?: string;
    style?: React.CSSProperties;
    maxWidth?: number;
    setIsDisplayingSearchHeader?: (isDisplayingSearchHeader: boolean) => void;
    onClickDownload?: () => void;
    searchPlaceholder?: string;
}) => {
    const searchResultsRef = useRef<HTMLDivElement>(null);
    const headerButtonRef = useRef<HTMLDivElement>(null);
    const { state } = useLocation() as { state: IReceiptState | undefined };
    const location = useLocation() as any;
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const { downloadImagesFromDesktopApp, loading } = useHandleDownload();
    const { isProUser } = useIsProUser();
    const { numCardColumns } = useFolders();
    const screenIsSmall = width < 800;
    const screenIsVerySmall = width < 500;

    const {
        search,
        setSearch,
        searchResults,
        isSearching,
        handleSearch,
        searchIsComplete,
    } = useReceiptSearch(undefined, ALL_DOCUMENTS, ALL_DOCUMENTS, 'home');

    const { inboxReceipts } = useInbox();
    const inboxCount = inboxReceipts.length;

    const [showExportMenu, setShowExportMenu] = useState<boolean>(false);

    useEffect(() => {
        if (setIsDisplayingSearchHeader) {
            setIsDisplayingSearchHeader(searchResults.length > 0);
        }
    }, [searchResults.length]);

    const maxWidth =
        maxWidthProp || CARD_DIMENSIONS.width * (numCardColumns + 2);
    const maxSearchWidth = screenIsVerySmall
        ? Math.max(140, width * 0.4)
        : screenIsSmall
        ? width * 0.5
        : CARD_DIMENSIONS.width * 3 - CARD_DIMENSIONS.gap * 2;
    const searchDropdownContainer = screenIsSmall
        ? width * 0.9
        : maxSearchWidth;

    const navigateToInbox = () => {
        navigate('/inbox', {
            state: { tab: InboxTab.DESKTOP },
        });
    };

    const hideHeaderTextOnButtons = width < 1000;

    const onSetReceiptState = (receipt: IReceipt) => {
        if (search.length > 0) {
            onWebAppSelectFromSearch({
                folder: ALL_DOCUMENTS,
                query: search,
                source: 'home',
            });
        }
        window.open(
            `${REACT_APP_BASE_URL}/receipts/${receipt.receipt_id}`,
            '_self'
        );
    };

    useHandleOutsideClick(searchResultsRef, () => {
        setSearch('');
    });

    return (
        <>
            <WebAppHeaderContainer
                alignLeft={alignLeft}
                style={{
                    boxShadow: theme.headerShadow,
                    overflow: 'visible',
                    position: 'fixed',
                    ...style,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        position: 'relative',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        maxWidth,
                    }}
                >
                    <HeaderLeafContainer src={LeafForHeader} alt="" />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                            zIndex: 1,
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: HEADER_HEIGHT,
                            }}
                        >
                            {showDrawer ? (
                                <div style={{ marginTop: 2, marginRight: 10 }}>
                                    <Drawer />
                                </div>
                            ) : null}
                            <div
                                onClick={() => {
                                    if (disableClick) return;
                                    navigate(navigatePath ?? '/app');
                                }}
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    height: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                {screenIsSmall && !forceShowLogo ? (
                                    <Home
                                        style={{
                                            color: theme.colors.white,
                                            marginRight: 20,
                                        }}
                                    />
                                ) : (
                                    <img src={logo} alt="logo" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {showSearch ? (
                            <div
                                style={{
                                    position: 'relative',
                                    width: maxSearchWidth,
                                }}
                            >
                                <SearchInput
                                    search={search}
                                    setSearch={setSearch}
                                    isSearching={isSearching}
                                    searchHeight={HEADER_HEIGHT * 0.6}
                                    style={{
                                        width: maxSearchWidth,
                                        maxWidth: maxSearchWidth,
                                    }}
                                    onSubmit={handleSearch}
                                    placeholder={
                                        screenIsVerySmall
                                            ? 'Search...'
                                            : searchPlaceholder || undefined
                                    }
                                />
                                <HeaderSearchResultsContainer
                                    maxheight={height - HEADER_HEIGHT}
                                    width={searchDropdownContainer}
                                    ref={searchResultsRef}
                                    style={{
                                        left:
                                            (maxSearchWidth -
                                                searchDropdownContainer) /
                                            2,
                                    }}
                                >
                                    {searchResults.map((r) => (
                                        <ReceiptListItem
                                            key={r.receipt_id}
                                            receipt={r}
                                            onSetReceiptState={
                                                onSetReceiptState
                                            }
                                            selectedReceiptId={
                                                state?.receipt?.receipt_id
                                            }
                                        />
                                    ))}
                                    {!isSearching &&
                                    searchIsComplete &&
                                    searchResults.length === 0 ? (
                                        <Body
                                            style={{
                                                color: theme.colors.blue60,
                                                margin: 20,
                                                fontWeight: '700',
                                                fontSize: 16,
                                                textAlign: 'center',
                                            }}
                                        >
                                            No results
                                        </Body>
                                    ) : null}
                                </HeaderSearchResultsContainer>
                            </div>
                        ) : title ? (
                            <OnboardingSubheader
                                style={{
                                    color: theme.colors.white,
                                    fontWeight: '600',
                                }}
                            >
                                {title}
                            </OnboardingSubheader>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {showExport ? (
                            <div
                                style={{
                                    position: 'relative',
                                    height: HEADER_HEIGHT,
                                }}
                                ref={headerButtonRef}
                            >
                                <HeaderButton
                                    onClick={() => {
                                        setShowExportMenu((prev) => !prev);
                                    }}
                                    text={
                                        hideHeaderTextOnButtons
                                            ? ''
                                            : isProUser
                                            ? 'Reports'
                                            : 'Export'
                                    }
                                    icon={
                                        <IosShare
                                            style={{
                                                color: theme.colors.white,
                                            }}
                                        />
                                    }
                                />
                                <HeaderDropdown
                                    insideClickRefs={[headerButtonRef]}
                                    visible={showExportMenu}
                                    setVisible={setShowExportMenu}
                                    items={[
                                        isProUser
                                            ? {
                                                  name: 'Reports',
                                                  onClick: () => {
                                                      onWebAppClickExport({
                                                          source:
                                                              location.pathname ===
                                                              '/'
                                                                  ? 'home'
                                                                  : 'folder',
                                                      });
                                                      navigate('/reports');
                                                  },
                                                  Icon: ContentPaste,
                                              }
                                            : null,
                                        folderIds
                                            ? {
                                                  name: 'Download Images (PDF)',
                                                  onClick: () => {
                                                      if (loading) return;
                                                      downloadImagesFromDesktopApp(
                                                          'pdf',
                                                          folderIds
                                                      );
                                                  },
                                                  Icon: PictureAsPdf,
                                              }
                                            : null,
                                        folderIds
                                            ? {
                                                  name: 'Download Images (Zip)',
                                                  onClick: () => {
                                                      if (loading) return;
                                                      downloadImagesFromDesktopApp(
                                                          'zip',
                                                          folderIds
                                                      );
                                                  },
                                                  Icon: FolderOpen,
                                              }
                                            : null,
                                    ]}
                                />
                            </div>
                        ) : null}
                        {showInbox ? (
                            <div
                                onClick={() => {
                                    onWebAppClickUncategorizedTab({});
                                    navigateToInbox();
                                }}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {inboxCount ? (
                                    <RedNumberCircle
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: -10,
                                        }}
                                    >
                                        <Body
                                            style={{
                                                color: theme.colors.white,
                                                fontWeight: '700',
                                                fontSize:
                                                    inboxCount >= 100 ? 10 : 14,
                                            }}
                                        >
                                            {inboxCount}
                                        </Body>
                                    </RedNumberCircle>
                                ) : null}
                                <img
                                    src={WhiteSave}
                                    alt=""
                                    width={30}
                                    height={30}
                                />
                            </div>
                        ) : null}
                        {showDownload ? (
                            <>
                                <Button
                                    onClick={() => {
                                        onMobileChargeFinderClickCTA({
                                            cta: 'download-app',
                                        });
                                        if (onClickDownload) {
                                            onClickDownload();
                                            return;
                                        }
                                        navigate('/redirect-download', {
                                            state: {
                                                alternativeWebUrl:
                                                    downloadWebUrl,
                                                customData: {
                                                    cta: 'downlaod-app',
                                                    label: downloadLabel,
                                                    source: 'header',
                                                    expName:
                                                        ChargeFinderVariants.name,
                                                },
                                            },
                                        });
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                        borderRadius: 25,
                                    }}
                                >
                                    <Body
                                        style={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                            fontSize: '0.7em',
                                        }}
                                    >
                                        {downloadLabel || 'Download App'}
                                    </Body>
                                </Button>
                            </>
                        ) : null}
                    </div>
                </div>
            </WebAppHeaderContainer>
            <div style={{ marginBottom: HEADER_HEIGHT }} />
            {loading ? <FixedLoadingIndicator /> : null}
        </>
    );
};

export default Header;
