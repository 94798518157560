import { Button, Container } from '@mui/material';
import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import theme from '../../theme';
import {
    onWebAppCostEstimationClickDownload,
    onWebAppCostEstimationLoadExperiment,
    onWebAppCostEstimationLoadVariant,
    onWebAppCostEstimationTakePicture,
    onWebAppCostEstimationViewPaywall,
    onWebAppLoadPaymentSuccessScreen,
    onWebAppPaymentAttempt,
} from '../../tracking/trackers';
import { useQuery } from '../../util/hooks';
import { useAuth } from '../../providers/AuthProvider';
import { getOS } from '../../util/isIOS';
import { STRIPE_PRICE_CE_BASE_20_MONTHLY } from '../Onboarding/Payment/CheckoutForm';
import Check from '../../assets/confirm.svg';
import multiChannelEvents from '../../tracking/multiChannelEvents';
import Header from '../../components/Header';
import CEPaymentVariants from '../Onboarding/Payment/components/CEPaymentVariants';
import AddDescription from './components/AddDescription';
import FinalStep from './components/FinalStep';
import SurveyStep from './components/SurveyStep';
import TakePhoto from './components/TakePhoto';
import {
    getLineItemAmount,
    useCostEstimator,
} from './components/CostEstimatorProvider';
import StepSaveName from './components/StepSaveName';
import StepSaveFolder from './components/StepSaveFolder';
// @ts-ignore

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

const CostEstimatorAppStoreUrl =
    'https://apps.apple.com/us/app/simplywise-cost-estimator/id6670619664';

const CostEstimator = ({ source }: { source: string }) => {
    const query = useQuery();
    const subscriptionSuccess = Boolean(query.get('subscription-success'));
    const { user, isSubscribed } = useAuth();
    const onboardingComplete =
        subscriptionSuccess &&
        isSubscribed &&
        user?.subscription_tier === STRIPE_PRICE_CE_BASE_20_MONTHLY;

    const tier = (query.get('tier') as 'base' | 'pro' | null) || 'base';
    const alreadySubscribed = query.get('alreadySubscribed') === 'true';
    const paymentMethod = query.get('method');

    const imageInputRef = useRef<HTMLInputElement>(null);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [showPaywallModal, setShowPaywallModal] = useState<boolean>(false);
    const [_, setNewInstruction] = useState<string>('');
    const {
        step,
        setStep,
        imageIds,
        setImageIds,
        imageUris,
        setImageUris,
        userType,
        questionsToAnswer,
        currentQuestionIndex,
        classifyProject,
        setDrafting,
        generateSmallEstimate,
        generateEstimateSingle,
        lineItems,
        estimateName,
    } = useCostEstimator();
    const expName = CEPaymentVariants.name;
    const { selectVariant } = useExperiment(expName);
    const abVariant = selectVariant(CEPaymentVariants.variants);

    const estimateTotal =
        lineItems?.reduce(
            (acc, l) => acc + getLineItemAmount(l, lineItems),
            0
        ) || 0;

    const downloadText = useMemo(() => {
        if (!userType) return ['', ''];
        if (
            userType === 'landlord' ||
            userType === 'homeowner' ||
            userType === 'other'
        ) {
            const rand = Math.floor(Math.random() * 2);
            if (rand === 0)
                return [
                    'Stay on budget with AI',
                    'Track every project expense with the #1 construction receipt tracker',
                ];
            return [
                'Keep your project budget on track',
                'Track every project expense with the #1 construction receipt tracker',
            ];
        }
        const rand = Math.floor(Math.random() * 4);
        if (rand === 0)
            return [
                'Make more money on each job',
                'Track expenses by job with the #1 construction receipt tracker',
            ];

        if (rand === 1)
            return [
                'Turn estimates into earnings',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        if (rand === 2)
            return [
                'Turn estimates into profits',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        return [
            'Use AI to automate more admin work',
            'Track every project expense with the #1 construction receipt tracker',
        ];
    }, [userType]);

    useEffect(() => {
        onWebAppCostEstimationLoadExperiment({
            exp: '2025-01-16',
        });
    }, []);

    useEffect(() => {
        if (!downloadText[0].length) return;
        onWebAppCostEstimationLoadVariant({
            variant: downloadText[0],
        });
    }, [downloadText]);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (
            !['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(
                _file.type
            )
        )
            return;

        resizeFile(_file)
            .then((resizedFile) => {
                const imageUrl = URL.createObjectURL(resizedFile);
                setImageUris([...(imageUris || []), imageUrl]);
                PublicService.uploadImage(resizedFile)
                    .then((res) => {
                        const newImageId = res.data.image_ids[0];
                        const updatedImageIds = [
                            ...(imageIds || []),
                            newImageId,
                        ];
                        setImageIds(updatedImageIds);
                        onWebAppCostEstimationTakePicture({
                            image_ids: updatedImageIds,
                            exp_name: 'aug-25-remove-paywall',
                        });
                    })
                    .catch((err) => {
                        console.error('Upload error:', err);
                    });
            })
            .catch((err) => {
                console.error('Resize error:', err);
            });

        setStep('1');
    };
    const onAnswerQuestion = (c: string, isCustom: boolean = false) => {
        setNewInstruction((prev) => {
            const question = questionsToAnswer[currentQuestionIndex].question;
            if (!prev?.length) {
                prev = 'ANSWERS:\n';
            }
            const n = prev + `- ${question} ${c}\n`;
            if (currentQuestionIndex === questionsToAnswer.length - 1) {
                classifyProject(n).then((classification) => {
                    if (classification === 'small') {
                        generateSmallEstimate(
                            n,
                            estimateName || 'Project'
                        ).then(() => {});
                    } else {
                        generateEstimateSingle(n).then(() => {});
                    }
                });
            }
            setDrafting(false);
            return n;
        });
    };

    const downloadApp = () => {
        onWebAppCostEstimationClickDownload();
        const os = getOS();
        setTimeout(() => {
            if (os === 'macos' || os === 'ios') {
                window.location.href = CostEstimatorAppStoreUrl;
                return;
            }
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'cost-estimator',
                    initial_landing: query.get('initial-landing') || '',
                    experience: 'cost-estimator',
                }}
                branchAppStoreUrl={CostEstimatorAppStoreUrl}
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.costestimator&listing=estimator"
                black
                appId="com.simplywise.costestimator"
            />
        ) : null;
    }, []);

    const userTypeList = useMemo(
        () =>
            [
                {
                    text: 'Contractor',
                    value: 'contractor',
                },
                {
                    text: 'Landlord',
                    value: 'landlord',
                },
                {
                    text: 'Homeowner',
                    value: 'homeowner',
                },
            ]
                .map((value) => ({
                    value,
                    sort: Math.random(),
                }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value),
        []
    );

    const ctaType = useMemo(() => {
        return 'stripe';
    }, []);

    useEffect(() => {
        if (isCompleted && userType) {
            // setTimeout(() => setShowFeedbackModal(true), 12000);
        }
    }, [isCompleted, userType]);

    useEffect(() => {
        if (showPaywallModal) {
            onWebAppCostEstimationViewPaywall();
        }
    }, [showPaywallModal]);

    useEffect(() => {
        if (onboardingComplete) onOnboardingComplete('default');
    }, [onboardingComplete]);

    const onOnboardingComplete = (cohort: string) => {
        onWebAppLoadPaymentSuccessScreen({
            tier,
            expName,
            cohortName: abVariant,
        });

        onWebAppPaymentAttempt({
            method:
                paymentMethod === 'manual-card'
                    ? 'manual-card'
                    : paymentMethod === 'apple-pay'
                    ? 'apple-pay'
                    : paymentMethod === 'paypal'
                    ? 'paypal'
                    : 'unknown',
            status: 'success',
            couponApplied: query.get('couponApplied') === 'true',
            experiment: expName,
            cohort: abVariant,
        });

        multiChannelEvents.subscribe();
    };

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100vh',
                fontFamily: 'Inter, sans-serif',
                width: '100%',
            }}
        >
            {/* rendering it here to avoid lazy loading down the road */}
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            {/* Header Section */}
            {source === 'dashboard' ? (
                <Header
                    // showSearch
                    // showExport // TODO: add export, Inbox, and search functionality
                    // showDrawer
                    // showInbox
                    // searchPlaceholder="Search your estimates..."
                    // folderIds={folders.map((f) => f.tag_id)}
                    navigatePath="/app/cost-estimator"
                />
            ) : (
                <SectionStickyHeaderLogo
                    expName={'cost-estimator-page'}
                    pageId={'cost-estimator-page'}
                    headerMode={undefined}
                    onClick={() => {
                        downloadApp();
                    }}
                >
                    <div></div>
                </SectionStickyHeaderLogo>
            )}
            <input
                placeholder="Upload Image"
                type="file"
                accept="image/*"
                // capture="environment"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />

            {onboardingComplete ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'column',
                        padding: '40px',
                    }}
                >
                    <div>Thank you for subscribing!</div>
                    <div
                        style={{
                            fontSize: '15px',
                            margin: 10,
                            textAlign: 'center',
                        }}
                    >
                        {alreadySubscribed
                            ? 'You are already subscribed'
                            : 'Your subscription has been confirmed.'}
                        <br />
                        Please download{' '}
                        <strong>SimplyWise: Cost Estimator</strong>.
                    </div>
                    {/* <ReportSuccessLoad cohort={'default'} onLoad={onLoad} /> */}
                    <img
                        src={Check}
                        alt=""
                        width={80}
                        height={80}
                        style={{ marginTop: 20 }}
                    />
                    {downloadBadges}
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'column',
                        padding: '40px 10px',
                        height: '100vh',
                    }}
                >
                    {step === '0' ? (
                        <TakePhoto
                            imageInputRef={imageInputRef}
                            source={source}
                        />
                    ) : step === '1' ? (
                        <AddDescription />
                    ) : step === 'save-name' ? (
                        <StepSaveName />
                    ) : step === 'save-folder' ? (
                        <StepSaveFolder />
                    ) : (
                        <>
                            {userType && questionsToAnswer ? (
                                <FinalStep
                                    source={source}
                                    setShowPaywallModal={setShowPaywallModal}
                                    showPaywallModal={showPaywallModal}
                                    setShowFeedbackModal={setShowFeedbackModal}
                                    showFeedbackModal={showFeedbackModal}
                                    downloadText={downloadText}
                                    downloadApp={downloadApp}
                                    onAnswerQuestion={onAnswerQuestion}
                                    estimateTotal={estimateTotal}
                                    ctaType={ctaType}
                                    ctaAction={'CostEstimatorSignUp'}
                                />
                            ) : (
                                <SurveyStep userTypeList={userTypeList} />
                            )}
                        </>
                    )}
                </div>
            )}
        </Container>
    );
};

export default CostEstimator;
