import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalculateIcon from '@mui/icons-material/Calculate';
import theme from '../../../theme';
import { FormHeader, StyledCard, IconBox } from './styles';

interface AppSelectionModalProps {
    onSelectApp: (app: 'receipt-app' | 'cost-estimator') => void;
}

const AppSelectionModal = ({ onSelectApp }: AppSelectionModalProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                p: 4,
            }}
        >
            <Box sx={{ maxWidth: '900px', width: '100%' }}>
                <FormHeader sx={{ marginBottom: 5 }}>
                    Welcome to SimplyWise! 👋
                </FormHeader>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                        gap: 4,
                        px: { xs: 2, md: 4 },
                    }}
                >
                    <StyledCard onClick={() => onSelectApp('receipt-app')}>
                        <CardContent
                            sx={{
                                p: 4,
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <IconBox
                                className="icon-box"
                                sx={{ mx: { xs: 'auto', md: 0 } }}
                            >
                                <ReceiptIcon
                                    sx={{ color: 'primary400', fontSize: 28 }}
                                />
                            </IconBox>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    mb: 2,
                                    color: theme.colors.blue100,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                Receipt App
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'primaryLight',
                                    lineHeight: 1.6,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                Easily manage and track all your receipts in one
                                place
                            </Typography>
                        </CardContent>
                    </StyledCard>

                    <StyledCard onClick={() => onSelectApp('cost-estimator')}>
                        <CardContent
                            sx={{
                                p: 4,
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <IconBox
                                className="icon-box"
                                sx={{ mx: { xs: 'auto', md: 0 } }}
                            >
                                <CalculateIcon
                                    sx={{ color: 'primary400', fontSize: 28 }}
                                />
                            </IconBox>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    mb: 2,
                                    color: theme.colors.blue100,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                Cost Estimator
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'primaryLight',
                                    lineHeight: 1.6,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                Calculate and forecast your project costs with
                                confidence
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Box>
            </Box>
        </Box>
    );
};

export default AppSelectionModal;
