import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function getImageDimensions(file: string) {
    return new Promise<{ w: number; h: number }>(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = file;
    });
}

/**
 * Converts an HTML element to a PDF document with consistent layout across devices, width fixed to 700
 * @param elementId - The ID of the HTML element to convert
 * @param filename - The filename for the generated PDF (without extension)
 */
export const htmlToPDF = async (elementId: string, filename: string) => {
    const input = document.getElementById(elementId);
    const FIXED_WIDTH = 700;

    if (input) {
        // Clone the element to control dimensions without affecting the live DOM
        const clone = input.cloneNode(true) as HTMLElement;

        // Remove elements with 'no-print' class
        const noPrintElements = clone.getElementsByClassName('no-print');
        while (noPrintElements.length > 0) {
            noPrintElements[0].remove();
        }

        // Fix the width to ensure consistent rendering
        clone.style.width = `${FIXED_WIDTH}px`;
        clone.style.margin = '0';
        clone.style.padding = '0';

        clone.style.position = 'absolute';
        clone.style.left = '-9999px';
        clone.style.top = '-9999px';
        document.body.appendChild(clone);

        const canvas = await html2canvas(clone, {
            backgroundColor: 'white',
            scale: 4,
            logging: false,
            useCORS: true, // Enable cross-origin resource loading (for external images/fonts)
            width: FIXED_WIDTH,
            windowWidth: FIXED_WIDTH,
        });

        // Remove the clone from the document
        document.body.removeChild(clone);

        const jpegDataUrl = canvas.toDataURL('image/jpeg', 0.9);
        const imageSize = await getImageDimensions(jpegDataUrl);
        const actualHeight = imageSize.h / 4;

        // Create a PDF with fixed width and dynamic height to fit all content on one page
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [FIXED_WIDTH, actualHeight],
        });

        pdf.addImage(jpegDataUrl, 'JPEG', 0, 0, FIXED_WIDTH, actualHeight);

        pdf.save(`${filename}.pdf`);
    }
};
