import GalleryIcon from '@mui/icons-material/PhotoLibrary';
import { useQuestionaire } from '../QuestionaireProvider';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import CameraSVG from '../../../../assets/icons/details/camera.svg';
import { BodyBold } from '../../../../components/Text';
import { onWebAppCostEstimationBookingLinkSkipTakePhoto } from '../../../../tracking/trackers';
import theme from '../../../../theme';
import {
    Container,
    ImageContainer,
    CircleContainer,
    TitleContainer,
    ButtonsContainer,
    Description,
    TakePhotoButton,
} from './styles';

const StepTakePhoto = ({
    imageInputRef,
}: {
    imageInputRef: React.RefObject<HTMLInputElement>;
}) => {
    const { width = 768 } = useWindowDimensions();
    const {
        setStep,
        setImageIds,
        submitProgressToForm,
        formId,
        imageUris,
        setImageUris,
        imageIds,
    } = useQuestionaire();
    const circleDimension = width ? Math.min(width * 0.5, 250) : 250;

    const handleSkip = async () => {
        setImageIds([]);
        await submitProgressToForm({
            image_ids: [],
        });
        onWebAppCostEstimationBookingLinkSkipTakePhoto({
            form_id: formId || 0,
        });
        setStep('project-description');
    };

    const handleDeleteImage = async (index: number) => {
        const newImageUris = imageUris?.filter((_, i) => i !== index);
        setImageUris(newImageUris);

        const newImageIds = imageIds?.filter((_, i) => i !== index);
        setImageIds(newImageIds);
        await submitProgressToForm({
            image_ids: newImageIds,
        });
    };

    return (
        <Container>
            {imageUris && imageUris.length > 0 ? (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '1rem',
                        width: '90%',
                        maxWidth: '31.25rem',
                        margin: '2rem auto',
                    }}
                >
                    {imageUris?.map((image, index) => (
                        <div
                            key={index}
                            style={{
                                position: 'relative',
                                aspectRatio: '1',
                                width: '100%',
                            }}
                        >
                            <img
                                src={image}
                                alt={`uploaded-${index}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '0.5rem',
                                }}
                            />
                            <button
                                onClick={() => handleDeleteImage(index)}
                                style={{
                                    position: 'absolute',
                                    top: '0.5rem',
                                    right: '0.5rem',
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    borderRadius: '50%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '0.875rem',
                                    color: '#666',
                                    padding: 0,
                                }}
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <ImageContainer>
                        <CircleContainer dimension={circleDimension}>
                            <img
                                src={CameraSVG}
                                width={150}
                                height={150}
                                alt="camera"
                            />
                        </CircleContainer>
                    </ImageContainer>
                    <TitleContainer>
                        <BodyBold style={{ fontSize: width < 768 ? 22 : 30 }}>
                            Take Pictures
                        </BodyBold>
                    </TitleContainer>
                </>
            )}

            <ButtonsContainer>
                {!imageUris ? (
                    <Description>
                        Take photos of what you would like us to repair or
                        modify
                    </Description>
                ) : (
                    <Description>Please add up to 4 photos</Description>
                )}

                <TakePhotoButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        imageInputRef.current?.click();
                    }}
                    startIcon={<GalleryIcon />}
                    style={{
                        borderRadius: 50,
                        padding: '15px',
                        backgroundColor: theme.colors.primary400,
                        textTransform: 'none',
                        fontSize: '18px',
                        marginBottom: 10,
                    }}
                    disabled={imageUris && imageUris.length >= 4}
                >
                    Add Project Photos
                </TakePhotoButton>

                {imageUris ? (
                    <TakePhotoButton
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setStep('project-description');
                        }}
                        style={{
                            borderRadius: 50,
                            padding: '15px',
                            borderColor: theme.colors.primary400,
                            borderWidth: 2,
                            textTransform: 'none',
                            fontSize: '18px',
                            marginBottom: 10,
                            color: theme.colors.primary400,
                        }}
                    >
                        Continue
                    </TakePhotoButton>
                ) : (
                    <p
                        onClick={handleSkip}
                        style={{
                            color: '#666666',
                            cursor: 'pointer',
                            textAlign: 'center',
                            fontSize: '1.25rem',
                            textDecoration: 'none',
                            transition: 'text-decoration 0.2s ease',
                            marginTop: 0,
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.textDecoration = 'underline';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.textDecoration = 'none';
                        }}
                    >
                        Skip this step
                    </p>
                )}
            </ButtonsContainer>
        </Container>
    );
};

export default StepTakePhoto;
