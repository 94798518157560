import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box } from '@mui/material';
import theme from '../../../theme';

interface CustomAnswerModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (answer: string) => void;
}

const CustomAnswerModal = ({
    open,
    onClose,
    onSubmit,
}: CustomAnswerModalProps) => {
    const [customAnswer, setCustomAnswer] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    // Handle focus when modal opens
    useEffect(() => {
        if (open) {
            // Short delay to ensure modal is fully rendered
            const timeoutId = setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
            return () => clearTimeout(timeoutId);
        }
    }, [open]);

    const handleSubmit = () => {
        if (customAnswer.trim()) {
            onSubmit(customAnswer);
            setCustomAnswer('');
            onClose();
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="custom-answer-modal"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '70%',
                    maxWidth: '400px',
                    bgcolor: '#ffffff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden',
                }}
            >
                <div style={{ padding: '24px 24px 16px' }}>
                    <h3
                        style={{
                            margin: 0,
                            fontSize: '1.25rem',
                            fontWeight: 500,
                            color: '#2C3E50',
                            marginBottom: '8px',
                        }}
                    >
                        Custom Answer
                    </h3>
                    <p
                        style={{
                            margin: 0,
                            fontSize: '0.875rem',
                            color: '#666',
                        }}
                    >
                        Please enter your answer
                    </p>
                </div>

                <div style={{ padding: '0 24px 24px' }}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={customAnswer}
                        onChange={(e) => setCustomAnswer(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '8px 12px',
                            fontSize: '1rem',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSubmit();
                            if (e.key === 'Escape') onClose();
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        borderTop: '1px solid #eee',
                    }}
                >
                    <button
                        onClick={onClose}
                        style={{
                            flex: 1,
                            padding: '12px',
                            border: 'none',
                            borderRight: '1px solid #eee',
                            background: '#f8f9fa',
                            fontSize: '0.875rem',
                            color: theme.colors.error,
                            cursor: 'pointer',
                            transition: 'background-color 0.2s',
                            fontWeight: 'bold',
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        style={{
                            flex: 1,
                            padding: '12px',
                            border: 'none',
                            background: theme.colors.primary400,
                            fontSize: '0.875rem',
                            color: 'white',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            transition: 'background-color 0.2s',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.background =
                                theme.colors.primary400Light;
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.background =
                                theme.colors.primary400;
                        }}
                    >
                        OK
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default CustomAnswerModal;
