import { emitter } from '@marvelapp/react-ab-test';

const experimentIndex = 0;
const CEPaymentVariants = [
    {
        name: 'CE-Payment-Exp-Feb11',
        variants: {
            A: '20-per-month',
        },
    },
];

emitter.defineVariants(
    CEPaymentVariants[experimentIndex].name,
    Object.keys(CEPaymentVariants[experimentIndex].variants)
);

export default CEPaymentVariants[experimentIndex];
