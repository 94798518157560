import { useRef, useState } from 'react';
import {
    KeyboardArrowRight,
    KeyboardArrowDown,
    Calculate,
    Add,
    Remove,
    Delete,
} from '@mui/icons-material';
import { Button, ButtonBase } from '@mui/material';
import theme from '../../../theme';
import { Body, BodyBold } from '../../../components/Text';
import { formatAmount } from '../../../util/formatAmount';
import Ruler from '../../../assets/icons/ruler.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
    onWebAppCostEstimationEditLineItem,
    onWebAppCostEstimationRemoveLineItem,
    onWebAppCostEstimationAddLineItem,
} from '../../../tracking/trackers';
import { useCostEstimator } from './CostEstimatorProvider';
import { getLineItemAmount } from './CostEstimatorProvider';
import { ICELineItem } from './CostEstimatorProvider/types';
const SwipeableItem = ({
    children,
    onDelete,
}: {
    children: React.ReactNode;
    onDelete: () => void;
}) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [swipeX, setSwipeX] = useState(0);
    const startX = useRef(0);
    const currentX = useRef(0);
    const deleteThreshold = -80;
    // Touch Events
    const handleTouchStart = (e: React.TouchEvent) => {
        startX.current = e.touches[0].clientX;
        currentX.current = e.touches[0].clientX;
        setIsSwiping(true);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isSwiping) return;

        const diff = e.touches[0].clientX - startX.current;
        currentX.current = e.touches[0].clientX;

        // Only allow left swipe
        const newX = Math.min(0, diff);
        // Limit maximum swipe
        const limitedX = Math.max(newX, -100);

        setSwipeX(limitedX);
    };

    const handleTouchEnd = () => {
        setIsSwiping(false);

        if (swipeX <= deleteThreshold) {
            onDelete?.();
        }
        setSwipeX(0);
    };
    // Mouse Events
    const handleMouseDown = (e: React.MouseEvent) => {
        startX.current = e.clientX;
        currentX.current = e.clientX;
        setIsSwiping(true);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isSwiping) return;

        const diff = e.clientX - startX.current;
        currentX.current = e.clientX;

        // Only allow left swipe
        const newX = Math.min(0, diff);
        // Limit maximum swipe
        const limitedX = Math.max(newX, -100);

        setSwipeX(limitedX);
    };

    const handleMouseUp = () => {
        setIsSwiping(false);

        if (swipeX <= deleteThreshold) {
            onDelete?.();
        }
        setSwipeX(0);
    };

    // Stop swiping if mouse leaves the element
    const handleMouseLeave = () => {
        if (isSwiping) {
            setIsSwiping(false);
            setSwipeX(0);
        }
    };

    return (
        <div
            style={{
                position: 'relative',
                overflow: 'hidden',
                touchAction: 'pan-y pinch-zoom',
                userSelect: 'none', // Prevent text selection while swiping
            }}
        >
            {/* Delete button background */}
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: 80,
                    backgroundColor: theme.colors.error,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: `translateX(${swipeX + 80}px)`,
                    transition: isSwiping ? 'none' : 'transform 0.2s ease-out',
                }}
            >
                <Delete style={{ color: 'white' }} fontSize="medium" />
            </div>

            {/* Main content */}
            <div
                style={{
                    transform: `translateX(${swipeX}px)`,
                    transition: isSwiping ? 'none' : 'transform 0.2s ease-out',
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </div>
    );
};

const LineItemList = ({
    estimateTotal,
    editingLineItem,
    setEditingLineItem,
    isAddNewItem,
    setIsAddNewItem,
}: {
    estimateTotal: number;
    editingLineItem: (ICELineItem & { index: number }) | undefined;
    setEditingLineItem: (
        lineItem: (ICELineItem & { index: number }) | undefined
    ) => void;
    isAddNewItem: boolean;
    setIsAddNewItem: (isAddNewItem: boolean) => void;
}) => {
    const { width } = useWindowDimensions();
    const [expandedGroups, setExpandedGroups] = useState<{
        [key: string]: boolean;
    }>({
        'Home Wall Painting Planner-labor': true,
        'Home Wall Painting Planner-material': true,
        'Other': true,
    });
    const { lineItems, setLineItems } = useCostEstimator();

    const isLineItemMeasurable = (i: number) => {
        return i === -11;
    };

    const calcGroupTypeTotal = (group: any) => {
        let groupTypeTotal = 0;
        Object.keys(group).forEach((key) => {
            if (Array.isArray(group[key])) {
                group[key].forEach((item: ICELineItem) => {
                    groupTypeTotal += getLineItemAmount(item, lineItems);
                });
            }
        });
        return formatAmount(groupTypeTotal);
    };

    const calcGroupSum = (group: any, subGroup: string) => {
        let groupSum = 0;
        if (Array.isArray(group[subGroup])) {
            group[subGroup].forEach((item: ICELineItem) => {
                groupSum += getLineItemAmount(item, lineItems);
            });
        }
        return formatAmount(groupSum);
    };

    const groupedLineItems = lineItems
        .map((l: any, i: number) => ({ ...l, index: i }))
        .reduce((acc: any, l: any) => {
            const type = l.trade ?? 'Other';

            const group = acc.find((el: any) => el.type === type);
            const groupIndex = acc.findIndex((el: any) => el.type === type);

            if (group) {
                if (!group[l.group]) {
                    group[l.group] = [];
                }
                group[l.group].push(l);
                acc[groupIndex] = group;
            } else {
                acc.push({
                    type,
                    labor: [],
                    material: [],
                    equipment: [],
                    other: [],
                });
                if (!acc[acc.length - 1][l.group]) {
                    acc[acc.length - 1][l.group] = [];
                }
                acc[acc.length - 1][l.group].push(l);
            }

            return acc;
        }, []);

    const save = () => {
        if (editingLineItem) {
            if (isAddNewItem) {
                // Adding new item
                setLineItems([
                    ...lineItems,
                    {
                        name: editingLineItem.name,
                        quantity: editingLineItem.quantity,
                        unitprice: editingLineItem.unitprice,
                        description: editingLineItem.description,
                        trade: editingLineItem.trade,
                        group: editingLineItem.group,
                        amount_type: editingLineItem.amount_type,
                    },
                ]);
                setIsAddNewItem(false);
            } else {
                // Editing existing item
                setLineItems(
                    lineItems.map((l: any, idx: number) =>
                        idx === editingLineItem?.index ? editingLineItem : l
                    )
                );
            }
            setEditingLineItem(undefined);
        }
        //TODO: knowledge base update
        // const wasPriceChanged =
        //     existingLineItem.unitprice !== editingLineItem.unitprice;

        // if (
        //     wasPriceChanged &&
        //     ['material', 'labor', 'equipment'].includes(
        //         editingLineItem.group
        //     )
        // ) {
        //     updateKnowledgeBaseBasedOnLineItem?.(editingLineItem);
        // }
    };

    const handleAddLineItem = () => {
        const newItem = {
            name: 'New Item',
            quantity: '0',
            unitprice: '0',
            description: '',
            trade: 'Other',
            group: '',
            amount_type: 'fixed' as const,
            index: lineItems.length,
        };
        setEditingLineItem(newItem);
        setIsAddNewItem(true);
    };

    const renderLineItem = (
        l: ICELineItem & { index: number },
        i: number,
        idx: number,
        idxSubgroup: number
    ) => {
        return (
            <SwipeableItem
                key={'estimate' + idx.toString() + i.toString()}
                onDelete={() => {
                    onWebAppCostEstimationRemoveLineItem({
                        item: l.name,
                    });
                    setLineItems(
                        lineItems.filter((_, index) => index !== l.index)
                    );
                }}
            >
                <div
                    key={'estimate' + idx.toString() + i.toString()}
                    style={{
                        position: 'relative',
                        width: '100%',
                        borderBottom: '1px solid #D6D6D6',
                        paddingLeft: 32,
                        paddingRight: width < 768 ? 16 : 30,
                    }}
                >
                    <div
                        onClick={(e) => {
                            setEditingLineItem?.({
                                ...l,
                            });
                            onWebAppCostEstimationEditLineItem({
                                item: l.name,
                            });
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '16px',
                            cursor: 'pointer',
                            height: 58,
                            borderBottomWidth: 0.5,
                            borderBottomColor: '#D6D6D6',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <BodyBold style={{ fontSize: 14 }}>
                                {l.name || l.description}
                                {l.amount_type === 'percentage'
                                    ? ` (${l.unitprice}%)`
                                    : null}
                            </BodyBold>
                            {l.amount_type === 'percentage' ? null : (
                                <Body
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        fontWeight: '600',
                                        marginTop: 5,
                                        fontSize: 14,
                                        color: '#74778C',
                                        gap: '2px',
                                    }}
                                >
                                    {isLineItemMeasurable(i) ? (
                                        <img
                                            alt="ruler"
                                            src={Ruler}
                                            width={18}
                                            height={15}
                                            style={{ marginRight: 10 }}
                                        />
                                    ) : null}
                                    {isLineItemMeasurable(i)
                                        ? (l.quantity || '0') + ' sq. ft'
                                        : l.quantity || '0'}
                                    <span
                                        style={{
                                            color: '#aaa',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0 4px',
                                        }}
                                    >
                                        x
                                    </span>
                                    ${l.unitprice}
                                </Body>
                            )}
                        </div>
                        <BodyBold
                            style={{
                                fontSize: 14,
                                fontWeight: '500',
                            }}
                        >
                            {formatAmount(getLineItemAmount(l, lineItems))}
                        </BodyBold>
                    </div>
                </div>
            </SwipeableItem>
        );
    };

    const renderGroupSection = (
        group: any,
        subGroup: string,
        idx: number,
        idxSubgroup: number
    ) => {
        if (group[subGroup].length === 0) {
            return null;
        }
        return (
            <>
                {group.type !== 'Other' ? (
                    <ButtonBase
                        onClick={() => {
                            setExpandedGroups({
                                ...expandedGroups,
                                [group.type + subGroup]:
                                    expandedGroups[group.type + subGroup] ===
                                    false
                                        ? true
                                        : false,
                            });
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            padding: '16px',
                            backgroundColor: '#F9F9F9',
                            height: 56,
                            borderBottomWidth: 0.5,
                            borderBottomColor: '#D6D6D6',
                            textAlign: 'left',
                        }}
                    >
                        {expandedGroups[group.type + subGroup] === false ? (
                            <KeyboardArrowRight
                                style={{
                                    marginRight: 8,
                                    color: theme.colors.grayText,
                                }}
                            />
                        ) : (
                            <KeyboardArrowDown
                                style={{
                                    marginRight: 8,
                                    color: theme.colors.grayText,
                                }}
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <BodyBold
                                key={group.type}
                                style={{
                                    fontSize: 14,
                                    color:
                                        expandedGroups[group.type] === false
                                            ? 'black'
                                            : '#535353',
                                }}
                            >
                                {subGroup.charAt(0).toUpperCase() +
                                    subGroup.slice(1)}
                            </BodyBold>
                            <BodyBold
                                style={{
                                    fontSize: 14,
                                    paddingRight: width < 768 ? 14 : 30,
                                    color:
                                        expandedGroups[group.type] === false
                                            ? 'black'
                                            : '#535353',
                                }}
                            >
                                {calcGroupSum(group, subGroup)}
                            </BodyBold>
                        </div>
                    </ButtonBase>
                ) : null}
                {expandedGroups[group.type + subGroup] !== false ? (
                    <>
                        {group[subGroup].map((l: any, i: number) =>
                            renderLineItem(l, i, idx, idxSubgroup)
                        )}
                    </>
                ) : null}
            </>
        );
    };

    return (
        <div style={{ width: '100%', maxWidth: 700 }}>
            {editingLineItem ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        borderWidth: 1,
                        gap: 10,
                        padding: width < 768 ? 10 : 20,
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <BodyBold
                            style={{
                                marginBottom: '5px',
                                paddingLeft: width < 768 ? 10 : 20,
                                fontSize: width < 768 ? 14 : 16,
                            }}
                        >
                            Item
                        </BodyBold>
                        <input
                            style={{
                                lineHeight: 'normal',
                                borderRadius: '10px',
                                fontSize: width < 768 ? 16 : 20,
                                padding:
                                    width < 768
                                        ? '10px 15px 10px 20px'
                                        : '15px 20px 15px 25px',
                                boxSizing: 'border-box',
                                border: `1px solid #D1D2D9`,
                                width: '100%',
                            }}
                            name="Item"
                            value={editingLineItem.name ?? ''}
                            onChange={(e) => {
                                onWebAppCostEstimationEditLineItem({
                                    item: e.target.value,
                                });
                                if (editingLineItem) {
                                    setEditingLineItem({
                                        ...editingLineItem,
                                        name: e.target.value,
                                    });
                                }
                            }}
                        />
                    </div>

                    <div style={{ width: '100%' }}>
                        <BodyBold
                            style={{
                                marginBottom: '5px',
                                paddingLeft: width < 768 ? 10 : 20,
                                fontSize: width < 768 ? 14 : 16,
                            }}
                        >
                            Description
                        </BodyBold>
                        {isAddNewItem ? (
                            <input
                                style={{
                                    lineHeight: 'normal',
                                    borderRadius: '10px',
                                    fontSize: width < 768 ? 16 : 20,
                                    padding:
                                        width < 768
                                            ? '10px 15px 10px 20px'
                                            : '15px 20px 15px 25px',
                                    boxSizing: 'border-box',
                                    border: `1px solid #D1D2D9`,
                                    width: '100%',
                                }}
                                name="Description"
                                value={editingLineItem.description ?? ''}
                                onChange={(e) => {
                                    if (editingLineItem) {
                                        setEditingLineItem({
                                            ...editingLineItem,
                                            description: e.target.value,
                                        });
                                    }
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                    width: '100%',
                                    backgroundColor: '#F7F7F7',
                                    borderRadius: '8px',
                                    border: `1px solid ${theme.colors.border}`,
                                }}
                            >
                                <Body
                                    style={{
                                        color: '#808080',
                                        padding:
                                            width < 768
                                                ? '5px'
                                                : '15px 20px 15px 25px',
                                    }}
                                >
                                    {editingLineItem.description}
                                </Body>
                            </div>
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        <BodyBold
                            style={{
                                marginBottom: '5px',
                                paddingLeft: width < 768 ? 10 : 20,
                                fontSize: width < 768 ? 14 : 16,
                            }}
                        >
                            Quantity
                        </BodyBold>

                        <input
                            style={{
                                lineHeight: 'normal',
                                borderRadius: '10px',
                                fontSize: width < 768 ? 16 : 20,
                                padding:
                                    width < 768
                                        ? '10px 15px 10px 20px'
                                        : '15px 20px 15px 25px',
                                boxSizing: 'border-box',
                                border: `1px solid #D1D2D9`,
                                width: '100%',
                            }}
                            name="Quantity"
                            value={editingLineItem.quantity ?? '0'}
                            onChange={(e) => {
                                if (editingLineItem) {
                                    setEditingLineItem({
                                        ...editingLineItem,
                                        quantity: e.target.value,
                                    });
                                }
                            }}
                        />
                    </div>

                    <div style={{ width: '100%' }}>
                        <BodyBold
                            style={{
                                marginBottom: '5px',
                                paddingLeft: width < 768 ? 10 : 20,
                                fontSize: width < 768 ? 14 : 16,
                            }}
                        >
                            {editingLineItem.amount_type === 'percentage'
                                ? 'Percentage'
                                : `Price per ${
                                      editingLineItem.unit?.replace(/s$/, '') ||
                                      'unit'
                                  }`}
                        </BodyBold>

                        <input
                            style={{
                                lineHeight: 'normal',
                                borderRadius: '10px',
                                fontSize: width < 768 ? 16 : 20,
                                padding:
                                    width < 768
                                        ? '10px 15px 10px 20px'
                                        : '15px 20px 15px 25px',
                                boxSizing: 'border-box',
                                border: `1px solid #D1D2D9`,
                                width: '100%',
                            }}
                            name="Price per unit"
                            type="number"
                            value={editingLineItem.unitprice ?? ''}
                            onChange={(e) => {
                                if (editingLineItem) {
                                    setEditingLineItem({
                                        ...editingLineItem,
                                        unitprice: e.target.value,
                                    });
                                }
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            position: 'fixed',
                            flexDirection: width < 768 ? 'column' : 'row',
                            justifyContent: 'center',
                            bottom: width < 768 ? 10 : 15,
                            maxWidth: 700,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                color: 'white',
                                boxShadow: 'none',
                                borderRadius: 25,
                                height: 50,
                                width: Math.min(width * 0.8, 400),
                                fontWeight: '700',
                                fontSize: 20,
                            }}
                            sx={{
                                '&:hover': {
                                    backgroundColor: `${theme.colors.primary400Light} !important`,
                                },
                            }}
                            onClick={() => {
                                setEditingLineItem(undefined);
                                save();
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                if (!isAddNewItem) {
                                    setLineItems(
                                        lineItems.filter(
                                            (_, idx) =>
                                                idx !== editingLineItem?.index
                                        )
                                    );
                                }
                                setEditingLineItem(undefined);
                                setIsAddNewItem(false);
                            }}
                            style={{
                                color: theme.colors.error,
                                boxShadow: 'none',
                                borderRadius: 25,
                                height: 50,
                                width: Math.min(width * 0.8, 400),
                                fontWeight: '700',
                                fontSize: 20,
                            }}
                            sx={{
                                '&:hover': {
                                    backgroundColor: `${theme.colors.error} !important`,
                                    color: 'white !important',
                                },
                            }}
                        >
                            {isAddNewItem ? 'Cancel' : 'Delete'}
                        </Button>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        marginTop: width < 768 ? -30 : 0,
                    }}
                >
                    {/**Total Section */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: width < 768 ? 15 : 20,
                            paddingLeft: width < 768 ? 10 : 20,
                        }}
                    >
                        <Calculate
                            style={{
                                color: theme.colors.primary400,
                                marginRight: 12,
                            }}
                            fontSize="large"
                        />
                        <div>
                            <Body
                                style={{
                                    fontSize: 12,
                                    color: theme.colors.grayText,
                                    fontWeight: 600,
                                }}
                            >
                                TOTAL
                            </Body>
                            <BodyBold
                                style={{ fontSize: 20, fontWeight: '700' }}
                            >
                                {formatAmount(estimateTotal || 0)}
                            </BodyBold>
                        </div>
                    </div>

                    <div style={{ marginTop: 25 }}>
                        {groupedLineItems
                            .sort(
                                (a: { type: string }, b: { type: string }) => {
                                    // Move "Other" group to the end
                                    if (a.type === 'Other') return 1;
                                    if (b.type === 'Other') return -1;
                                    return a.type.localeCompare(b.type);
                                }
                            )
                            .map((group: any, idx: number) => {
                                return (
                                    <div
                                        style={{
                                            borderWidth: 1,
                                            borderColor: '#D6D6D6',
                                        }}
                                    >
                                        {/**Group Header */}
                                        <ButtonBase
                                            onClick={() => {
                                                setExpandedGroups({
                                                    ...expandedGroups,
                                                    [group.type]:
                                                        !expandedGroups[
                                                            group.type
                                                        ],
                                                });
                                            }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                padding: '16px',
                                                paddingRight:
                                                    width < 768 ? 14 : 30,
                                                backgroundColor: '#ECECEC',
                                                borderBottomWidth:
                                                    expandedGroups[
                                                        group.type
                                                    ] === false
                                                        ? 0
                                                        : 0.5,
                                                borderBottomColor: '#D6D6D6',
                                                height: 56,
                                                textAlign: 'left',
                                            }}
                                        >
                                            {expandedGroups[group.type] ===
                                            false ? (
                                                <Add
                                                    style={{
                                                        color: theme.colors
                                                            .grayText,
                                                    }}
                                                />
                                            ) : (
                                                <Remove
                                                    style={{
                                                        color: theme.colors
                                                            .grayText,
                                                    }}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    flex: 1,
                                                    flexWrap: 'wrap',
                                                    gap: '10px',
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <BodyBold
                                                    style={{
                                                        fontSize:
                                                            group.type.length >
                                                                25 &&
                                                            width < 768
                                                                ? 13
                                                                : 16,
                                                        flexWrap: 'wrap',
                                                        flex: 1,
                                                        paddingRight: 10,
                                                        fontWeight: '700',
                                                        color: theme.colors
                                                            .text,
                                                    }}
                                                >
                                                    {group.type}
                                                </BodyBold>
                                                <BodyBold
                                                    style={{
                                                        fontSize: 16,
                                                        minWidth: 100,
                                                        fontWeight: '600',
                                                        textAlign: 'right',
                                                        paddingRight: 10,
                                                    }}
                                                >
                                                    {calcGroupTypeTotal(group)}
                                                </BodyBold>
                                            </div>
                                        </ButtonBase>
                                        {expandedGroups[group.type] !==
                                        false ? (
                                            <>
                                                {Object.keys(group)
                                                    .filter(
                                                        (subGroup) =>
                                                            Array.isArray(
                                                                group[subGroup]
                                                            ) &&
                                                            group[subGroup]
                                                                .length
                                                    )
                                                    .map(
                                                        (
                                                            subGroup,
                                                            idxSubgroup
                                                        ) => {
                                                            return renderGroupSection(
                                                                group,
                                                                subGroup,
                                                                idx,
                                                                idxSubgroup
                                                            );
                                                        }
                                                    )}
                                            </>
                                        ) : null}
                                    </div>
                                );
                            })}
                    </div>

                    <div
                        onClick={() => {
                            onWebAppCostEstimationAddLineItem({
                                item: 'New Item',
                            });
                            handleAddLineItem();
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: 5,
                            borderColor: theme.colors.border,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: 15,
                            paddingRight: width < 768 ? 14 : 30,
                            margin: '5px 10px',
                            cursor: 'pointer',
                        }}
                    >
                        <BodyBold
                            style={{
                                fontSize: 16,
                                color: theme.colors.primary400,
                            }}
                        >
                            + Add Item
                        </BodyBold>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LineItemList;
