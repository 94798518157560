import {
    Folder,
    FOLDER_ICONS,
    FOLDER_ICONS_V2,
    ICON_SIZE,
} from '../../util/folders';
import Custom from '../../assets/icons/folders/custom.svg';

interface IIconProps {
    iconId: string | null;
    folderName?: string;
    size?: number | null;
}

const FolderIcon = ({ iconId, folderName, size }: IIconProps): JSX.Element => {
    let finalSize = size || ICON_SIZE;

    const blankFolder = (
        <img alt="" src={Custom} width={finalSize} height={finalSize} />
    );

    // all folders have icon_id = null in UserTag until manual icon change
    if (!iconId) {
        if (
            folderName &&
            Object.keys(FOLDER_ICONS).includes(folderName.toLowerCase())
        ) {
            const src = FOLDER_ICONS[folderName.toLowerCase()];
            return <Folder src={src} width={finalSize} height={finalSize} />;
        } else {
            return blankFolder;
        }
    }

    const icons = FOLDER_ICONS_V2.filter((iconItem) => {
        return iconItem.id === iconId;
    });

    if (icons.length === 0) {
        console.warn('Icon not found');
        return blankFolder;
    }

    const item = icons[0];
    if (item.scale) {
        finalSize = finalSize * item.scale;
    }

    return <Folder src={item.icon} width={finalSize} height={finalSize} />;
};

export default FolderIcon;
