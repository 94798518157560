import React from 'react';
import { Button } from '@mui/material';
import alldone from '../../../../assets/alldone.svg';
import { onWebAppCostEstimationBookingLinkGoToInbox } from '../../../../tracking/trackers';
import WhiteSave from '../../../../assets/whiteSave.svg';
import { Container, SignImage, MessageText } from './styles';

const StepDone = ({ isContractor }: { isContractor: boolean }) => {
    const handleGotoInbox = () => {
        window.location.href = 'swce://open-inbox';
        onWebAppCostEstimationBookingLinkGoToInbox();
    };

    return (
        <Container>
            <SignImage>
                <img src={alldone} alt="All Done!" />
            </SignImage>

            {isContractor ? (
                <MessageText>
                    Completed client forms appear in app inbox!
                </MessageText>
            ) : (
                <MessageText>We'll be in touch shortly!</MessageText>
            )}
            {isContractor ? (
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            color: 'white',
                            boxShadow: 'none',
                            borderRadius: 25,
                            height: 50,
                            fontWeight: '700',
                            fontSize: 20,
                            minWidth: 350,
                            textTransform: 'none',
                            maxWidth: 500,
                            display: 'flex',
                            gap: '10px',
                            marginTop: 20,
                        }}
                        onClick={handleGotoInbox}
                    >
                        <img src={WhiteSave} alt="" width={30} height={30} />
                        Go to Inbox
                    </Button>
                </>
            ) : null}
        </Container>
    );
};

export default StepDone;
