import { hexToRGB } from '../util/hexToRGB';

const offWhiteShadow = '#EDE6DE';
const screenShadow = '#EFEFEF';

const theme = {
    roundness: 10,
    colors: {
        primary: '#191C3D',
        primaryLight: '#435868',
        primary50: '#DEF2F9',
        primary50Light: hexToRGB('#DEF2F9', 0.5),
        primary400: '#00B1C9',
        primary400Light: '#78C4E2',
        secondary: '#00B1C9',
        accent: '#00B1C9',
        success: '#1FD47D',
        error: '#ED6A5A',
        warning: '#E7B537',
        screen: '#F0F0F0',
        screenShadow,
        folderHover: '#fafafa',
        background: '#F3F4F5',
        disabledOverlay: 'rgba(0, 0, 0, 0.75)',
        grayText: 'rgba(0, 0, 0, 0.65)',
        disabled: '#979797',
        white: '#FFF',
        offWhiteTransparent: 'rgba(255, 232, 174, 0.1)',
        offWhiteSolid: '#FFFDF6', // takes away the transparency of the other offWhite
        offWhiteShadow,
        blue100: '#181C3F',
        blue80: '#5D6079',
        blue60: '#74778C',
        blue20: '#D1D2D9',
        blue10: '#E8E8EC',
        blue5: '#F3F3F5',
        offWhite: '#F6F3EF',
        divider: 'rgba(33, 33, 33, 0.08)',
        text: '#000',
        border: '#F9F9F9', // TODO(jj): not the same as mobile
        webBackgroundColor: '#F5F3EE',
    },
    fontSizes: {
        headerLarge: 24,
        header: 20,
        body: 16,
        subtitle: 18,
        label: 14,
        folderLabel: 14,
        onboarding: 32,
        digitLarge: 50,
    },
    letterSpacing: '0.15px',
    defaultShadow: `1px 1px 5px ${screenShadow}`,
    defaultShadowDark: `1px 1px 5px ${hexToRGB(screenShadow, 0.5)}`,
    reviewsShadow: `0px 0px 12px 0px rgba(0, 0, 0, 0.06)`,
    headerShadow: `0px 2px 3px 1px ${hexToRGB(offWhiteShadow, 0.5)}`,
    sectionShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
    lightShadow: '0px 2px 1px rgba(0, 0, 0, 0.05)',
    spreadShadow: '10px 10px 50px rgba(0, 0, 0, 0.05)',
};

export default theme;
