import { Button } from '@mui/material';
import { DriveFileRenameOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { onWebAppCostEstimationStep } from '../../../tracking/trackers';
import theme from '../../../theme';
import { callClaude } from './CostEstimatorProvider/service';
import { useCostEstimator } from './CostEstimatorProvider';

const StepSaveName = () => {
    const { setStep, estimateId, setEstimateName, prompt } = useCostEstimator();
    const [inputValue, setInputValue] = useState('');

    // Generate name when component mounts
    useEffect(() => {
        if (prompt && !inputValue) {
            callClaude({
                instructions:
                    'Based on this project description, create a descriptive name for the project. Only return the name and nothing else',
                prompt: prompt,
                quick: true,
            }).then((res: any) => {
                setInputValue(res.response);
                setEstimateName(res.response);
            });
        }
    }, [prompt]);

    const handleNext = () => {
        // Just navigate to folder selection, don't save yet
        setStep('save-folder');
        onWebAppCostEstimationStep({
            estimate_id: estimateId || '',
            step: 'selecting-folder',
        });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setEstimateName(newValue);
    };

    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '90%',
                maxWidth: '37.5rem',
                margin: '0 auto',
                padding: '2.5rem 1.25rem',
            }}
        >
            <div
                style={{
                    backgroundColor: theme.colors.offWhite,
                    borderRadius: '50%',
                    width: '12.5rem',
                    height: '12.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '2.5rem',
                }}
            >
                <DriveFileRenameOutline
                    style={{
                        width: '6.25rem',
                        height: '6.25rem',
                        color: theme.colors.primary400,
                    }}
                />
            </div>

            <h2
                style={{
                    fontSize: '1.75rem',
                    fontWeight: '600',
                    marginBottom: '1.875rem',
                    textAlign: 'center',
                    color: theme.colors.text,
                }}
            >
                Estimate Name
            </h2>

            <input
                value={inputValue}
                onChange={handleNameChange}
                style={{
                    width: '100%',
                    padding: '0.9375rem 1.25rem',
                    fontSize: '1rem',
                    border: `0.125rem solid ${theme.colors.offWhite}`,
                    borderRadius: '0.75rem',
                    outline: 'none',
                    transition: 'border-color 0.2s ease',
                    backgroundColor: 'white',
                    marginBottom: '2.5rem',
                }}
                onFocus={(e) => {
                    e.target.style.borderColor = theme.colors.primary400;
                }}
                onBlur={(e) => {
                    e.target.style.borderColor = theme.colors.offWhite;
                }}
                placeholder="Enter estimate name..."
            />

            <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: '1.5625rem',
                    height: '3.125rem',
                    width: '100%',
                    fontWeight: '700',
                    fontSize: '1.25rem',
                    minWidth: '21.875rem',
                    transition: 'transform 0.2s ease',
                }}
                onClick={handleNext}
                onMouseOver={(e) => {
                    e.currentTarget.style.transform = 'scale(1.02)';
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                }}
            >
                Next
            </Button>
        </div>
    );
};

export default StepSaveName;
