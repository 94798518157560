import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import theme from '../../../../theme';

export const Container = styled('div')({
    padding: '0 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '37.5rem',
    margin: '0 auto',
    height: '100%',
});

export const TitleContainer = styled('div')({
    marginBottom: '1rem',
    marginTop: '2rem',
    textAlign: 'center',
});

export const FormContainer = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    width: '100%',
});

export const InputGroup = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
});

export const StyledInput = styled('input')({
    'width': '100%',
    'padding': '1rem',
    'borderRadius': '0.75rem',
    'border': `1px solid #D1D2D9`,
    'fontSize': '1rem',
    'lineHeight': '1.5',
    'fontFamily': 'inherit',
    '&::placeholder': {
        color: '#A3A3A3',
    },
    '&:focus': {
        outline: 'none',
        borderColor: theme.colors.primary400,
    },
});

export const ContinueButton = styled(Button)({
    marginTop: '1.5rem',
    borderRadius: '1.5625rem',
    height: '3.125rem',
    backgroundColor: theme.colors.primary400,
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
});

export const StyledLabel = styled('label')({
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.colors.text,
});

export const RequiredIndicator = styled('span')({
    color: '#FF0000',
    marginLeft: '0.125rem',
});

export const ButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.5rem',
});
