import { createContext, useContext, useEffect, useState } from 'react';
import { IUserCategoryGroup, IUserTag } from '../../services/receipts/types';
import ReceiptService from '../../services/receipts/service';
import { useAuth } from '../../providers/AuthProvider';
import { CARD_DIMENSIONS } from '../../components/FolderCard/styles';
import CategoryGroupService from '../../services/category_groups/service';
import FolderService from '../../services/folders/service';
import { dispatchThunk } from '../../hooks/dispatchThunk';
import { useTagsStore } from '../../providers/IndexedDBProvider';

const MAX_NUM_COLUMNS = 5;

export interface IFolderState {
    folders: IUserTag[];
    setFolders: React.Dispatch<React.SetStateAction<IUserTag[]>>;
    categoryGroups: IUserCategoryGroup[];
    setCategoryGroups: React.Dispatch<
        React.SetStateAction<IUserCategoryGroup[]>
    >;
    isFetching: boolean;
    refresh: () => void;
    numCardColumns: number;
    editFolder: (
        folderId: string,
        name: string,
        iconId?: string | null
    ) => void;
    editCategoryGroup: (categoryGroupId: number, name: string) => void;
    addFolder: (name: string, iconId: string | null, spaceId?: number) => void;
    deleteFolder: (folderId: string) => void;
    createCategoryGroup: (
        name: string,
        categoryIds: string[],
        icon?: string | null,
        spaceId?: number
    ) => void;
    assignCategoryGroup: (folderId: string, categoryGroupId: number) => void;
    unassignCategoryGroup: (folderId: string, categoryGroupId: number) => void;
    isCostEstimator: boolean;
}

const FolderContext = createContext<IFolderState>({
    folders: [],
    setFolders: () => {},
    categoryGroups: [],
    setCategoryGroups: () => {},
    isFetching: false,
    refresh: () => {},
    numCardColumns: MAX_NUM_COLUMNS,
    editFolder: () => {},
    addFolder: () => {},
    deleteFolder: () => {},
    createCategoryGroup: () => {},
    assignCategoryGroup: () => {},
    unassignCategoryGroup: () => {},
    editCategoryGroup: () => {},
    isCostEstimator: false,
});

const FolderProvider = ({ children }: { children: React.ReactNode }) => {
    const { tags: tagsInStore, categoryGroups: categoryGroupsInStore } =
        useTagsStore();
    const [folders, setFolders] = useState<IUserTag[]>([]);
    const [categoryGroups, setCategoryGroups] = useState<IUserCategoryGroup[]>(
        []
    );
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isCostEstimator, setIsCostEstimator] = useState<boolean>(false);
    const [numCardColumns, setNumCardColumns] =
        useState<number>(MAX_NUM_COLUMNS);
    const { token } = useAuth();

    useEffect(() => {
        const handleResize = () => {
            setNumCardColumns(
                Math.min(
                    MAX_NUM_COLUMNS,
                    Math.floor(
                        window.innerWidth /
                            (CARD_DIMENSIONS.width + CARD_DIMENSIONS.gap * 2)
                    )
                )
            );
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const editFolder = async (
        folderId: string,
        name: string,
        iconId?: string | null
    ) => {
        return dispatchThunk(
            FolderService.editFolder(folderId, name, iconId),
            () => {
                setFolders((prevFolders) => {
                    const updatedFolders = prevFolders.map((folder) => {
                        if (folder.tag_id === folderId) {
                            const newF: IUserTag = {
                                ...folder,
                                name: name,
                                icon_id: iconId || null,
                            };
                            return newF;
                        }
                        return folder;
                    });
                    return updatedFolders;
                });
            }
        );
    };

    const addFolder = async (
        name: string,
        iconId: string | null,
        spaceId?: number
    ) => {
        return dispatchThunk(
            FolderService.createFolder(name, iconId, spaceId),
            (data) => {
                setFolders((prevFolders) => {
                    const updatedFolders = [...prevFolders, data.tag];
                    return updatedFolders;
                });
            }
        );
    };

    const deleteFolder = async (folderId: string) => {
        return dispatchThunk(FolderService.deleteFolder(folderId), () => {
            setFolders((prevFolders) => {
                const updatedFolders = prevFolders.filter(
                    (f) => f.tag_id !== folderId
                );
                return updatedFolders;
            });
        });
    };

    const refresh = async () => {
        setIsFetching(true);
        const getTagsRequest = ReceiptService.getReceiptTags();
        const getCategoryGroupsRequest =
            CategoryGroupService.getCategoryGroups();

        const [tagsResponse, categoryGroupsResponse] = await Promise.all([
            getTagsRequest,
            getCategoryGroupsRequest,
        ]);
        const { status, data } = tagsResponse;
        if (status !== 'success') {
            console.error('There was an error!');
        } else {
            setFolders(data);
        }
        const { data: categoryGroups, status: categoryGroupStatus } =
            categoryGroupsResponse;
        if (categoryGroupStatus !== 'success') {
            console.error('There was an error!');
        } else {
            setCategoryGroups(categoryGroups);
        }
        setIsFetching(false);
    };

    const createCategoryGroup = async (
        name: string,
        categoryIds: string[],
        icon?: string | null,
        spaceId?: number
    ) => {
        return dispatchThunk(
            CategoryGroupService.createCategoryGroup(
                name,
                categoryIds,
                icon,
                spaceId
            ),
            (data) => {
                setCategoryGroups((prevCategoryGroups) => [
                    ...prevCategoryGroups,
                    data.data,
                ]);
                setFolders((prevFolders) => {
                    const updatedFolders = prevFolders.map((folder) => {
                        if (categoryIds.includes(folder.tag_id)) {
                            return {
                                ...folder,
                                category_group_ids: [
                                    ...(folder.category_group_ids || []),
                                    data.data.id,
                                ],
                            };
                        }
                        return folder;
                    });
                    return updatedFolders;
                });
            }
        );
    };

    const assignCategoryGroup = async (
        folderId: string,
        categoryGroupId: number
    ) => {
        return dispatchThunk(
            CategoryGroupService.assignCategoryGroup(folderId, categoryGroupId),
            () => {
                setFolders((prevFolders) => {
                    const updatedFolders = prevFolders.map((folder) => {
                        if (folder.tag_id === folderId) {
                            return {
                                ...folder,
                                category_group_ids: [
                                    ...(folder.category_group_ids || []),
                                    categoryGroupId,
                                ],
                            };
                        }
                        return folder;
                    });
                    return updatedFolders;
                });
            }
        );
    };

    const unassignCategoryGroup = async (
        folderId: string,
        categoryGroupId: number
    ) => {
        return dispatchThunk(
            CategoryGroupService.unassignCategoryGroup(
                folderId,
                categoryGroupId
            ),
            () => {
                setFolders((prevFolders) => {
                    const updatedFolders = prevFolders.map((folder) => {
                        if (folder.tag_id === folderId) {
                            return {
                                ...folder,
                                category_group_ids:
                                    folder.category_group_ids?.filter(
                                        (id) => id !== categoryGroupId
                                    ) || [],
                            };
                        }
                        return folder;
                    });
                    return updatedFolders;
                });
            }
        );
    };

    const editCategoryGroup = async (categoryGroupId: number, name: string) => {
        return dispatchThunk(
            CategoryGroupService.editCategoryGroup(categoryGroupId, name),
            () => {
                setCategoryGroups((prevCategoryGroups) => {
                    const updatedCategoryGroups = prevCategoryGroups.map(
                        (cg) => {
                            if (cg.id === categoryGroupId) {
                                return {
                                    ...cg,
                                    name: name || cg.name,
                                };
                            }
                            return cg;
                        }
                    );
                    return updatedCategoryGroups;
                });
            }
        );
    };

    useEffect(() => {
        if (!tagsInStore || !categoryGroupsInStore) return;
        if (tagsInStore.length > 0) {
            setFolders(tagsInStore);
            setCategoryGroups(categoryGroupsInStore);
            setIsFetching(false);
            return;
        }
        if (token) {
            refresh();
        }
    }, [token, tagsInStore, categoryGroupsInStore]);

    return (
        <FolderContext.Provider
            value={{
                editFolder,
                addFolder,
                deleteFolder,
                createCategoryGroup,
                assignCategoryGroup,
                unassignCategoryGroup,
                editCategoryGroup,
                folders,
                setFolders,
                categoryGroups,
                setCategoryGroups,
                isFetching,
                refresh,
                numCardColumns,
                isCostEstimator,
            }}
        >
            {children}
        </FolderContext.Provider>
    );
};

export const useFolders = () => useContext(FolderContext);

export default FolderProvider;
