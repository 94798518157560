import LogoWhite from '../../assets/landing/logo_white.svg';
import CTAButton from '../../components/Buttons/CTAButton';
import { FooterData } from '../../components/LandingPageVariations/configuration/types';
import { Body, BodyBold, BulletPoint } from '../../components/Text';
import theme from '../../theme';
import { FAQ_LINK } from '../../util/constants';
import { VariationCallToAction } from '../../components/LandingPageVariations/elements/VariationCallToAction';
import {
    FooterContainer,
    FooterLinksColumn,
    FooterDownloadContainer,
    FooterLink,
} from './styles';

export enum IFooterLink {
    about = 'about',
    privacy = 'privacy',
    terms = 'terms',
    contact = 'contact',
    confidence = 'confidence',
    blog = 'blog',
    faq = 'faq',
}

type IFooterProps = {
    mode?: 'normal' | 'download';
    hideFooterLinks?: IFooterLink[];
    footerData?: FooterData;
    isMobile?: boolean;
    baseName?: string;
    variationName?: string;
};

const Footer = (props: IFooterProps) => {
    // Get object of all footer links we want to include
    const footerObj: { -readonly [key in keyof typeof IFooterLink]: boolean } =
        {
            about: false,
            privacy: false,
            terms: false,
            contact: false,
            confidence: false,
            blog: false,
            faq: false,
        };

    Object.values(IFooterLink).forEach((s) => {
        if (!props.hideFooterLinks || !props.hideFooterLinks.includes(s)) {
            footerObj[s] = true;
        }
    });

    if (props.footerData) {
        return (
            <FooterContainer
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    padding: '45px 0px 0px 0px',
                }}
            >
                {props.footerData.footerWatermark && (
                    <img
                        src={props.footerData.footerWatermark.props.src}
                        alt=""
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: props.isMobile ? '30%' : '25%',
                            width: props.isMobile ? '100%' : '25%',
                            height: 'auto',
                        }}
                    />
                )}
                {props.footerData.slogan && (
                    <BodyBold
                        style={{
                            color: theme.colors.white,
                            textAlign: 'center',
                            fontSize: props.isMobile ? 35 : 50,
                            width: props.isMobile ? '85%' : 500,
                            zIndex: 100,
                        }}
                    >
                        {props.footerData.slogan}
                    </BodyBold>
                )}
                <VariationCallToAction
                    {...props.footerData.callToAction}
                    isFooter
                    baseName={props.baseName ?? ''}
                    variationName={props.variationName ?? ''}
                    buttonWidth={props.isMobile ? '65%' : 300}
                    isMobile={props.isMobile}
                />
                {props.footerData.ratingVisual}
                <div
                    style={{
                        width: '100%',
                        backgroundColor: 'rgba(255,255,255, 0.1)',
                        height: 1,
                        marginTop: 50,
                        marginBottom: 40,
                    }}
                />
                {props.isMobile && props.footerData.footerLogo}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 40,
                        marginTop: props.isMobile ? 40 : 20,
                        width: props.isMobile ? '100%' : '60%',
                        alignItems: 'center',
                        padding: `0 20px`,
                    }}
                >
                    {!props.isMobile && props.footerData.footerLogo && (
                        <img
                            src={props.footerData.footerLogo.props.src}
                            alt=""
                            width={120}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 0,
                            width: props.isMobile ? '100%' : '40%',
                        }}
                    >
                        {props.footerData.links &&
                            props.footerData.links?.map((link, index) => (
                                <FooterLink
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                    href={link.url}
                                >
                                    <Body
                                        style={{
                                            color: 'rgba(255,255,255,0.7)',
                                            marginRight:
                                                index ===
                                                (props.footerData?.links
                                                    ?.length ?? 0) -
                                                    1
                                                    ? 0
                                                    : 20,
                                            fontSize: 14,
                                        }}
                                    >
                                        {link.label}
                                    </Body>
                                </FooterLink>
                            ))}
                    </div>
                </div>
            </FooterContainer>
        );
    }

    return (
        <FooterContainer>
            {props.mode === 'download' ? (
                <FooterDownloadContainer>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 40,
                            marginBottom: 20,
                            minWidth: 200,
                            maxWidth: 300,
                            justifyContent: 'center',
                        }}
                    >
                        <CTAButton text={'Try Now!'} />
                    </div>
                </FooterDownloadContainer>
            ) : (
                <a title="home" href="/" style={{ marginTop: 40 }}>
                    <img
                        src={LogoWhite}
                        alt=""
                        width={170}
                        height={28}
                        style={{ marginTop: 10 }}
                    />
                </a>
            )}
            <FooterLinksColumn>
                {footerObj[IFooterLink.about] ? (
                    <FooterLink href={'/about'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            About Us
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.faq] ? (
                    <FooterLink href={FAQ_LINK}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            FAQ
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.privacy] ? (
                    <FooterLink href={'/privacy'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Privacy Policy
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.terms] ? (
                    <FooterLink href={'/terms'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Terms and Conditions
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {/* {footerObj[IFooterLink.confidence] ? (
                    <FooterLink
                        href={
                            'https://www.simplywise.com/blog/retirement-confidence-index'
                        }
                    >
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Confidence Index
                        </BulletPoint>
                    </FooterLink>
                ) : null} */}
                {footerObj[IFooterLink.blog] ? (
                    <FooterLink href={'https://www.simplywise.com/blog/'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Blog
                        </BulletPoint>
                    </FooterLink>
                ) : null}
            </FooterLinksColumn>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 20,
                    alignItems: 'center',
                }}
            >
                <BulletPoint
                    style={{ color: theme.colors.white, textAlign: 'center' }}
                >
                    Free Tools
                </BulletPoint>
                <a
                    href={'/welcome/code-checker'}
                    style={{ textDecorationColor: theme.colors.white }}
                >
                    <BulletPoint style={{ color: theme.colors.white }}>
                        Building Code Checklists
                    </BulletPoint>
                </a>
                <a
                    href={'/sales-tax-calculator'}
                    style={{
                        textDecorationColor: theme.colors.white,
                        marginTop: 5,
                    }}
                >
                    <BulletPoint style={{ color: theme.colors.white }}>
                        Sales Tax Calculator
                    </BulletPoint>
                </a>
                <a
                    href={'/whats-this-charge'}
                    style={{
                        textDecorationColor: theme.colors.white,
                        marginTop: 5,
                    }}
                >
                    <BulletPoint style={{ color: theme.colors.white }}>
                        What's this charge?
                    </BulletPoint>
                </a>
            </div>
            <BulletPoint style={{ color: theme.colors.white }}>
                Contact us
            </BulletPoint>
            <a
                aria-label="SimplyWise support email"
                href={`mailto:support@simplywise.com`}
                style={{ textDecorationColor: theme.colors.white }}
            >
                <BulletPoint style={{ color: theme.colors.white }}>
                    support@simplywise.com
                </BulletPoint>
            </a>
            <BulletPoint
                style={{ color: theme.colors.disabled, marginTop: 40 }}
            >
                &copy; 2021 SimplyWise, Inc. All rights reserved.
            </BulletPoint>
        </FooterContainer>
    );
};

export default Footer;
