import { styled, Typography } from '@mui/material';
import { Card, Box } from '@mui/material';
import theme from '../../../theme';

export const StyledCard = styled(Card)(() => ({
    'cursor': 'pointer',
    'transition': 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    'border': 'none',
    'borderRadius': 16,
    'backgroundColor': 'rgba(255, 255, 255, 0.95)',
    'backdropFilter': 'blur(10px)',
    'position': 'relative',
    'overflow': 'hidden',
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 16,
        padding: '2px',
        background: `linear-gradient(120deg, ${theme.colors.primary400}40, ${theme.colors.secondary}40)`,
        WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
    },
    '&:hover': {
        'transform': 'translateY(-8px)',
        'boxShadow': '0 20px 40px rgba(0, 0, 0, 0.08)',
        '& .icon-box': {
            transform: 'scale(1.1)',
            backgroundColor: `${theme.colors.primary400}15`,
        },
    },
}));

export const IconBox = styled(Box)(() => ({
    width: 56,
    height: 56,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.colors.primary400}10`,
    marginBottom: 16,
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
}));

export const FormHeader = styled(Typography)(() => ({
    fontSize: '2.5rem',
    fontWeight: 700,
    background: `-webkit-linear-gradient(120deg, ${theme.colors.primary400}, ${theme.colors.secondary})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginBottom: '16px',
    textAlign: 'center',
}));
