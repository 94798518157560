import { styled } from '@mui/material/styles';
import theme from '../../../../theme';

export const Container = styled('div')({
    padding: '0 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '37.5rem',
    margin: '0 auto',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
});

export const SignImage = styled('div')({
    width: '12.5rem',
    height: '12.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '3rem',
    marginTop: '-5rem',
});

export const MessageText = styled('div')({
    color: theme.colors.text,
    fontSize: '1.5rem',
    fontWeight: '700',
    textAlign: 'center',
});
