import React, { useState } from 'react';
import { useQuestionaire } from '../QuestionaireProvider';
import Survey from '../../../../components/Survey';
import DraftingAnimation from '../../../CostEstimator/components/DraftingAnimation';
import MeasuringTape from '../../../../assets/animations/svGator/MeasuringTape';
import CustomAnswerModal from '../../../CostEstimator/components/CustomAnswerModal';
import { onWebAppCostEstimationBookingLinkStep } from '../../../../tracking/trackers';
import {
    Container,
    ProgressBarContainer,
    ProgressBar,
    Progress,
    QuestionText,
    ChoicesContainer,
} from './styles';

const StepQuestions = () => {
    const {
        questionsToAnswer,
        setQuestionsToAnswer,
        setStep,
        initialQuestionCount,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        submitProgressToForm,
        setDrafting,
        formId,
    } = useQuestionaire();
    const [showCustomAnswerModal, setShowCustomAnswerModal] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState<
        Array<{ question: string; answer: string }>
    >([]);

    const handleAnswerQuestion = async (answer: string) => {
        if (answer === 'custom') {
            setShowCustomAnswerModal(true);
            return;
        }

        const currentAnswer = {
            question: questionsToAnswer[currentQuestionIndex].question,
            answer,
        };

        const updatedAnswers = [...answeredQuestions, currentAnswer];
        setAnsweredQuestions(updatedAnswers);

        await submitProgressToForm({
            questions_answers: updatedAnswers,
        });
        onWebAppCostEstimationBookingLinkStep({
            step: 'questions',
            form_id: formId || 0,
            metadata: {
                questions_answers: updatedAnswers,
            },
        });

        if (currentQuestionIndex >= questionsToAnswer.length - 1) {
            setDrafting(true);
            setQuestionsToAnswer([]);
            setStep('client-info-step');
            return;
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1);
    };

    if (questionsToAnswer.length === 0) {
        return (
            <DraftingAnimation
                draftingText="Gathering Information..."
                animation={<MeasuringTape />}
            />
        );
    }

    if (questionsToAnswer.length > 0) {
        return (
            <Container>
                <ProgressBarContainer>
                    <ProgressBar>
                        <Progress
                            style={{
                                width: `${
                                    (currentQuestionIndex /
                                        initialQuestionCount) *
                                    100
                                }%`,
                            }}
                        />
                    </ProgressBar>
                </ProgressBarContainer>

                <QuestionText>
                    {questionsToAnswer[currentQuestionIndex].question}
                </QuestionText>

                <ChoicesContainer>
                    <Survey
                        key={currentQuestionIndex}
                        choices={[
                            ...questionsToAnswer[
                                currentQuestionIndex
                            ].choices.map((choice: string) => ({
                                text: choice,
                                value: choice,
                            })),
                            { text: 'Other', value: 'custom' },
                        ]}
                        textStyle={{ textAlign: 'left' }}
                        onSelect={(choice) => handleAnswerQuestion(choice)}
                    />
                </ChoicesContainer>
                <CustomAnswerModal
                    open={showCustomAnswerModal}
                    onClose={() => setShowCustomAnswerModal(false)}
                    onSubmit={(answer) => {
                        handleAnswerQuestion(answer);
                        setShowCustomAnswerModal(false);
                    }}
                />
            </Container>
        );
    }

    return null;
};

export default StepQuestions;
