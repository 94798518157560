import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { useRef, useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import Resizer from 'react-image-file-resizer';
import Alert from '@mui/material/Alert';
import theme from '../../theme';
import { capitalizeFirstLetter } from '../../util/formatname';
import { ISharedCostEstimation } from '../../services/public/types';
import { ISignatureData } from './types';

const SignatureSection = ({
    isContractor,
    estimateData,
    signatureData,
    setSignatureData,
}: {
    isContractor: boolean;
    estimateData: ISharedCostEstimation;
    signatureData: ISignatureData;
    setSignatureData: React.Dispatch<React.SetStateAction<ISignatureData>>;
}) => {
    const sigCanvas = useRef<SignatureCanvas>(null);
    const canvasContainerRef = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [showInfoAlert, setShowInfoAlert] = useState<boolean>(true);
    // Add resize handler to properly initialize the canvas after component mounts
    useEffect(() => {
        // Only try to manipulate the canvas if we're on the draw tab
        if (activeTab === 0 && sigCanvas.current) {
            const initializeCanvas = () => {
                if (!sigCanvas.current) return;

                sigCanvas.current.clear();

                const canvas = sigCanvas.current._canvas;
                const container = canvasContainerRef.current;

                if (canvas && container) {
                    const rect = container.getBoundingClientRect();
                    canvas.width = rect.width;
                    canvas.height = rect.height;

                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        ctx.scale(1, 1);
                    }
                }
            };

            // Use a small delay to ensure the component is fully mounted
            const timer = setTimeout(initializeCanvas, 0);
            return () => clearTimeout(timer);
        }
    }, [activeTab]);

    useEffect(() => {
        const handleResize = () => {
            if (
                activeTab === 0 &&
                sigCanvas.current &&
                canvasContainerRef.current
            ) {
                try {
                    sigCanvas.current.clear();
                    const canvas = sigCanvas.current._canvas;
                    const container = canvasContainerRef.current;
                    const rect = container.getBoundingClientRect();
                    if (canvas) {
                        canvas.width = rect.width;
                        canvas.height = rect.height;
                    }
                } catch (error) {
                    console.error('Error resizing canvas:', error);
                }
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [activeTab]);

    const handleTypedSignatureChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const text = e.target.value;

        setSignatureData((prev: ISignatureData) => ({
            ...prev,
            typedSignatureText: text,
            signatureType: 'typed',
            originalText: text,
        }));

        if (text.trim()) {
            const canvas = document.createElement('canvas');
            canvas.width = 800;
            canvas.height = 300;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.font = '72px "Italianno", cursive';
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                ctx.fillText(text, canvas.width / 2, canvas.height / 2);

                const signatureImage = canvas.toDataURL('image/png');
                const resizedImage = await resizeSignatureImage(signatureImage);

                setSignatureData((prev: ISignatureData) => ({
                    ...prev,
                    signatureImage: resizedImage,
                }));
            }
        } else {
            setSignatureData((prev: ISignatureData) => ({
                ...prev,
                signatureImage: null,
            }));
        }
    };

    const handleDrawEnd = async () => {
        if (sigCanvas.current) {
            const signatureImage = sigCanvas.current.toDataURL('image/png');
            const resizedImage = await resizeSignatureImage(signatureImage);
            setSignatureData((prev: ISignatureData) => ({
                ...prev,
                signatureImage: resizedImage,
                signatureType: 'drawn',
                originalText: null,
            }));
        }
        setIsDrawing(false);
    };

    const resizeSignatureImage = (base64Image: string): Promise<string> => {
        return new Promise((resolve) => {
            const byteString = atob(base64Image.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: 'image/png' });

            Resizer.imageFileResizer(
                blob,
                500,
                200,
                'PNG',
                85,
                0,
                (uri) => {
                    resolve(uri as string);
                },
                'base64'
            );
        });
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);

        setSignatureData((prev: ISignatureData) => ({
            ...prev,
            signatureImage: null,
            typedSignatureText: '',
            signatureType: newValue === 0 ? 'drawn' : 'typed',
            originalText: null,
        }));
    };

    if (estimateData?.metadata?.approval_status === 'approved') {
        return (
            <>
                <Box sx={{ bgcolor: '#D9D9D9', padding: '6px 16px' }}>
                    <Typography
                        sx={{
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            fontWeight: 'bold',
                        }}
                        color={theme.colors.text}
                    >
                        Signed Signature
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: '0 16px 16px 16px',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <img
                            src={
                                estimateData?.metadata?.signature
                                    ?.signature_image || ''
                            }
                            alt="Signature"
                            style={{
                                maxWidth: '400px',
                                maxHeight: '150px',
                                objectFit: 'contain',
                                filter: 'contrast(1.1)',
                            }}
                        />
                    </Box>

                    <Box>
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '0.875rem',
                                    sm: '1rem',
                                },
                                fontWeight: 'bold',
                                mb: 1,
                                color: '#000',
                            }}
                        >
                            {capitalizeFirstLetter(
                                estimateData?.metadata?.signature?.signer_info
                                    ?.signer_name || ''
                            )}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '0.75rem',
                                    sm: '0.875rem',
                                },
                                color: '#666',
                                mb: 1,
                            }}
                        >
                            {estimateData?.metadata?.signature?.signer_info
                                ?.signer_email || ''}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '0.75rem',
                                    sm: '0.875rem',
                                },
                                color: '#666',
                            }}
                        >
                            {new Date(
                                estimateData?.metadata?.signed_at ?? ''
                            ).toLocaleDateString()}
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }

    // Only render the SignatureCanvas when on the drawing tab
    const renderSignatureCanvas = () => {
        if (activeTab !== 0) return null;

        return (
            <SignatureCanvas
                ref={sigCanvas}
                onBegin={() => setIsDrawing(true)}
                onEnd={handleDrawEnd}
                penColor="black"
                velocityFilterWeight={0.7}
                minWidth={0.5}
                maxWidth={2.5}
                dotSize={1.5}
                throttle={16}
                canvasProps={{
                    className: 'sigCanvas',
                    style: {
                        width: '100%',
                        height: '100%',
                        touchAction: 'none', // Prevent browser scroll/zoom gestures
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    },
                }}
            />
        );
    };

    const resetSignature = () => {
        try {
            if (activeTab === 0 && sigCanvas.current) {
                sigCanvas.current.clear();
                setIsDrawing(false);
            }

            setSignatureData((prev: ISignatureData) => ({
                ...prev,
                signatureImage: null,
                typedSignatureText: '',
                signatureType: activeTab === 0 ? 'drawn' : 'typed',
                originalText: null,
            }));
        } catch (error) {
            console.error('Error resetting signature:', error);
        }
    };

    return (
        <Box
            className={
                estimateData?.metadata?.approval_status === 'pending'
                    ? 'no-print'
                    : ''
            }
            sx={{
                width: '100%',
                mb: 3,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    bgcolor: '#D9D9D9',
                    padding: '6px 16px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: '0.875rem',
                            sm: '1rem',
                        },
                        fontWeight: 'bold',
                    }}
                    color={theme.colors.text}
                >
                    Sign Here
                </Typography>
            </Box>

            {showInfoAlert && isContractor && (
                <Alert
                    severity="info"
                    sx={{
                        'backgroundColor': '#FDF4E7',
                        '& .MuiAlert-message': {
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: '100%',
                        },
                        '& .MuiAlert-icon': {
                            display: 'none',
                        },
                        'ml': { xs: '16px', md: 0 },
                        'mr': { xs: '16px', md: 0 },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'stretch', sm: 'center' },
                            justifyContent: 'space-between',
                            gap: 2,
                            color: theme.colors.text,
                            width: '100%',
                        }}
                    >
                        <Typography
                            fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
                            sx={{
                                flex: 1,
                                textAlign: 'left',
                            }}
                        >
                            This is how the signature will appear to your
                            client.
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setShowInfoAlert(false)}
                            sx={{
                                'backgroundColor': '#002A3C',
                                'borderRadius': '4px',
                                '&:hover': {
                                    backgroundColor: '#002A3C',
                                },
                                'whiteSpace': 'nowrap',
                                'padding': '3px 10px',
                                'fontSize': {
                                    xs: '0.75rem',
                                    sm: '0.75rem',
                                },
                                'alignSelf': 'flex-start',
                            }}
                        >
                            Got it
                        </Button>
                    </Box>
                </Alert>
            )}

            <Box
                sx={{
                    px: 3,
                    py: 2,
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {isContractor && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(32, 32, 32, 0.07)',
                            zIndex: 10,
                            cursor: 'not-allowed',
                        }}
                    />
                )}
                {/* Tabs */}
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    sx={{
                        'mb': 2,
                        'borderBottom': '1px solid #E0E0E0',
                        '& .MuiTab-root': {
                            minHeight: '48px',
                            textTransform: 'none',
                            fontSize: '1rem',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.colors.primary400,
                            height: '2px',
                        },
                    }}
                >
                    <Tab
                        value={0}
                        icon={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <EditIcon
                                    sx={{
                                        fontSize: '1.2rem',
                                    }}
                                />
                                <Typography>Draw</Typography>
                            </Box>
                        }
                        sx={{
                            '&.Mui-selected': {
                                'color': theme.colors.primary400,
                                '& .MuiSvgIcon-root': {
                                    color: theme.colors.primary400,
                                },
                            },
                        }}
                    />
                    <Tab
                        value={1}
                        icon={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    fontStyle="italic"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    T
                                </Typography>
                                <Typography>Type</Typography>
                            </Box>
                        }
                        sx={{
                            '&.Mui-selected': {
                                'color': theme.colors.primary400,
                                '& .MuiSvgIcon-root': {
                                    color: theme.colors.primary400,
                                },
                            },
                        }}
                    />
                </Tabs>

                {/* Draw Tab Panel */}
                {activeTab === 0 && (
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '200px',
                            backgroundColor: '#f8f9fa',
                            border: `2px solid #E2E3E4`,
                            borderRadius: '8px',
                        }}
                        ref={canvasContainerRef}
                    >
                        {!isDrawing && !signatureData.signatureImage && (
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#9E9E9E',
                                    pointerEvents: 'none',
                                    fontSize: {
                                        xs: '0.875rem',
                                        sm: '1.1rem',
                                    },
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '0 16px',
                                    zIndex: 2,
                                }}
                            >
                                Draw your signature here
                            </Typography>
                        )}
                        {renderSignatureCanvas()}
                    </Box>
                )}

                {/* Type Tab Panel */}
                {activeTab === 1 && (
                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Type your signature"
                            value={signatureData.typedSignatureText}
                            onChange={handleTypedSignatureChange}
                            onBlur={handleTypedSignatureChange}
                            sx={{
                                'backgroundColor': '#f8f9fa',
                                'borderRadius': '8px',
                                'mb': 2,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                },
                            }}
                        />
                        {signatureData.typedSignatureText && (
                            <Box
                                sx={{
                                    width: '100%',
                                    minHeight: '80px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f8f9fa',
                                    border: `2px solid #E2E3E4`,
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: '"Italianno", cursive',
                                        fontSize: '2.5rem',
                                        color: '#000',
                                    }}
                                >
                                    {signatureData.typedSignatureText}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {/* Signer Form Fields */}
                <Box sx={{ mt: 2 }}>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: '0.875rem',
                                sm: '1rem',
                            },
                            fontWeight: 'bold',
                            mb: 1,
                        }}
                    >
                        Full Name{' '}
                        <span
                            style={{
                                color: theme.colors.error,
                            }}
                        >
                            *
                        </span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={signatureData.signerName}
                        onChange={(e) =>
                            setSignatureData((prev: ISignatureData) => ({
                                ...prev,
                                signerName: e.target.value,
                            }))
                        }
                        sx={{
                            'mb': 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                            },
                        }}
                    />

                    <Typography
                        sx={{
                            fontSize: {
                                xs: '0.875rem',
                                sm: '1rem',
                            },
                            fontWeight: 'bold',
                            mb: 1,
                        }}
                    >
                        Email Address{' '}
                        <span
                            style={{
                                color: theme.colors.error,
                            }}
                        >
                            *
                        </span>
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="email"
                        value={signatureData.signerEmail}
                        onChange={(e) =>
                            setSignatureData((prev: ISignatureData) => ({
                                ...prev,
                                signerEmail: e.target.value,
                            }))
                        }
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                            },
                        }}
                    />
                </Box>

                {/* Checkboxes */}
                <Box sx={{ mt: 3 }}>
                    {/* Reset Button */}
                    <Button
                        startIcon={<RefreshIcon />}
                        variant="outlined"
                        onClick={resetSignature}
                        sx={{
                            'borderRadius': '8px',
                            'textTransform': 'none',
                            'color': theme.colors.text,
                            'borderColor': '#E2E3E4',
                            '&:hover': {
                                borderColor: theme.colors.text,
                                backgroundColor: 'transparent',
                            },
                            'mb': 2,
                        }}
                    >
                        Reset Signature
                    </Button>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={signatureData.legallyBindingChecked}
                                onChange={(e) =>
                                    setSignatureData(
                                        (prev: ISignatureData) => ({
                                            ...prev,
                                            legallyBindingChecked:
                                                e.target.checked,
                                        })
                                    )
                                }
                                sx={{
                                    'padding': '0 8px 0 0',
                                    'size': {
                                        xs: 'small',
                                        sm: 'medium',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: {
                                            xs: 20,
                                            sm: 24,
                                        },
                                    },
                                    '&.Mui-checked': {
                                        color: theme.colors.primary400,
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '0.8rem',
                                        sm: '0.9rem',
                                    },
                                    lineHeight: {
                                        xs: 1.3,
                                        sm: 1.5,
                                    },
                                    mt: {
                                        xs: '1px',
                                        sm: 0,
                                    },
                                }}
                            >
                                I agree that my electronic signature is legally
                                binding and equivalent to my handwritten
                                signature to the extent permitted by applicable
                                law.
                            </Typography>
                        }
                        sx={{
                            margin: '0 0 8px 0',
                            alignItems: 'flex-start',
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={signatureData.approveEstimationChecked}
                                onChange={(e) =>
                                    setSignatureData(
                                        (prev: ISignatureData) => ({
                                            ...prev,
                                            approveEstimationChecked:
                                                e.target.checked,
                                        })
                                    )
                                }
                                sx={{
                                    'padding': '0 8px 0 0',
                                    'size': {
                                        xs: 'small',
                                        sm: 'medium',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: {
                                            xs: 20,
                                            sm: 24,
                                        },
                                    },
                                    '&.Mui-checked': {
                                        color: theme.colors.primary400,
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '0.8rem',
                                        sm: '0.9rem',
                                    },
                                    lineHeight: {
                                        xs: 1.3,
                                        sm: 1.5,
                                    },
                                    mt: {
                                        xs: '1px',
                                        sm: 0,
                                    },
                                }}
                            >
                                I confirm I have reviewed and approve this
                                estimation.
                            </Typography>
                        }
                        sx={{
                            margin: '0 0 16px 0',
                            alignItems: 'flex-start',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default SignatureSection;
