import { Button } from '@mui/material';
import { Engineering } from '@mui/icons-material';
import theme from '../../../../theme';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { BodyBold, Body } from '../../../../components/Text';
import { useQuestionaire } from '../QuestionaireProvider';
import {
    onWebAppCostEstimationBookingLinkStep,
    onWebAppCostEstimationBookingLinkOpenLink,
} from '../../../../tracking/trackers';

const StepStart = ({
    url,
    companyLogo,
    isContractor,
}: {
    url?: string;
    companyLogo?: string;
    isContractor: boolean;
}) => {
    const { width = 768 } = useWindowDimensions();
    const { setStep, formId } = useQuestionaire();
    const circleDimension = width ? Math.min(width * 0.5, 250) : 250;

    return (
        <div
            style={{
                padding: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {companyLogo ? (
                <img
                    src={`data:image/jpeg;base64,${companyLogo}`}
                    alt="Company Logo"
                    style={{
                        maxWidth: '350px',
                        maxHeight: '300px',
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'contain',
                        margin: '50px 0',
                    }}
                />
            ) : (
                <div
                    style={{
                        width: circleDimension,
                        height: circleDimension,
                        backgroundColor: theme.colors.offWhite,
                        borderRadius: '50%',
                        margin: '50px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Engineering
                        sx={{
                            width: 150,
                            height: 150,
                            color: theme.colors.primary400,
                        }}
                    />
                </div>
            )}

            <BodyBold
                style={{
                    fontSize: width < 768 ? 22 : 30,
                    marginBottom: 20,
                    textAlign: 'center',
                }}
            >
                Tell us about the project
            </BodyBold>

            <Body
                style={{
                    color: theme.colors.grayText,
                    fontSize: 16,
                    textAlign: 'center',
                    marginBottom: 40,
                    maxWidth: 300,
                }}
            >
                Answer some quick questions, so we can get you an estimate fast
            </Body>

            <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 25,
                    height: 50,
                    fontWeight: '700',
                    fontSize: 20,
                    minWidth: 350,
                    textTransform: 'none',
                    maxWidth: 500,
                }}
                onClick={() => {
                    onWebAppCostEstimationBookingLinkStep({
                        step: 'start',
                        form_id: formId || 0,
                    });
                    onWebAppCostEstimationBookingLinkOpenLink({
                        isContractor: isContractor,
                        url: url || '',
                    });
                    setStep('take-photo');
                }}
            >
                Start
            </Button>
        </div>
    );
};

export default StepStart;
