/**
 * Capitalizes the first letter of each word in a string and converts the rest to lowercase.
 *
 * @param name - The input string to be formatted
 * @returns The formatted string with each word capitalized
 *
 * @example
 * capitalizeFirstLetter("john doe")     // returns "John Doe"
 * capitalizeFirstLetter("JANE SMITH")   // returns "Jane Smith"
 * capitalizeFirstLetter("bob O'NEIL")   // returns "Bob O'neil"
 * capitalizeFirstLetter("")             // returns ""
 */
export const capitalizeFirstLetter = (name: string): string => {
    if (!name) return '';
    return name
        .split(' ')
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
};
