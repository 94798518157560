import { useEffect, useState } from 'react';
import {
    PaymentElement,
    useStripe,
    useElements,
    PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import {
    PAYMENT_PRICE_BASE,
    PAYMENT_PRICE_CE_BASE_20,
    PAYMENT_PRICE_PRO,
} from '../../../util/constants';
import theme from '../../../theme';
import {
    onWebAppApplePayAvailabilityStatus,
    onWebAppInitiatePayment,
    onWebAppPaymentAttempt,
} from '../../../tracking/trackers';
// import SubscriptionService from '../../../services/subscriptions';
import { getBaseURL } from '../../../util/getBaseURL';
// import { useAuth } from '../../../providers/AuthProvider';
import { ENV } from '../../../configuration';
import { useAuth } from '../../../providers/AuthProvider';
import SubscriptionService from '../../../services/subscriptions';
import BottomButton from '../../../components/Buttons/BottomButton';
import CheckoutHeader from './CheckoutHeader';
import CEPaymentVariants from './components/CEPaymentVariants';

export let STRIPE_PRICE_ID_BASE = 'price_1LBJ2VJPcTvRC1gjXW6k6vnd';
export let STRIPE_PRICE_ID_PRO = 'price_1LBJ4dJPcTvRC1gj659g2cyO';
export let STRIPE_PRICE_ID_BASE_30 =
    'subscription_base_price_30_group_30and90_platform_stripe';
export let STRIPE_PRICE_ID_PRO_90 =
    'subscription_pro_price_90_group_30and90_platform_stripe';
export let STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY =
    'subscription_organization_seat_70_yearly_platform_stripe';
export let STRIPE_PRICE_INHERITENCE_SEAT_70_YEARLY =
    'subscription_inheritence_seat_70_yearly_platform_stripe';
export let STRIPE_PRICE_INHERITENCE_SEAT_90_YEARLY =
    'subscription_inheritence_seat_90_yearly_platform_stripe';
export let STRIPE_PRICE_INHERITENCE_SEAT_10_MONTHLY =
    'subscription_inheritence_seat_10_monthly_platform_stripe';
export let STRIPE_PRICE_INHERITENCE_SEAT_5_MONTHLY =
    'subscription_inheritence_seat_5_monthly_platform_stripe';
export let STRIPE_PRICE_CE_BASE_10_MONTHLY =
    'subscription_ce_base_price_10_monthly_platform_stripe';
export let STRIPE_PRICE_CE_BASE_20_MONTHLY =
    'subscription_ce_base_price_20_monthly_platform_stripe';
export let STRIPE_PRICE_AR_BASE_5_MONTHLY =
    'subscription_ar_base_price_5_monthly_platform_stripe';

export let PAYPAL_PRICE_ID_BASE = '0J080700BK064932UMLUW5SY';
export let PAYPAL_PRICE_ID_PRO = 'P-09V334249P092372DMRKAJRQ';
export let PAYPAL_PRICE_ID_BASE_30 = 'P-1X147514HV076345AMLUXATY';
export let PAYPAL_PRICE_ID_PRO_90 = 'P-5F159960GD025834TMRKAI6I';
export let PAYPAL_PRICE_ID_BASE_DISCOUNT = 'P-9NP60370KM246653JMMGSOBA';
export let PAYPAL_PRICE_ID_PRO_DISCOUNT = 'P-98D6287234327611MMMGSS7Y';
export let PAYPAL_PRICE_ID_CE_BASE_10_MONTHLY = 'P-4GM27684405715132M6IXD7I';
export let PAYPAL_PRICE_ID_CE_BASE_20_MONTHLY = 'P-9DL41916GM5361119M6L5JTY';
export let PAYPAL_PRICE_ID_AR_BASE_5_MONTHLY = 'P-5YY93699E4944245KM6JFODY';

if (ENV !== 'production') {
    STRIPE_PRICE_ID_BASE = 'price_1LAZVFJPcTvRC1gjrtgVNQGq';
    STRIPE_PRICE_ID_BASE_30 = 'price_1LAZVFJPcTvRC1gjrtgVNQGq';
    STRIPE_PRICE_ID_PRO = 'price_1LBhmNJPcTvRC1gjc7zXZEZa';
    STRIPE_PRICE_ID_PRO_90 = 'price_1LBhmNJPcTvRC1gjc7zXZEZa';

    PAYPAL_PRICE_ID_BASE = 'P-8JV63336B85784507MLUZARY';
    PAYPAL_PRICE_ID_BASE_30 = 'P-8JV63336B85784507MLUZARY';
    PAYPAL_PRICE_ID_PRO = 'P-8HY30772E02195136MLUZBJQ';
    PAYPAL_PRICE_ID_PRO_90 = 'P-8HY30772E02195136MLUZBJQ';
}

type ICheckoutFormProps = {
    tier: 'base' | 'pro';
    clientSecret: string; // used to securely complete the payment process instead of passing the entire PaymentIntent object
    discountPercentage?: number | undefined;
    couponId?: string | undefined;
    preForm?: JSX.Element | null;
    onPaymentSuccess?: () => void;
    successRedirectPath?: string;
    variant?: string;
    isCostEstimatorPaywall?: boolean;
};

const CheckoutForm = (props: ICheckoutFormProps) => {
    const {
        tier,
        clientSecret,
        discountPercentage,
        couponId,
        preForm,
        successRedirectPath,
        variant,
        isCostEstimatorPaywall,
    } = props;
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [{ options: paypalOptions }, dispatch] = usePayPalScriptReducer();

    const { user } = useAuth();
    const mobile = user?.mobile;

    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
        null
    );
    const [showCardEntry, setShowCardEntry] = useState<boolean>(false);
    const [isLoadingPaymentRequest, setIsLoadingPaymentRequest] =
        useState<boolean>(true);

    const expName = CEPaymentVariants.name;
    const { selectVariant } = useExperiment(expName);
    const abVariant = selectVariant(CEPaymentVariants.variants);

    const paymentAmount = isCostEstimatorPaywall
        ? PAYMENT_PRICE_CE_BASE_20
        : tier === 'pro'
        ? PAYMENT_PRICE_PRO
        : PAYMENT_PRICE_BASE;

    const buttonDisabled = isLoading || !stripe || !elements;

    const successPath =
        successRedirectPath ??
        `/payment/success?tier=${tier || 'base'}&variant=${variant}`;

    const onSuccess = () => {
        if (props.onPaymentSuccess) {
            props.onPaymentSuccess();
        } else {
            navigate(successPath);
        }
    };

    const BUTTON_HEIGHT = 45;
    const buttonStyles = {
        // same style as FormSubmitButton, but need to do separately to pass id="submit"
        width: '100%',
        borderRadius: 5,
        borderWidth: 0,
        color: theme.colors.white,
        backgroundColor: 'black',
        fontWeight: '600',
        height: BUTTON_HEIGHT,
    };

    let paypalPlanId =
        variant === 'ai-receptionist'
            ? PAYPAL_PRICE_ID_AR_BASE_5_MONTHLY
            : isCostEstimatorPaywall
            ? PAYPAL_PRICE_ID_CE_BASE_20_MONTHLY
            : tier === 'pro'
            ? PAYPAL_PRICE_ID_PRO
            : PAYPAL_PRICE_ID_BASE;
    // if coupon is applied, apply the discounted tier. don't care what the discount is since we only have one at the moment
    if (couponId) {
        paypalPlanId =
            tier === 'pro'
                ? PAYPAL_PRICE_ID_PRO_DISCOUNT
                : PAYPAL_PRICE_ID_BASE_DISCOUNT;
    }

    const couponApplied = couponId !== undefined;

    useEffect(() => {
        // reloads the JS SDK script because couponId is a dependency of the createSubscription function (else coupon will never apply)
        dispatch({
            type: 'resetOptions',
            value: { ...paypalOptions },
        });
    }, [couponId]);

    useEffect(() => {
        if (!stripe) return;
        const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Start 7 day trial',
                amount: 0,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
            if (result) {
                //@ts-ignore
                setPaymentRequest(pr);
                onWebAppApplePayAvailabilityStatus({
                    isAvailable: result.applePay,
                });
            } else {
                onWebAppApplePayAvailabilityStatus({ isAvailable: false });
            }
            setIsLoadingPaymentRequest(false);
        });
        // Handles apple payment
        // Listen to the paymentmethod event to receive a PaymentMethod object
        // Pass the PaymentMethod ID and the PaymentIntent’s client secret to stripe.confirmCardPayment to complete the payment
        pr.on('paymentmethod', async (ev) => {
            const { setupIntent, error: confirmError } =
                await stripe.confirmCardSetup(
                    clientSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false }
                );

            const handlePaymentError = () => {
                const message =
                    'Payment failure. Please try another payment method';
                setMessage(message);
                onWebAppPaymentAttempt({
                    status: 'failure',
                    method: 'apple-pay',
                    reason: message,
                    couponApplied,
                    experiment: isCostEstimatorPaywall ? expName : null,
                    cohort: isCostEstimatorPaywall ? abVariant : null,
                });
                return;
            };
            const handlePaymentSuccess = () => {
                onWebAppPaymentAttempt({
                    status: 'success',
                    method: 'apple-pay',
                    couponApplied,
                    experiment: isCostEstimatorPaywall ? expName : null,
                    cohort: isCostEstimatorPaywall ? abVariant : null,
                });
                // must manuall redirect to payment success screen
                onSuccess();
            };
            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                handlePaymentError();
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // (AR) NOTE: Reusing the PaymentIntent logic but instead with a SetupIntent. Not sure
                // if this is the proper way to do it.
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (setupIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardSetup(
                        clientSecret
                    );
                    if (error) {
                        // The payment failed -- ask your customer for a new payment method.
                        handlePaymentError();
                    } else {
                        handlePaymentSuccess();
                    }
                } else {
                    handlePaymentSuccess();
                }
            }
        });
    }, [stripe]);

    // Handles manual card payments
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);
        // complete the payment using details from the Payment Element and activate the subscription
        onWebAppPaymentAttempt({
            status: 'attempt',
            method: 'manual-card',
            couponApplied,
            experiment: isCostEstimatorPaywall ? expName : null,
            cohort: isCostEstimatorPaywall ? abVariant : null,
        });
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${getBaseURL(
                    window.location
                )}${successPath}&method=manual-card&couponApplied=${couponApplied}&subscription-success=true`,
            },
        });
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        const onFail = (message: string | undefined) => {
            setMessage(message);
            onWebAppPaymentAttempt({
                status: 'failure',
                method: 'manual-card',
                reason: message || '',
                couponApplied,
                experiment: isCostEstimatorPaywall ? expName : null,
                cohort: isCostEstimatorPaywall ? abVariant : null,
            });
        };
        if (error.type === 'card_error' || error.type === 'validation_error') {
            onFail(error.message);
        } else {
            onFail(`An unexpected error occurred. ${error.message || ''}`);
        }
        setIsLoading(false);
    };

    const getOrCreatePaypalSubscription = async (
        paypalPlanId: string,
        actions: any
    ) => {
        let localSubscription:
            | {
                  subscriptionId: string;
              }
            | undefined;
        try {
            localSubscription = JSON.parse(
                localStorage.getItem('paypaylSubscriptionIds') || ''
            );
            if (localSubscription?.subscriptionId)
                return localSubscription?.subscriptionId;
        } catch (e) {
            console.error('Error parsing paypal subscription ids', e);
        }

        const subscriptionId = await actions.subscription.create({
            plan_id: paypalPlanId,
        });
        localStorage.setItem(
            'paypaylSubscriptionIds',
            JSON.stringify({
                subscriptionId,
            })
        );
        return subscriptionId;
    };

    return (
        <div style={{ marginBottom: 20 }}>
            <CheckoutHeader
                loading={isLoadingPaymentRequest}
                paymentAmount={paymentAmount}
                discountPercentage={discountPercentage}
                variant={isCostEstimatorPaywall ? 'cost-estimator' : variant}
            />

            {preForm && !isLoadingPaymentRequest ? preForm : null}

            {!mobile && isCostEstimatorPaywall ? (
                <BottomButton
                    text="Next"
                    onClick={() => {
                        navigate(
                            '/signup?manualCodeEntry=true&variant=cost-estimator'
                        );
                    }}
                    style={{
                        width: '100%',
                        marginTop: 20,
                    }}
                />
            ) : !showCardEntry ? (
                <>
                    <div style={{ marginTop: 20 }}>
                        <PayPalButtons
                            style={{
                                shape: 'rect',
                                color: 'gold',
                                layout: 'vertical',
                                label: 'subscribe',
                                height: BUTTON_HEIGHT,
                            }}
                            createSubscription={async (
                                data,
                                actions: any // type: CreateSubscriptionActions
                            ): Promise<string> => {
                                onWebAppInitiatePayment({
                                    method: 'paypal',
                                    couponApplied,
                                    experiment: isCostEstimatorPaywall
                                        ? expName
                                        : null,
                                    cohort: isCostEstimatorPaywall
                                        ? abVariant
                                        : null,
                                });
                                return getOrCreatePaypalSubscription(
                                    paypalPlanId,
                                    actions
                                );
                            }}
                            onApprove={async (res) => {
                                onWebAppPaymentAttempt({
                                    status: 'success',
                                    method: 'paypal',
                                    couponApplied,
                                    experiment: isCostEstimatorPaywall
                                        ? expName
                                        : null,
                                    cohort: isCostEstimatorPaywall
                                        ? abVariant
                                        : null,
                                });
                                const subscriptionId = res.subscriptionID;
                                // set the user's subscription in db
                                if (subscriptionId) {
                                    SubscriptionService.paypalSetSubscriptionUser(
                                        tier || 'base',
                                        subscriptionId,
                                        mobile
                                    );
                                    onSuccess();
                                }
                            }}
                            onCancel={() => {
                                onWebAppPaymentAttempt({
                                    status: 'failure',
                                    method: 'paypal',
                                    reason: 'payment cancelled',
                                    couponApplied,
                                    experiment: isCostEstimatorPaywall
                                        ? expName
                                        : null,
                                    cohort: isCostEstimatorPaywall
                                        ? abVariant
                                        : null,
                                });
                            }}
                            onError={(err) => {
                                onWebAppPaymentAttempt({
                                    status: 'failure',
                                    method: 'paypal',
                                    reason: err.toString() ?? '',
                                    couponApplied,
                                    experiment: isCostEstimatorPaywall
                                        ? expName
                                        : null,
                                    cohort: isCostEstimatorPaywall
                                        ? abVariant
                                        : null,
                                });
                            }}
                        />
                    </div>
                    {paymentRequest ? (
                        // apple pay button
                        <PaymentRequestButtonElement
                            options={{
                                //@ts-ignore
                                paymentRequest,
                            }}
                            onClick={() => {
                                onWebAppInitiatePayment({
                                    method: 'apple-pay',
                                    couponApplied,
                                    experiment: isCostEstimatorPaywall
                                        ? expName
                                        : null,
                                    cohort: isCostEstimatorPaywall
                                        ? abVariant
                                        : null,
                                });
                            }}
                            style={{ height: BUTTON_HEIGHT }}
                        />
                    ) : null}
                    <button
                        disabled={buttonDisabled}
                        style={{ ...buttonStyles, marginTop: 10 }}
                        onClick={() => {
                            setShowCardEntry(true);
                            onWebAppInitiatePayment({
                                method: 'manual-card',
                                couponApplied,
                                experiment: isCostEstimatorPaywall
                                    ? expName
                                    : null,
                                cohort: isCostEstimatorPaywall
                                    ? abVariant
                                    : null,
                            });
                        }}
                    >
                        <span id="button-text">
                            {isLoading ? (
                                <CircularProgress
                                    color={'inherit'}
                                    style={{ width: 24, height: 24 }}
                                />
                            ) : variant === 'ai-receptionist' ||
                              isCostEstimatorPaywall ? (
                                'Continue'
                            ) : (
                                'Credit or Debit'
                            )}
                        </span>
                    </button>
                </>
            ) : (
                <>
                    <form id="payment-form" onSubmit={handleSubmit}>
                        <PaymentElement id="payment-element" />
                        <button
                            disabled={buttonDisabled}
                            id="submit"
                            style={{ ...buttonStyles, marginTop: 30 }}
                        >
                            <span id="button-text">
                                {isLoading ? (
                                    <CircularProgress
                                        color={'inherit'}
                                        style={{ width: 24, height: 24 }}
                                    />
                                ) : (
                                    'Subscribe'
                                )}
                            </span>
                        </button>
                        {/* Show any error or success messages */}
                        {message ? (
                            <div id="payment-message">{message}</div>
                        ) : null}
                    </form>
                </>
            )}
        </div>
    );
};

export default CheckoutForm;
