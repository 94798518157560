import LeafForHeader from '../../../../assets/leafForHeader.svg';
import { HeaderContainer, HeaderLeaf, HeaderTitle } from './styles';

const QuestionaireHeader = ({ title }: { title: string }) => {
    return (
        <HeaderContainer>
            <HeaderLeaf src={LeafForHeader} alt="" />
            <HeaderTitle>{title}</HeaderTitle>
        </HeaderContainer>
    );
};

export default QuestionaireHeader;
