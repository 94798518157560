import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import { Helmet } from 'react-helmet-async';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicService from '../../services/public/service';
import { onWebAppCostEstimationBookingLinkStep } from '../../tracking/trackers';
import {
    QuestionaireContainer,
    ContentContainer,
    PreviewBanner,
} from './styles';
import QuestionaireHeader from './components/QuestionaireHeader';
import StepTakePhoto from './components/StepTakePhoto';
import StepStart from './components/StepStart';
import { useQuestionaire } from './components/QuestionaireProvider';
import StepDescription from './components/StepDescription';
import StepQuestions from './components/StepQuestions';
import StepClientInfo from './components/StepClientInfo';
import StepDone from './components/StepDone';
const CostEstimatorQuestionaire = ({
    isContractor,
}: {
    isContractor: boolean;
}) => {
    const {
        step,
        setFormId,
        imageIds,
        setImageIds,
        imageUris,
        setImageUris,
        submitProgressToForm,
        formId,
    } = useQuestionaire();
    const { url: urlParam } = useParams();
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [companyName, setCompanyName] = useState('SimplyWise');
    const [companyLogo, setCompanyLogo] = useState('');

    const url = urlParam?.replace('.simplywise.com', '');

    useEffect(() => {
        const fetchBookingLink = async () => {
            if (!url) return;
            const response = await PublicService.getBookingLink(url);
            if (response.status === 'success') {
                setCompanyName(
                    response.data?.booking_link.metadata.business_name ||
                        'SimplyWise'
                );
                setCompanyLogo(
                    response.data?.booking_link.metadata.business_logo || ''
                );
            }
        };
        fetchBookingLink();
    }, []);

    useEffect(() => {
        const fetchFormId = async () => {
            const response =
                await PublicService.getCostEstimatorQuestionaireFormId(
                    url ?? ''
                );
            if (response.status === 'success') {
                const fetchedFormId = response.data.booking_link_form.id;
                setFormId(fetchedFormId);
            }
        };
        fetchFormId();
    }, []);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (
            !['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(
                _file.type
            )
        )
            return;

        resizeFile(_file).then((resizedFile) => {
            const imageUrl = URL.createObjectURL(resizedFile);
            setImageUris([...(imageUris || []), imageUrl]);
            PublicService.uploadImage(resizedFile)
                .then(async (res) => {
                    const newImageId = res.data.image_ids[0];
                    const updatedImageIds = [...(imageIds || []), newImageId];
                    setImageIds(updatedImageIds);

                    await submitProgressToForm({
                        image_ids: updatedImageIds,
                    });
                    onWebAppCostEstimationBookingLinkStep({
                        step: 'take-photo',
                        form_id: formId || 0,
                        metadata: {
                            image_ids: [res.data.image_ids[0]],
                        },
                    });
                })
                .catch((err) => {
                    console.error('Upload error:', err);
                });
        });
    };

    return (
        <>
            <Helmet>
                <title>Booking Link | {url}</title>
                <meta
                    name="description"
                    content="Review and manage cost estimations for your projects | SimplyWise"
                />
                <link
                    rel="canonical"
                    href={`https://www.simplywise.com/booking-link/${url}`}
                />
            </Helmet>
            <QuestionaireContainer>
                <input
                    type="file"
                    accept="image/*"
                    ref={imageInputRef}
                    onChange={onUploadImage}
                    style={{ display: 'none' }}
                />
                <QuestionaireHeader title={companyName} />
                <ContentContainer>
                    {isContractor && (
                        <PreviewBanner>
                            <VisibilityIcon style={{ fontSize: 20 }} />
                            <span
                                style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    opacity: 1,
                                    color: 'white',
                                }}
                            >
                                Preview Mode
                            </span>
                        </PreviewBanner>
                    )}
                    {step === 'start' ? (
                        <StepStart
                            url={url}
                            companyLogo={companyLogo}
                            isContractor={isContractor}
                        />
                    ) : step === 'take-photo' ? (
                        <StepTakePhoto imageInputRef={imageInputRef} />
                    ) : step === 'project-description' ? (
                        <StepDescription />
                    ) : step === 'step-questions' ? (
                        <StepQuestions />
                    ) : step === 'client-info-step' ? (
                        <StepClientInfo />
                    ) : (
                        <StepDone isContractor={isContractor} />
                    )}
                </ContentContainer>
            </QuestionaireContainer>
        </>
    );
};

export default CostEstimatorQuestionaire;
