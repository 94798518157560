import { styled, TextField } from '@mui/material';
import theme from '../../theme';

export const TextInput = styled(TextField)({
    variant: 'outlined',
    color: 'secondary',
    borderColor: theme.colors.blue100,
});

export const NumberInput = styled(TextField)({
    inputMode: 'numeric',
    pattern: '[0-9]*',
    variant: 'outlined',
    color: 'secondary',
    borderColor: theme.colors.blue100,
});
